import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useCart } from '../../context/CartContext'
import { useTitle } from '../../hooks/useTitle'
import api from '../../services/api'
import * as S from './styles'
import DetailProduct from '../../components/Product/Detail'
import LoadingBackground from '../../components/LoadingBackground'
import { useAuth } from '../../context/AuthContext'

const Product = () => {
  const { id } = useParams()
  const { addProduct } = useCart()
  const { authenticated } = useAuth()
  const [product, setProduct] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'));

  useEffect(() => {
    const getProduct = async () => {
      setIsLoading(true)
      const response = await api.get(`/api/v2/Product/${id}`)
      setProduct(response.data.response.data)
      setIsLoading(false)
    }

    getProduct()
  }, [id])

  useTitle(product ? product.description : '')

  return (
    <>
      {isLoading ? (
        <LoadingBackground />
      ) : (
        product && (
          <>
            <DetailProduct product={product} addProduct={addProduct} />
            {(!!authenticated || !customerInfo.b2BSellingInfoDisabled) &&
              <S.ProductMoreProducts />
            }
          </>
        )
      )}
    </>
  )
}

export default Product
