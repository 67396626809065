import * as S from './styles'
import Faq from '../Faq'

const FaqContent = ({ className, questions }) => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = questions

  const newQuestionArr = []
  let count = 0

  Array.from(tempDiv.childNodes).forEach((el, idx) => {
    const questionText = el.textContent.trim()
    if (questionText[0] === '#' && questionText[questionText.length - 1] === '#') {
      count++
      newQuestionArr[count] = [el.outerHTML]
    } else {
      newQuestionArr[count]?.push(el.outerHTML)
    }
  })

  return (
    <S.FaqContent className={className}>
      <S.FaqContentTitle>Perguntas frequentes</S.FaqContentTitle>
      <S.FaqContentWrapper>
        {newQuestionArr.map((question, idx) => (
          <Faq key={idx} title={question[0].replaceAll('#', '')} content={question.slice(1).join('')} />
        ))}
      </S.FaqContentWrapper>
    </S.FaqContent>
  )
}

export default FaqContent
