import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import Icon from '../../commons/Icon'

export const BackButton = styled(Link)`${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 2rem;

  &:hover {
    ${BackButtonIconContainer} {
      color: ${theme.colors.mono.white};
      border-color: ${theme.colors.orange.default};
      background-color: ${theme.colors.orange.default};
    }

    ${BackButtonLabel} {
      color: ${theme.colors.orange.default};
    }
  }
`}`

export const BackButtonIconContainer = styled.span`${({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  color: ${theme.colors.mono.greyDark};
  border: .12rem solid ${theme.colors.mono.greyDark};
  background-color: ${theme.colors.mono.white};
  border-radius: 50%;
  transition: border .3s, background-color .3s, color .3s;
`}`

export const BackButtonIcon = styled(Icon)`
  width: 1.067rem;
  height: 1.067rem;
  transform: rotate(-90deg);
`

export const BackButtonLabel = styled.span`
  margin-left: 1rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
  transition: color .3s;
`
