import { SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
// import 'swiper/css/pagination'

import * as S from './styles'
import ProductBanner from '../ProductBanner'
import NavGroup from '../Slide/NavGroup'

const BannerSlide = ({ banners }) => {
  const bannerData = 'banner-slide'

  return (
    <S.BannerSlide>
      <S.BannerSlideSwiper
        spaceBetween={30}
        pagination={{
          el: `[data-js=${bannerData}-pagination]`,
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={{
          prevEl: `[data-js=${bannerData}-btn-prev]`,
          nextEl: `[data-js=${bannerData}-btn-next]`,
        }}
        modules={[Pagination, Navigation]}
      >
        {banners.map((banner, idx) => (
          <SwiperSlide key={idx}>
            <ProductBanner banner={banner.image} linkType={banner.linkType} linkId={banner.linkId} />
          </SwiperSlide>
        ))}
      </S.BannerSlideSwiper>
      <NavGroup btnIcon='leftArrow' data={bannerData} />
    </S.BannerSlide>
  )
}

export default BannerSlide
