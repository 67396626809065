import { useState, useEffect } from 'react'
import { useTitle } from '../../hooks/useTitle'
import api from '../../services/api'
import { deleteInvoice, printInvoice } from '../../services/invoice'
import formatMoney from '../../util/formatMoney'
import getDateStr from '../../util/getDateStr'
import * as S from './styles'
import Loading from '../../commons/Loading'
import Breadcrumb from '../../components/Breadcrumb'
import CartEmpty from '../../components/Cart/CartEmpty'
import LoadingBackground from '../../components/LoadingBackground'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import { useNavigate } from 'react-router-dom'

const Orders = () => {
  const [isOrdersLoading, setIsOrdersLoading] = useState(true)
  const [invoices, setInvoices] = useState([])
  const navigate = useNavigate()

  const getInvoices = async () => {
    const response = await api.get('/Invoice')
    setInvoices(response.data)
    setIsOrdersLoading(false)
    ReactTooltip.rebuild()
  }

  useEffect(() => {
    getInvoices()
  }, [])

  const handleDelete = async (id, status, integrationCode, customerCode) => {
    setIsOrdersLoading(true)
    const deleteResponse = await deleteInvoice(id, status, integrationCode, customerCode)
    setIsOrdersLoading(false)
    if (deleteResponse) getInvoices()
    setIsOrdersLoading(false)
  }

  const handleDuplicateOrder = async (invoice) => {
    const id = toast.loading(`Duplicando pedido ${invoice.invoiceId}`, {
      position: 'top-right',
    })
    setIsOrdersLoading(true)
    const items = invoice.items
    items.forEach(el => { el.id = 0 })
    const data = {
      Advance: invoice.advance,
      DeadlineId: invoice.deadlineId,
      DeliveryForecast: invoice.deliveryForecast,
      Discount: invoice.discount,
      Freight: invoice.freight,
      Items: items,
      Nature: invoice.nature,
      Notes: invoice.notes,
      PaymentMethodId: invoice.paymentMethodId,
      Price: null,
      Tax: invoice.tax,
      TotalValue: invoice.totalValue,
      Value: invoice.value,
      CreationSource: 2,
    }
    try {
      const response = await api.post('/Invoice', data)
      if (response.data.success) {
        toast.update(id, {
          render: response.data.successMessages[0],
          type: 'success',
          isLoading: false,
          delay: 750,
          autoClose: 2000,
        })
        navigate(`/orders/${response.data.data.invoiceId}`, { state: { editing: false } })
      }
    } catch (err) {
      toast.update(id, {
        render: 'Erro ao duplicar pedido',
        type: 'error',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
    }

    setIsOrdersLoading(false)
  }

  const handlePrint = async (id) => {
    setIsOrdersLoading(true)
    await printInvoice(id)
    setIsOrdersLoading(false)
  }

  useTitle('Pedidos')

  return (
    <S.Orders>
      <Breadcrumb current='Meus Pedidos' />

      {isOrdersLoading
        ? (
          <LoadingBackground />
          )
        : (
          <>
            {invoices.length > 0
              ? (
                <>
                  <S.OrdersTop>
                    <S.OrdersTitle>Meus Pedidos</S.OrdersTitle>
                    {invoices.length > 0
                      ? <S.OrdersQty>{invoices.length}</S.OrdersQty>
                      : <Loading />}
                  </S.OrdersTop>

                  {invoices.length > 0 && (
                    <S.OrdersTable>
                      <S.OrdersTableHeader>
                        <S.OrdersTableItem>ID</S.OrdersTableItem>
                        <S.OrdersTableItem>Data</S.OrdersTableItem>
                        <S.OrdersTableItem>Status</S.OrdersTableItem>
                        <S.OrdersTableItem>Valor</S.OrdersTableItem>
                        <S.OrdersTableItem>Observações</S.OrdersTableItem>
                        <S.OrdersTableItem>Ações</S.OrdersTableItem>
                      </S.OrdersTableHeader>

                      {invoices.map(invoice => (
                        <S.OrdersTableRow key={invoice.invoiceId}>
                          <S.OrdersTableItem>
                            <S.OrdersTableItemLinkId to={`/orders/${invoice.invoiceId}`}>
                              {invoice.invoiceId}
                            </S.OrdersTableItemLinkId>
                          </S.OrdersTableItem>
                          <S.OrdersTableItem>{getDateStr(invoice.timeCreation)}</S.OrdersTableItem>
                          <S.OrdersTableItem>{invoice.statusDescription + (invoice.integrationCode != null ? ' (Integrado)' : '')}</S.OrdersTableItem>
                          <S.OrdersTableItem>{formatMoney(invoice.totalValue)}</S.OrdersTableItem>
                          <S.OrdersTableItem>{invoice.notes}</S.OrdersTableItem>
                          <S.OrdersTableItem>
                            <S.OrdersTableItemLink to={`/orders/${invoice.invoiceId}`}>
                              <S.OrdersIcon type='circledInfo' />
                              Ver detalhes
                            </S.OrdersTableItemLink>
                          </S.OrdersTableItem>
                          <S.OrdersTableItem>
                            <S.OrdersButton onClick={() => handleDuplicateOrder(invoice)} data-tip='Duplicar'>
                              <S.OrdersIcon type='inboxes' />
                            </S.OrdersButton>
                          </S.OrdersTableItem>
                          <S.OrdersTableItem>
                            <S.OrdersButton onClick={() => handleDelete(invoice.invoiceId, invoice.status, invoice.integrationCode, invoice.customerCode)} disabled={isOrdersLoading} data-tip='Deletar'>
                              <S.OrdersIcon type='trash' />
                            </S.OrdersButton>
                          </S.OrdersTableItem>
                          <S.OrdersTableItem>
                            <S.OrdersButton data-tip='Imprimir' onClick={() => handlePrint(invoice.invoiceId)}>
                              <S.OrdersIcon type='print' />
                            </S.OrdersButton>
                          </S.OrdersTableItem>
                        </S.OrdersTableRow>
                      ))}
                    </S.OrdersTable>
                  )}
                  {invoices.length > 0 && (
                    <S.OrdersTableMobile>
                      {invoices.map(invoice => (
                        <span key={invoice.invoiceId}>
                          <S.OrdersTableHeader>
                            <S.OrdersTableItem>ID</S.OrdersTableItem>
                            <S.OrdersTableItem>Data</S.OrdersTableItem>
                            <S.OrdersTableItem>Status</S.OrdersTableItem>
                          </S.OrdersTableHeader>
                          <S.OrdersTableRow>
                            <S.OrdersTableItem>
                              <S.OrdersTableItemLinkId to={`/orders/${invoice.invoiceId}`}>
                                {invoice.invoiceId}
                              </S.OrdersTableItemLinkId>
                            </S.OrdersTableItem>
                            <S.OrdersTableItem>{getDateStr(invoice.timeCreation)}</S.OrdersTableItem>
                            <S.OrdersTableItem>{invoice.statusDescription}</S.OrdersTableItem>
                            <S.OrdersTableItem>
                              <S.OrdersTableItemLink to={`/orders/${invoice.invoiceId}`}>
                                <S.OrdersIcon type='circledInfo' />
                                Ver detalhes
                              </S.OrdersTableItemLink>
                              <S.OrdersTableItemLink to='/orders/' onClick={() => handleDuplicateOrder(invoice)}>
                                <S.OrdersIcon type='inboxes' />
                                Duplicar Pedido
                              </S.OrdersTableItemLink>
                            </S.OrdersTableItem>
                          </S.OrdersTableRow>
                        </span>
                      ))}
                    </S.OrdersTableMobile>
                  )}
                </>
                )
              : (
                <CartEmpty>
                  Parece que você ainda não tem nenhum pedido 😱 <br />
                  <strong>Vamos mudar isso?</strong>
                </CartEmpty>
                )}
          </>
          )}

    </S.Orders>

  )
}

export default Orders
