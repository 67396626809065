import * as S from './styles'

const IncrementStepper = ({ qty, setQty, isProductCard, styleQtd }) => {
  const handleQtyCount = (type) => {
    if (type === 'increase') {
      setQty(qty + 1);
    } else if (type === 'decrease' && qty > 1) {
      setQty(qty - 1);
    }
  };

  const handleQtyChange = (e) => {
    const inputValue = e.target.value;
    const replacedValue = inputValue.replace(',', '.');
    const formattedValue = parseFloat(replacedValue);
    setQty(formattedValue);
  };

  return (
    <S.IncrementStepper $isProductCard={isProductCard} $styleQtd={styleQtd}>
      <S.IncrementStepperBtn onClick={() => handleQtyCount('decrease')} $isProductCard={isProductCard}>-</S.IncrementStepperBtn>
      <S.IncrementStepperInput 
        type='number'
        value={qty} 
        onChange={(e) => setQty(e.target.value.replace(',','.'))} 
        onBlur={handleQtyChange}
        $styleQtd={styleQtd}
        $isProductCard={isProductCard}/>
      {/* <S.IncrementStepperValue>{qty}</S.IncrementStepperValue> */}
      <S.IncrementStepperBtn onClick={() => handleQtyCount('increase')} $isProductCard={isProductCard}>+</S.IncrementStepperBtn>
    </S.IncrementStepper>

  )
}

export default IncrementStepper
