import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import { CartProvider } from './context/CartContext'
import { SearchProvider } from './context/SearchContext'
import Routes from './routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ReactTooltip from 'react-tooltip'
import { ThemeContextProvider } from './context/ThemeContext'

const App = () => {
  return (
    <ThemeContextProvider>
      <AuthProvider>
        <CartProvider>
            <Router>
              <SearchProvider>
                <Routes />
              </SearchProvider>
            </Router>
          <ReactTooltip className='default-tooltip' />
          <ToastContainer
            autoClose={3000}
            position='bottom-right'
            theme='colored'
            pauseOnHover={false}
            draggable={false}
            pauseOnFocusLoss={false}
          />
        </CartProvider>
      </AuthProvider>
    </ThemeContextProvider>
  )
}

export default App
