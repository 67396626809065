import { useState, useEffect } from 'react'
import { useTitle } from '../../hooks/useTitle'
import api from '../../services/api'
import * as S from '../Home/styles'
import ProductCard from '../../components/ProductCard'
import LoadingBackground from '../../components/LoadingBackground'
import { useAuth } from '../../context/AuthContext'
import { useSearch } from '../../context/SearchContext'
import Select from '../../components/Select'
import Paginator from '../../components/Paginator'
import { objectToQueryString } from '../../util/objToQueryString'
import { useCallback } from 'react'

const FilterPage = () => {
  const [visualization, setVisualization] = useState(2)
  const [, setCustomer] = useState({})
  const [opportunitiesProducts, setOpportunitiesProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { user, verifyAcessWithoutLogin } = useAuth()
  const { loadingBackground, products, filter,setFilter, currentPage, setCurrentPage, totalPages, setTotalPages, orderFilter, setOrderFilter } = useSearch()
  const accessWithoutLogin = verifyAcessWithoutLogin()

  const getOpportunitiesProducts = async (order, page) => {
    const updatedFilter = {
      ...filter,
      order: order,
      page: page,
    }
    setFilter(updatedFilter)
    const filterQuery = objectToQueryString(updatedFilter)
    const response = await api.get(`api/v2/Product/FilterBar?${filterQuery}`)
    setOpportunitiesProducts(response.data.response.data)
    setTotalPages(response.data.response.totalPages)
    setIsLoading(false)
    window.scrollTo({ top: 0 })
  }

  const getCustomerInfos = useCallback(async () => {
    const getCustomer = JSON.parse(localStorage.getItem('DAuth:customer'));
    setCustomer(getCustomer)
  }, [])

  const handleOrder = (e) => {
    setOrderFilter(e)
    setCurrentPage(1)
    getOpportunitiesProducts(e, 1)
  }
  const handlePageClick = (e) => {
    setIsLoading(true)
    setCurrentPage(e.selected + 1)
    getOpportunitiesProducts(orderFilter, e.selected + 1)
  }
  useEffect(() => {
    getOpportunitiesProducts(orderFilter, currentPage)
    getCustomerInfos()
  }, [])

  useEffect(() => {
    setOpportunitiesProducts(products)
  }, [products])

  useTitle(`Filtro por...`)
  return (
    <>
      {(isLoading || loadingBackground) && (
        <LoadingBackground />
      )}

      <S.HomeTopInfo>
        <S.HomeTopInfoTitle>
          Resultados da busca
        </S.HomeTopInfoTitle>
        <S.HomeTopOrderCnt>
          <label htmlFor=''>Ordenação</label>
          <Select value={orderFilter} onChange={(e) => handleOrder(e.target.value)}>
            <option value='0'>Nome - De A para Z</option>
            <option value='1'>Nome - De Z para A</option>
            {
              accessWithoutLogin === false &&
              <>
                <option value='2'>Preço - Do menor para o maior</option>
                <option value='3'>Preço - Do maior para o menor</option>
              </>
            }
          </Select>
        </S.HomeTopOrderCnt>
        <S.HomeViewCnt>
          <S.HomeViewBtnText>Visualização</S.HomeViewBtnText>
          <S.HomeViewBtnCnt isMobile>
            <S.HomeViewBtn isActive={visualization === 1} onClick={() => setVisualization(1)}>
              <S.HomeViewBtnIcon type='oneCol' />
            </S.HomeViewBtn>
            <S.HomeViewBtn isActive={visualization === 2} onClick={() => setVisualization(2)}>
              <S.HomeViewBtnIcon type='twoCol' />
            </S.HomeViewBtn>
          </S.HomeViewBtnCnt>
          <S.HomeViewBtnCnt>
            <S.HomeViewBtn isActive={visualization === 2} onClick={() => setVisualization(2)}>
              <S.HomeViewBtnIcon type='twoCol' />
            </S.HomeViewBtn>
            <S.HomeViewBtn isActive={visualization === 3} onClick={() => setVisualization(3)}>
              <S.HomeViewBtnIcon type='thrCol' />
            </S.HomeViewBtn>
          </S.HomeViewBtnCnt>
        </S.HomeViewCnt>
      </S.HomeTopInfo>
      <S.HomeGridProducts visualization={visualization}>
        {opportunitiesProducts.length > 0 &&
          opportunitiesProducts.map((product, index) => <ProductCard key={product.productId} product={product} styleQtd={visualization} />,
          )}
      </S.HomeGridProducts>
      {!isLoading && totalPages > 1 && <Paginator totalPages={totalPages} currentPage={currentPage} handlePageClick={handlePageClick} />}
      {/* <S.HomeSlideOpportunities /> */}
      {opportunitiesProducts.length === 0 &&
        <S.NoResults>
          Nenhum produto encontrado
        </S.NoResults>}
    </>
  )
}
export default FilterPage
