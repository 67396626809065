import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li{
    ::marker {
      display: none
    }
  }
  table {
    border-collapse: collapse;
  }

  textarea {
    white-space: revert;
  }

  address {
    font-style: normal;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    @media ${({ theme }) => theme.screens['3xl']} {
      font-size: 55%;
    }

    @media ${({ theme }) => theme.screens['2xl']} {
      font-size: 50%;
    }

    @media ${({ theme }) => theme.screens.xl} {
      font-size: 48%;
    }

    @media ${({ theme }) => theme.screens.md} {
      font-size: 62.5%;
    }
    @media ${({ theme }) => theme.screens.xs} {
      font-size: 60%;
    }
  }

  html, body {
    overflow-x: hidden;
  }

  body {
    color: ${({ theme }) => theme.colors.mono.black};
    -webkit-font-smoothing: antialiased;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  a, button {
    transition: color .3s, background-color .3s, border-color .3s, box-shadow .3s;
  }

  a {
    text-decoration: none;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  p {
    letter-spacing: -0.02rem;
  }
  .default-tooltip{
    font-size: 1.3rem;
    line-height: 1.7rem;
    color: #FFFFFF;
  }
  .ReactModal__Content{
    overflow-x: hidden !important;
  }
  input[type='checkbox'],
  input[type='radio'] {
    --active: ${({ theme }) => theme.colors.green.default};
    --active-inner: #fff;
    /* --focus: 2px rgba(39, 94, 254, .3); */
    --border: #BBC1E1;
    --border-hover: ${({ theme }) => theme.colors.green.default};
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2rem;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 2.1rem;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      /* font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px; */
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 4px;
      &:after {
        width: 0.5rem;
        height: 0.9rem;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 0.7rem;
        top: 0.4rem;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 3.8rem;
      border-radius: 11px;
      &:after {
        left: 0.2rem;
        top: 0.2rem;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  input[type='radio'] {
    border-radius: 50%;
    &:after {
      width: 1.9rem;
      height: 1.9rem;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, .7));
    }
    &:checked {
      --s: .5;
    }
  }
  .paginator-cnt{
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    margin: 0rem auto 3.2rem;
    width: fit-content;
    .disabled{
      filter: grayscale(10);
      pointer-events: none;
    }
    .page-number{
      a{
        border: 0.3rem solid transparent;
        width: 5.3rem;
        height: 5.3rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover{
          border-color: ${({ theme }) => theme.colors.orange.default};
        }
      }
      &.selected{
        a{
          border-color: ${({ theme }) => theme.colors.orange.default};
        }
      }
    }
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`

export default GlobalStyle
