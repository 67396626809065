import * as S from './styles'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCart } from '../../context/CartContext'
import formatMoney from '../../util/formatMoney'
import { IMAGE_URL } from '../../constants'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import { Button } from '../../commons/Button'
import IncrementStepper from '../IncrementStepper'
import { ProductCartQuantity } from '../ProductCartQuantity'

const ProductCard = ({ product, styleQtd, model }) => {
  const { addProduct, cart } = useCart()
  const navigate = useNavigate()
  let firstImage = '/logo.png'
  const { verifyAcessWithoutLogin } = useAuth()
  const accessWithoutLogin = verifyAcessWithoutLogin()
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))
  const showProductPromotionInPortal = customer?.environmentConfiguration?.showProductPromotionInPortal
  const findProductOnCart = cart?.items?.filter(item => item.productId === product.id)
  const [qty, setQty] = useState(0)
  const [inicialQty, setInicialQty] = useState(0)

  const getButtonCTAName = () => {
    if (product.hasGrade) {
      return 'Ver detalhes'
    } else if (product.stockRestrition && product.warehouseQuantity <= 0) {
      return 'Produto sem estoque'
    } else {
      return 'Adicionar ao pedido'
    }
  }

  if (product.images && product.images.length > 0) {
    if (product.images[0].includes('http')) { firstImage = product.images[0] } else { firstImage = `${IMAGE_URL}${product.images[0]}` }
  }

  useEffect(() => {
    if (findProductOnCart?.length > 0) {
      const tmpQty = findProductOnCart?.reduce((total, item) => total + (item.quantity || 0), 0);
      const tmpInitialQty = tmpQty && tmpQty > 0 ? tmpQty : 0;
      setInicialQty(tmpInitialQty)
    } else {
      setInicialQty(0)
    }
  }, [product, findProductOnCart])

  useEffect(() => {
   setQty(inicialQty)
  }, [inicialQty])

  return (
    <S.ProductCard model={model}>
      <S.ProductCardFigure model={model} styleQtd={styleQtd}>
        {inicialQty > 0 && <ProductCartQuantity qtd={inicialQty}/>}
        <S.ProductCardImage
          src={firstImage}
          alt={product.description}
          onError={(e) => { e.target.src = '/logo.png' }}
        />
      </S.ProductCardFigure>
      <S.ProductCardContent styleQtd={styleQtd}>
        <S.ProductCardWrapper>
          <S.ProductCardTitle>{product.description}
            {(product.multiplicationFactor > 1 ? <S.ProductCardSubTitle> (x{product.multiplicationFactor})</S.ProductCardSubTitle> : <></>)}
          </S.ProductCardTitle>
          {
            accessWithoutLogin === false ?
              showProductPromotionInPortal && product.originalPrice > product.price ?
              <>
                <S.ProductCardOriginalPriceContainer>
                  <S.ProductCardOriginalPriceWrapper>
                    <S.ProductCardOriginalPrice>R$ {formatMoney(product.originalPrice, false)}</S.ProductCardOriginalPrice>
                  </S.ProductCardOriginalPriceWrapper>
                  <S.ProductCardOriginalPriceWrapper>
                    <S.ProductCardPriceLabel>R$</S.ProductCardPriceLabel>
                    <S.ProductCardPrice>{formatMoney(product.price, false)}</S.ProductCardPrice>
                  </S.ProductCardOriginalPriceWrapper>
                </S.ProductCardOriginalPriceContainer>
              </>
              :
              <S.ProductCardPriceContainer>
                <S.ProductCardPriceLabel>R$</S.ProductCardPriceLabel>
                <S.ProductCardPrice>{formatMoney(product.price, false)}</S.ProductCardPrice>
              </S.ProductCardPriceContainer>
            : null
          }
          {styleQtd === 3 && (!product.hasGrade || product.warehouseQuantity > 0) && <IncrementStepper qty={qty} setQty={setQty} isProductCard={true} styleQtd={styleQtd}/>}
          {styleQtd !== 3 &&
            <>
              {
                accessWithoutLogin === false ?
                <>
                  {(!product.hasGrade && 
                      (!product.stockRestrition || (product.stockRestrition && product.warehouseQuantity > 0))) && 
                        <IncrementStepper qty={qty} setQty={setQty} isProductCard={true} styleQtd={styleQtd}/>
                  }
                  <S.ProductCardButton
                    model={model}
                    onClick={() => product.hasGrade ? navigate(`/product/${product.id}`) : addProduct(product.id, (qty > 0 ? qty : 1))}
                    disabled={!!(product.stockRestrition && product.warehouseQuantity <= 0 && !product.hasGrade)}
                  >
                    {getButtonCTAName()}
                  </S.ProductCardButton>
                  {!product.hasGrade && (
                    <S.ProductCardLink to={`/product/${product.id}`}>Ver detalhes</S.ProductCardLink>
                  )}
                </>
                :
                <S.DetailBtnWrapper>
                  <Link to={`/product/${product.id}`}>
                    <Button style={{margin: '6.5rem 0', paddingLeft: '5rem', paddingRight: '5rem'}}>
                      Ver detalhes
                    </Button>
                  </Link>
                </S.DetailBtnWrapper>
              }
            </>
          }
        </S.ProductCardWrapper>
      </S.ProductCardContent>
      {styleQtd === 3 &&
        <S.MobileBtns>
          {
            accessWithoutLogin === false ? 
            <>
              <S.ProductCardButton
                model={model}
                onClick={() => product.hasGrade ? navigate(`/product/${product.id}`) : addProduct(product.id, (qty > 0 ? qty : 1))}
                disabled={!!(product.stockRestrition && product.warehouseQuantity <= 0 && !product.hasGrade)}
              >
                {getButtonCTAName()}
              </S.ProductCardButton>
              {!product.hasGrade && (
                <S.ProductCardLink to={`/product/${product.id}`}>Ver detalhes</S.ProductCardLink>
              )}
            </>
            :
            <Link to={`/product/${product.id}`}>
              <S.ProductCardButton>
                Ver detalhes
              </S.ProductCardButton>
            </Link>
          }
        </S.MobileBtns>
      }

    </S.ProductCard>
  )
}

export default ProductCard
