import * as S from './styles'
import { IMAGE_URL } from '../../constants'
import { useState, useEffect } from 'react'
import { useAuth } from '../../context/AuthContext'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  const [customer, setCustomer] = useState()
  const [whatsappSupport, setWhatsappSupport] = useState()
  const [whatsappSupportLink, setWhatsappSupportLink] = useState('')
  const [ecommerceFooter, setEcommerceFooter] = useState()
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [mail, setMail] = useState('')
  const [phone, setPhone] = useState('')
  const [whatsappLink, setWhatsappLink] = useState('')
  const [instagramLink, setInstagramLink] = useState('')
  const [facebookLink, setFacebookLink] = useState('')

  const { user, getCustomer } = useAuth()
  const currentYear = new Date().getFullYear()

  const getCustomerInfos = async () => {
    const response = await getCustomer(user.companyID)
    setCustomer(response)
    setEcommerceFooter(response?.environmentConfiguration.ecommerceFooter)
    setWhatsappSupport(response?.environmentConfiguration.whatsappSupportContact)
  }

  const findDatas = (id, array) => {
    const findData = array?.find(item => item.id === id)
    return findData
  }

  const getWhatsappSupportInfo = async () => {
    const whatsappSupportData = findDatas(whatsappSupport, customer?.person.contacts)
    setWhatsappSupportLink(whatsappSupportData ? `https://wa.me/55${whatsappSupportData.description}` : whatsappSupportLink)
  }

  const getFooterInfos = async () => {
    const addressData = findDatas(ecommerceFooter?.address, customer?.person.addresses)
    setAddress(addressData ? `${addressData.address}, ${addressData.number} - ${addressData.cep}` : address)
    setCity(addressData ? `${addressData.city}, ${addressData.stateDescription}-${addressData.stateShortDescription}` : city)
    const mailData = findDatas(ecommerceFooter?.mail, customer?.person.contacts)
    setMail(mailData ? mailData.description : mail)

    const phoneData = findDatas(ecommerceFooter?.phone, customer?.person.contacts)
    setPhone(phoneData ? phoneData.description : phone)

    const whatsappData = findDatas(ecommerceFooter?.whatsappContact, customer?.person.contacts)
    setWhatsappLink(whatsappData ? `https://wa.me/55${whatsappData.description}` : whatsappLink)

    ecommerceFooter?.socialMedia.map(item => {
      const socialMediaData = findDatas(item, customer.person.contacts)

      if (socialMediaData.type === 7) {
        setInstagramLink(socialMediaData.description)
      } else {
        setFacebookLink(socialMediaData.description)
      }
    })
  }

  function maskCNPJ (cnpj) {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
  }

  useEffect(() => {
    getCustomerInfos()
  }, [])

  useEffect(() => {
    getFooterInfos()
  }, [ecommerceFooter])

  useEffect(() => {
    getWhatsappSupportInfo()
  }, [whatsappSupport])

  return (
    <>
      {/* Ícone WhatsApp */}
      {customer && whatsappSupport > 0
        ? <S.WhatsAppIconLink href={whatsappSupportLink} target='_blank'>
            <S.WhatsAppIconWrapperMobile>
              <S.WhatsAppIcon icon={faWhatsapp} size='2x' />
            </S.WhatsAppIconWrapperMobile>
            <S.WhatsAppIconWrapper>
              <S.WhatsAppIcon icon={faWhatsapp} size='xl' />
              Fale conosco
            </S.WhatsAppIconWrapper>
          </S.WhatsAppIconLink>
        : null}
      {/* Footer */}
      {ecommerceFooter && ecommerceFooter.enableFooter === true
        ? <S.CustomFooter>
          <S.CFooterContainer>
            {/* Infos */}
            <S.CFooterInfos>
              <S.CFooterLogoWrapper>
                <S.CFooterLogo src={(customer.person && customer.person.image) ? `${IMAGE_URL}${customer.person.image}` : '/assets/images/logo-bendito.svg'} />
              </S.CFooterLogoWrapper>
              <S.CFooterCompanyName>{customer.person.socialName ? customer.person.socialName : customer.person.companyName}</S.CFooterCompanyName>
              {ecommerceFooter.showRegistrationCode === true
                ? <S.CFooterCompanyCNPJ>CNPJ {maskCNPJ(customer.person.registrationCode)}</S.CFooterCompanyCNPJ>
                : null}
              {ecommerceFooter.address > 0
                ? <>
                  <S.CFooterCompanyAddress>{address}</S.CFooterCompanyAddress>
                  <S.CFooterCompanyCity>{city}</S.CFooterCompanyCity>
                  </>
                : null}
            </S.CFooterInfos>
            {/* Redes sociais */}
            <S.CFooterSocialMedia>
              <S.CFooterCompanySocialMediaWrapper>
                {instagramLink !== ''
                  ? <S.CFooterIconLink href={instagramLink} target='_blank'>
                    <S.CFooterIconWrapper>
                      <S.StyledFontAwesomeSocialMediaIcon icon={faInstagram} size='2x' />
                    </S.CFooterIconWrapper>
                  </S.CFooterIconLink>
                  : null}
                {facebookLink !== ''
                  ? <S.CFooterIconLink href={facebookLink} target='_blank'>
                    <S.CFooterIconWrapper>
                      <S.StyledFontAwesomeSocialMediaIcon icon={faFacebook} size='2x' />
                    </S.CFooterIconWrapper>
                  </S.CFooterIconLink>
                  : null}
                {ecommerceFooter.whatsappContact > 0
                  ? <S.CFooterIconLink href={whatsappLink} target='_blank' style={{ marginRight: 0 }}>
                    <S.CFooterIconWrapper>
                      <S.StyledFontAwesomeSocialMediaIcon icon={faWhatsapp} size='2x' />
                    </S.CFooterIconWrapper>
                    </S.CFooterIconLink>
                  : null}
              </S.CFooterCompanySocialMediaWrapper>
              {/* Contatos */}
              <S.CFooterCompanyContactsWrapper>
                {ecommerceFooter.mail > 0
                  ? <S.CFooterCompanyMail>
                    <S.StyledFontAwesomeIcon icon={faEnvelope} style={{ marginRight: '1rem' }} size='lg' />
                    {mail}
                    </S.CFooterCompanyMail>
                  : null}
                {ecommerceFooter.phone > 0
                  ? <S.CFooterCompanyPhone>
                    <S.StyledFontAwesomeIcon icon={faPhone} style={{ marginRight: '1rem' }} size='lg' />
                    {phone}
                    </S.CFooterCompanyPhone>
                  : null}
              </S.CFooterCompanyContactsWrapper>
            </S.CFooterSocialMedia>
          </S.CFooterContainer>
          </S.CustomFooter>
        : null}
      <S.Footer>
        <S.Text>Copyright &copy; {currentYear} - Bendito  <span>•</span> <br />
          <S.TextLink href='https://bendito.digital/' target='_blank'>bendito.digital</S.TextLink>
        </S.Text>
      </S.Footer>
    </>
  )
}

export default Footer
