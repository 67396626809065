import styled from 'styled-components/macro'
import Icon from '../../commons/Icon'

export const Profile = styled.div``

export const ProfileInner = styled.div`
  max-width: 56.3rem;
  width: 100%;
  margin: 8.3rem auto 0;

  @media ${({ theme }) => theme.screens.sm} {
    max-width: unset;
  }
`

export const ProfileTitle = styled.h2`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -0.1rem;
  color: ${({ theme }) => theme.colors.mono.black};

  @media ${({ theme }) => theme.screens.lg} {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
`

export const ProfileForm = styled.form`
  margin-top: 2rem;
`

export const ProfileFormBox = styled.div`
  margin-bottom: 2rem;
  padding: 3rem;
  background: ${({ theme }) => theme.colors.mono.greyLight};
  border-radius: 1rem;

  @media ${({ theme }) => theme.screens.sm} {
    padding: 2rem;
  }
`

export const ProfileRowNames = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`

export const ProfileRowGrid = styled(ProfileRowNames)``

export const ProfileRowPhones = styled(ProfileRowNames)`

  @media ${({ theme }) => theme.screens.md} {
    grid-template-columns: 1fr;
    gap: 0;
  }
`

export const ProfileRowBirthday = styled.div`
  ${ProfileRowGrid} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const ProfileRowContainer = styled.div``

export const ProfileLabel = styled.span`
  display: block;
  margin-bottom: .5rem;
  font-size: 1.6rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const ProfileFormCheckboxWrapper = styled.div`
  margin-bottom: 2rem;
`
export const ProfileRowAddress = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 1rem;
`

export const ProfileRowState = styled.div`
  margin-bottom: 1rem;
`

export const ProfileRowCEP = styled.div`
  display: grid;
  grid-template-columns: 8fr 1fr;
`

export const InputSearchCEPButton = styled.button`
  background:  ${({ theme }) => theme.colors.orange.default};
  border: 2px solid #E3E6E8;
  border-radius: .6rem;
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 4.8rem;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-top: 1.2rem;
`

export const InputIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.mono.white};
  stroke: ${({ theme }) => theme.colors.mono.white};
`
