import styled, { css } from 'styled-components/macro'
import Icon from '../../commons/Icon'

export const Faq = styled.div`${({ isFaqActive, theme, productDetails }) => css`
  position: relative;
  padding: 2rem 2.4rem;
  background-color: ${theme.colors.mono.greyLight};
  border: .2rem solid ${({ theme }) => theme.colors.mono.greyLight};
  border-radius: .8rem;
  cursor: pointer;
  margin-top: ${productDetails ? '5rem' : 0};
  transition: background-color .3s, border-color .3s;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  ${isFaqActive && css`
  background-color: ${({ theme }) => theme.colors.mono.white};
  border-color: ${theme.colors.orange.default};

  ${FaqIcon} {
      color: ${theme.colors.mono.greyDark};
    }

    ${FaqTitle} {
      color: ${theme.colors.orange.default};
    }

    ${FaqContent} {
      margin-top: 2rem;
    }

  `}
`}`

export const FaqHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FaqIcon = styled(Icon)`${({ theme }) => css`
  width: 2.4rem;
  height: 2.4rem;
  color: ${theme.colors.orange.default};
`}`

export const FaqTitle = styled.div`
  margin-right: 2rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -.03rem;
  color: ${({ theme }) => theme.colors.mono.blackDark};
  transition: color .3s;

  @media ${({ theme }) => theme.screens.md} {
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: -0.2px;
  }
`

export const FaqContent = styled.div`
  margin-top: 0;
  height: 0;
  overflow: hidden;
  transition: height .3s, margin .3s;

  & > * {
    &:not(:last-of-type) {
      margin-bottom: 2.5rem;
    }
  }

  ul {
    list-style: disc;
    margin-left: 2rem;
  }

  a {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`
