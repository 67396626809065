import styled, { css } from 'styled-components/macro'
import Icon from '../../commons/Icon'

export const Collection = styled.div`
  margin-bottom: 12rem;
`

export const CollectionWrapper = styled.div`
  display: flex;
  margin-top: 4.3rem;
  flex-wrap: wrap;
  justify-content: center;
  @media ${({ theme }) => theme.screens.sm} {
    order: 3;
  }
`

export const CollectionFigure = styled.figure`${({ theme }) => css`
  flex-shrink: 0;
  max-width: 56.3rem;
  width: 100%;
  height: 38rem;
  margin-right: 7.3rem;
  background-color: ${theme.colors.mono.white};
  border: 1rem solid ${theme.colors.mono.white};
  border-radius: .8rem;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.08)) drop-shadow(0px 9px 9px rgba(0, 0, 0, 0.04)) drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.03)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.0196802));
  @media ${theme.screens.xl} {
    flex-shrink: unset;
    height: auto;
  }
  @media ${theme.screens.md} {
    margin-right: 0;
    max-width: initial;
  }
`}`

export const CollectionImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const CollectionContent = styled.div`
  flex-grow: 1;
  flex: 1;
  min-width: 28rem;
  @media ${({ theme }) => theme.screens.xl} {
    margin-top: 5rem;
  }
`

export const CollectionTitle = styled.h2`
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5rem;
  letter-spacing: -.2rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const CollectionText = styled.div`
  margin-top: 2rem;

  p {
    &:not(:last-of-type) {
      margin-bottom: 2.5rem;
    }
  }
`

export const CollectionQualities = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1.5px solid ${({ theme }) => theme.colors.mono.grey};

  @media ${({ theme }) => theme.screens['2xl']} {
    grid-template-columns: 1fr;
  }
`

export const CollectionItem = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CollectionItemIconContainer = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.2rem;
  height: 5.2rem;
  margin-right: 3rem;
  background-color: ${({ theme }) => theme.colors.orange.light10};
  border-radius: 50%;
`

export const CollectionItemIcon = styled(Icon)`
  width: 2.8rem;
  height: 2.8rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const CollectionItemContent = styled.div`
  flex-grow: 1;
`

export const CollectionItemTitle = styled.h3`
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -.03rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const CollectionItemText = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const CollectionGridProducts = styled.div`
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: repeat(${({ styleQtd }) => styleQtd}, 1fr);
  gap: 4rem 3.6rem;
  margin-top: 8rem;
  margin-bottom: 8rem;
  /* @media ${({ theme }) => theme.screens.sm} {
    grid-template-columns: 1fr;
  } */
`
export const FullW = styled.div`
width: 100%;
`
