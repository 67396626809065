import styled from 'styled-components/macro'

export const OrderPrices = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2.3rem 4rem;
  background: ${({ theme }) => theme.colors.mono.greyLight};
  border-radius: .5rem;
  flex-wrap: wrap;
  gap: 1.6rem;
  @media ${({ theme }) => theme.screens.sm} {
    justify-content: center;
  }
`

export const OrderPricesItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const OrderPricesLabel = styled.span`
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const OrderPricesValue = styled(OrderPricesLabel)`
  color: ${({ theme }) => theme.colors.mono.black};
`

export const OrderPricesTotal = styled(OrderPricesItem)`
  align-items: flex-end;
`

export const OrderPricesLabelTotal = styled(OrderPricesValue)`
  font-weight: 700;
`

export const OrderPricesValueTotal = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  color: ${({ theme }) => theme.colors.orange.default};
`
