import styled from 'styled-components/macro'

const Container = styled.div`
  max-width: 180.6rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  @media ${({ theme }) => theme.screens.xs} {
    padding: 0 1.5rem;
  }
`

export default Container
