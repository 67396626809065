import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTitle } from '../../hooks/useTitle'
import { toast } from 'react-toastify'
import { useAuth } from '../../context/AuthContext'
import api from '../../services/api'
import * as S from './styles'
import { IMAGE_URL } from '../../constants'
import LoadingBackground from '../../components/LoadingBackground'
import { getSubdomain } from '../../util/getSubdomain'

const Login = () => {
  const { signIn, authenticated } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isForgetPassword, setIsForgetPassword] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [waitingTheme, setWaitingTheme] = useState(false)
  const [formattedInviteLink, setFormattedInviteLink] = useState()
  const navigate = useNavigate()
  /* eslint-disable no-unused-vars */
  const { register, handleSubmit, formState: { errors } } = useForm()
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'));

  const hangleToggleNewPassword = () => {
    setIsForgetPassword(!isForgetPassword)
  }

  const handleLogin = async data => {
    const { username, password } = data
    setIsLoading(true)
    const id = toast.loading('Acessando área logada...')
    const signResponse = await signIn(username, password)
    if (signResponse.success) {
      toast.update(id, {
        render: signResponse.successMessages[0],
        type: 'success',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
    } else {
      toast.update(id, {
        render: signResponse.errorMessages[0],
        type: 'error',
        isLoading: false,
        delay: 750,
        autoClose: 5000,
      })
    }
    setIsLoading(false)
  }

  const handleLoginError = (errors, e) => {
    Object.entries(errors).forEach(entry => {
      toast.warning(entry[1].message)
    })
  }

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(visible => !visible)
  }

  const handleNewPassword = async data => {
    const { username } = data
    setIsLoading(true)
    const response = await api.post(`/Authorize/Recovery/Buyer/${username}`)
    if (response.data.success) {
      toast(response.data.successMessages[0], {
        type: 'success',
        isLoading: false,
        delay: 750,
        autoClose: 5000,
      })
    } else {
      toast(response.data.errorMessages[0], {
        type: 'error',
        isLoading: false,
        delay: 750,
        autoClose: 5000,
      })
    }
    setIsLoading(false)
  }

 

  const getURLInvite = async () => {
    const apiResponse = customerInfo?.inviteLink
    const subdomain = getSubdomain()
    if (apiResponse?.includes('portal-staging')) {
      const formattedStagInvite = apiResponse?.replace('portal-staging', subdomain).toString()
      setFormattedInviteLink(formattedStagInvite)
    } else {
      const formattedInvite = apiResponse?.replace('portal', subdomain).toString()
      setFormattedInviteLink(formattedInvite)
    }
  }

  useEffect(() => {
    getURLInvite()
  }, [customerInfo])

  useEffect(() => {
    if (authenticated) {
      setTimeout(() => {
        navigate('/companies')
      }, 2000)
    }
  }, [authenticated, navigate])

  useTitle('Bendito - Acelere suas vendas!')

  return (waitingTheme
    ? <LoadingBackground />
    : <S.Login>
      <S.LoginContainer>
        <S.LoginWrapper>
          <S.LoginInner>
            <S.LoginLogoWrapper>
              <S.LoginLogo src={customerInfo?.logo ? `${IMAGE_URL}${customerInfo.logo}` : '/assets/images/logo-bendito-white.svg'} />
            </S.LoginLogoWrapper>
            <S.LoginTitle>Bem-vindo(a) ao seu portal de compras B2B</S.LoginTitle>
            {!!customerInfo && customerInfo.b2BSellingInfoDisabled 
              ? <S.LoginDisabledText>Página indisponível. Contate seu administrador.</S.LoginDisabledText>
              :<S.LoginText>Informe seus dados para acessar o portal:</S.LoginText>
            }

            {(!customerInfo || !customerInfo.b2BSellingInfoDisabled) &&
              (!isForgetPassword
                ? (
                  <S.LoginBox>
                    <form onSubmit={handleSubmit(handleLogin, handleLoginError)}>
                      <S.HomeField>
                        <S.HomeFieldInput
                          type='text'
                          placeholder='&nbsp;'
                          {...register('username', { required: 'E-mail é obrigatório' })}
                        />
                        <S.HomeFieldLabel>
                          Seu e-mail de acesso:
                        </S.HomeFieldLabel>
                      </S.HomeField>
                      <S.HomeField>
                        <S.HomeFieldInput
                          type={isPasswordVisible ? 'text' : 'password'}
                          placeholder='&nbsp;'
                          {...register('password', { required: 'Senha é obrigatório' })}
                        />
                        <S.HomeFieldLabel>
                          Informe sua senha:
                        </S.HomeFieldLabel>
                        <S.LoginFieldInputPasswordButton
                          isActive={isPasswordVisible}
                          type='button'
                          onClick={handlePasswordVisibility}
                        >
                          <S.LoginIcon type={isPasswordVisible ? 'eyeOff' : 'eye'} />
                        </S.LoginFieldInputPasswordButton>
                      </S.HomeField>
                      <S.HomeFieldWrapper>
                        <S.HomeFieldButton type='button' onClick={hangleToggleNewPassword}>Esqueci minha senha</S.HomeFieldButton>
                      </S.HomeFieldWrapper>
                      <S.LoginButton type='submit' disabled={isLoading}>
                        Entrar
                      </S.LoginButton>
                      {customerInfo
                        ? <S.SignUpText>
                            Não possui uma conta?
                            <S.SignUpLink href={formattedInviteLink} target='_blank'>Cadastre-se</S.SignUpLink>
                          </S.SignUpText>
                        : null}
                    </form>
                  </S.LoginBox>
                  )
                : (
                  <>
                    <S.LoginBox>
                      <S.LoginBoxTitle>Esqueceu sua senha?</S.LoginBoxTitle>
                      <S.LoginBoxText>Informe seu e-mail para enviarmos uma nova senha</S.LoginBoxText>

                      <form onSubmit={handleSubmit(handleNewPassword, handleLoginError)}>
                        <S.HomeField>
                          <S.HomeFieldInput
                            type='text'
                            placeholder='&nbsp;'
                            {...register('username', { required: 'Informe o e-mail cadastrado para recuperar sua senha' })}
                          />
                          <S.HomeFieldLabel>
                            Seu e-mail de acesso:
                          </S.HomeFieldLabel>
                        </S.HomeField>

                        <S.LoginButton type='submit' disabled={isLoading}>
                          Enviar nova senha
                        </S.LoginButton>
                      </form>
                    </S.LoginBox>

                    <S.LoginPasswordLink type='button' onClick={hangleToggleNewPassword}>
                      <S.LoginIcon type='leftArrow' />
                      Voltar para login
                    </S.LoginPasswordLink>
                  </>
                  ))
            }
          </S.LoginInner>
        </S.LoginWrapper>
      </S.LoginContainer>
      </S.Login>
  )
}

export default Login
