import styled, { css } from 'styled-components/macro'
import Tag from '../../components/Tag'
import MessageBox from '../../components/MessageBox'
import { Button, ButtonOutline } from '../../commons/Button'
import Sidebar from '../../components/Sidebar'
import Icon from '../../commons/Icon'
import { SidebarMobileCntDrop } from '../../components/Sidebar/styles'

export const OrdersDetail = styled.div`
  margin-bottom: 12.7rem;
`
export const CntTextArea = styled.div`
  min-width: 20rem;
  flex: 1;
  label {
    height: 2.7rem;
    background: ${({ theme }) => theme.colors.mono.greyLight};
    border: 1px solid ${({ theme }) => theme.colors.mono.grey};
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    line-height: 17px;
    padding: 0 2.2rem;
    color: ${({ theme }) => theme.colors.mono.greyDarker};
  }
  textarea {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.colors.mono.grey};
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    padding: 1rem 2.2rem;
    color: ${({ theme }) => theme.colors.mono.black};
    height: fit-content;
  }
`
export const OrdersDetailTop = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  align-items: center;
  margin-top: 6rem;
`

export const OrdersDetailTitle = styled.h2`
  margin-right: 4rem;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -0.1rem;
`

export const OrdersDetailDate = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4rem;
`

export const OrdersDetailDateLabel = styled.span`
  margin-right: 1rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const OrdersDetailDateTag = styled(Tag)`
  color: ${({ theme }) => theme.colors.mono.black};
  background: ${({ theme }) => theme.colors.mono.greyLight};
`

export const OrdersDetailStatus = styled.div`
  display: flex;
  align-items: center;
`

export const BtnAddProduct = styled(Button)`
  margin-left: auto;
`

export const OrdersDetailStatusLabel = styled.div`
  margin-right: 1rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const OrdersDetailStatusTag = styled(Tag)`
  ${({ theme, status }) => css`
    ${status === 2 &&
    css`
      color: ${theme.colors.orange.default};
      background-color: ${theme.colors.orange.light10};
    `}

    ${status === 1 &&
    css`
      color: ${theme.colors.green.default};
      background-color: ${theme.colors.green.light10};
    `}

  ${status === 7 &&
    css`
      color: ${theme.colors.yellow.default};
      background-color: ${theme.colors.yellow.light10};
    `}
  `}
`

// List here
export const OrdersMessageBox = styled(MessageBox)`
  margin-top: 2rem;
`
export const BtnSearch = styled(Button)`
  min-width: 23.5rem;
`
export const SelectPayment = styled.div`
  flex: 1;
  label {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #8f99a3;
    text-align: center;
  }
`
export const CntModal = styled.div`
  min-width: 75vw;
  max-width: 75vw;
  .top {
    display: flex;
    justify-content: space-between;
    .left {
      .title {
        font-weight: 700;
        font-size: 3.2rem;
        line-height: 38px;
        letter-spacing: -1px;
        color: ${({ theme }) => theme.colors.mono.black};
      }
      .desc {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 22px;
        letter-spacing: -0.3px;
        color: ${({ theme }) => theme.colors.orange.default};
      }
    }

    .right {
      button {
      }
    }
  }

  .cnt {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    min-height: 65vh;
    gap: 2rem;
    .category {
      max-height: 75vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .itens {
      flex: 1;
      .search {
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        .input {
          padding: 8px 15px;
          height: 52px;
          flex: 1;
          min-width: 20rem;
          background: #ffffff;
          border: 2px solid ${({ theme }) => theme.colors.mono.grey};
          border-radius: 6px;
        }

        .button {
        }
      }
      .result-prodc {
        margin-top: 50px;
        border-radius: 8px;
        padding: 2rem;
        max-height: 64vh;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 70%;
        max-width: 95vw;
      }
      .result {
        margin-top: 50px;
        background: ${({ theme }) => theme.colors.mono.greyLight};
        border-radius: 8px;
        padding: 2rem;
        max-height: 64vh;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 70%;
        .text {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.2px;
          color: ${({ theme }) => theme.colors.mono.greyDarker};
          max-width: 39rem;
          margin: 2rem auto;
        }
        .not-found {
          margin-top: 5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      ${SidebarMobileCntDrop} {
        max-width: 95%;
      }
    }
  }
`
export const SidebarEdit = styled(Sidebar)`
  max-height: 30vh;
  overflow-y: auto;
`
export const HeaderSearch = styled.input`
  max-width: 80rem;
  width: 100%;
  padding: 1.3rem;
  /* Mono/Grey */
  border: 2px solid #e3e6e8;
  border-radius: 6px;
  margin-right: 4rem;

  @media ${({ theme }) => theme.screens['3xl']} {
    margin-right: 1rem;
  }
`
export const OrdersDetailOtherInfos = styled.div`
  display: flex;
  margin: 3rem 0 8rem 4.4rem;
  padding-top: 3rem;
  border-top: 1.5px solid ${({ theme }) => theme.colors.mono.greyLight};
  justify-content: flex-end;
  gap: 2.4rem;
  flex-wrap: wrap;

  ${OrdersMessageBox} {
    flex-grow: 1;
    margin: 0 1.8rem 0 0;
  }
  @media ${({ theme }) => theme.screens.lg} {
    margin: 3rem 0 8rem 0rem;
  }
`

export const OrdersDetailOtherWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 68.7rem;
  width: 100%;
`

export const OrdersDetailActions = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
`

export const OrdersDetailButton = styled(ButtonOutline)`
  min-height: 4.2rem;
  margin-top: auto;
  padding: 1.082rem 2rem;
  width: 15.6rem;
`
export const OrdersDetailButtonBuy = styled(Button)`
  /* margin-left: 2rem; */
  min-height:4.2rem;
  margin-top: auto;
  padding: 1.082rem 2rem;
  width: 17.1rem
`
export const ProductQtd = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  button {
    width: 3.2rem;
    height: 3.2rem;
    font-size: 3rem;
    background: ${({ theme }) => theme.colors.mono.grey};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.mono.greyDarker};
  }

  input {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
    letter-spacing: -0.2px;
    color: ${({ theme }) => theme.colors.mono.black};
    border: none;
    max-width: 60px;
    height: 3.2rem;
    border-radius: 32px;
    transition: all 0.3s ease;
    &:hover {
      background: ${({ theme }) => theme.colors.mono.greyLight};
    }
  }
`
export const SidebarMenuItemLinkLabel = styled.span`
  flex-grow: 1;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  color: ${({ theme }) => theme.colors.orange.default};
  transition: color 0.3s;
`
export const SidebarMenuItemLinkIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.mono.greyDark};
`
export const SidebarMenuItemLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 1.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.orange.default};
    * {
      color: ${({ theme }) => theme.colors.mono.white};
      &:last-child {
        color: inherit;
      }
    }
    ${SidebarMenuItemLinkLabel},
    ${SidebarMenuItemLinkIcon} {
      color: ${({ theme }) => theme.colors.mono.white};
    }
  }
`
export const ModalProduct = styled.div``
export const CntModalProducts = styled.div`
  max-width: 1050px;
  li {
    margin-bottom: 3rem;
    align-items: center;
  }
`
export const RowIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.mono.greyDark};
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`
export const CntBtnModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 3.2rem;
`
