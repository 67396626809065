import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Button } from '../../commons/Button'

export const ProductCard = styled.div`
  ${({ theme, model }) => css`
    position: relative;
    padding: 1rem;
    background: ${theme.colors.mono.white};
    box-shadow: ${theme.shadows.card};
    border-radius: 0.8rem;
    min-width: 10rem;
    @media ${({ theme }) => theme.screens.md} {
      padding: ${model === 'shadow' ? '1.5rem 1.5rem 3rem' : 0};
      box-shadow: ${model === 'shadow' ? theme.shadows.card : 'unset'};
      
    }
  `}
`

export const ProductCardContent = styled.div`
  width: calc(100% - 19rem);
  padding: 2rem 4rem 3rem 2rem;
  background: ${({ theme }) => theme.colors.mono.greyLight};
  border-radius: 0 0.8rem 0.8rem 0;
  min-height: 24rem;
  ${({ styleQtd }) => styleQtd === 2 && 'height: 100%;'}
  
  @media ${({ theme }) => theme.screens.md} {
    background: unset;
    width: 100%;
    padding: 0 1rem;
  }
  @media ${({ theme }) => theme.screens.xs} {
    padding: 0;
  }
`

export const ProductCardWrapper = styled.div`
  max-width: 34.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.screens.md} {
    max-width: unset;
  }
`

export const ProductCardTitle = styled.h3`
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.8rem;
  letter-spacing: -0.1rem;
  color: ${({ theme }) => theme.colors.mono.black};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media ${({ theme }) => theme.screens.md} {
    max-height: 5.8rem;
    text-align: center;
    -webkit-line-clamp: 1;
  }
  @media ${({ theme }) => theme.screens.sm} {
    font-size: 1.8rem;
  }
`
export const ProductCardSubTitle = styled.small`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.0rem;
  letter-spacing: -0.1rem;
  color: ${({ theme }) => theme.colors.mono.black};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media ${({ theme }) => theme.screens.md} {
    max-height: 3.0rem;
    text-align: center;
    -webkit-line-clamp: 1;
  }
  @media ${({ theme }) => theme.screens.sm} {
    font-size: 1.0rem;
  }
`

export const ProductCardInfo = styled.span`
  display: block;
  margin-top: 0.7rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
  @media ${({ theme }) => theme.screens.md} {
    text-align: center;
  }
  @media ${({ theme }) => theme.screens.sm} {
    font-size: 1.3rem;
  }
`

export const ProductCardPriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 4rem 0;

  @media ${({ theme }) => theme.screens.md} {
    justify-content: center;
    margin: 1.5rem 0;
  }
`

export const ProductCardPriceLabel = styled.span`
  margin-right: 1rem;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 2.2rem;
  letter-spacing: -0.03rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const ProductCardPrice = styled.span`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -0.1rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const ProductCardOriginalPriceContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 4rem 0;

  @media ${({ theme }) => theme.screens.md} {
    margin: 1.5rem;
  }
`

export const ProductCardOriginalPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  
  @media ${({ theme }) => theme.screens.md} {
    justify-content: center;
    margin: 0;
  }
`

export const ProductCardOriginalPrice = styled.span`
  font-weight: 700;
  font-size: 1.8rem;
  letter-spacing: -0.1rem;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const ProductCardFigure = styled.figure`
  ${({ styleQtd, model }) => css`
    position: absolute;
    right: 3rem;
    top: ${styleQtd === 3 ? '42%' : '50%'};
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: ${({ theme }) => theme.colors.mono.white};
    width: ${styleQtd === 3 ? '20rem' : '26rem'};
    height: 28rem;
    border-radius: 0.8rem;

    @media ${({ theme }) => theme.screens['3xl']} {
      width: 20rem;
      max-height: 24rem;
    }

    @media ${({ theme }) => theme.screens.xl} {
      width: 18rem;
      max-height: 22rem;
    }

    @media ${({ theme }) => theme.screens.md} {
      position: relative;
      right: unset;
      top: unset;
      transform: unset;
      height: fit-content;
      height: 30rem;
      width: 100%;
    }
    @media ${({ theme }) => theme.screens.xs} {
      position: relative;
      right: unset;
      top: unset;
      transform: unset;
      height: fit-content;
      height: ${model === 'shadow' ? 'auto' : '15rem'};
      width: 100%;
    }
  `}
`

export const ProductCardImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`
export const MobileBtns = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  justify-content: center;
`

export const ProductCardButton = styled(Button)`
${({ model }) => css`
  position: relative;
  z-index: 100;
  display: block;
  width: 100%;
  flex: 1;

  @media ${({ theme }) => theme.screens.md} {
    width: ${model === 'shadow' ? 'auto' : '100%'};
    margin: ${model === 'shadow' ? '0 auto' : 'initial'};
    padding: ${model === 'shadow' ? '1.5rem 2rem' : '1.5rem 1rem'};
  }
  @media ${({ theme }) => theme.screens.xs} {
    padding: 1rem 0.5rem;
    font-size: 1.4rem
  }
  `}
`

export const ProductCardLink = styled(Link)`
  display: table;
  margin: 1.5rem auto 0;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.orange.default};
  flex: 1;
  &:hover {
    color: ${({ theme }) => theme.colors.mono.black};
  }
  @media ${({ theme }) => theme.screens.md} {
    color: ${({ theme }) => theme.colors.mono.greyDarker};
  }
`
export const DetailBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`
