import styled, { css } from 'styled-components/macro'
import Icon from '../../commons/Icon'

export const ProductCartQuantityIcon = styled(Icon)``

export const ProductCartQuantityWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: -10px;

  ${ProductCartQuantityIcon} {
    width: 3rem;
    height: 3rem;
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const ProductCartQuantityQtd = styled.span`
  position: absolute;
  left: 10px;
  top: -0.8rem;
  padding: 0 0.8rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.7rem;
  background: ${({ theme }) => theme.colors.yellow.default};
  border: 0.2rem solid ${({ theme }) => theme.colors.mono.white};
  border-radius: 10rem;
  color: ${({ theme }) => theme.colors.mono.black};
`