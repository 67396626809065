import styled from 'styled-components'

export const BannerEndPage = styled.a`
  display: block;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.mono.white};
  box-shadow: ${({ theme }) => theme.shadows.card};
  border-radius: 0.8rem;
  margin-bottom: 4rem;
  height: 15rem;
  max-height: 15rem;
`

export const BannerEndPageImage = styled.img`
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: fill;
`
