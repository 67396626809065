import styled from 'styled-components/macro'
import SlideOpportunities from '../../SlideOpportunities'

export const CartEmpty = styled.div`
  margin-top: 8.3rem;

  @media ${({ theme }) => theme.screens.md} {
    margin-top: 8rem;
  }
`

export const CartEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CartEmptyFigure = styled.figure``

export const CartEmptyImg = styled.img``

export const CartEmptyText = styled.p`
  max-width: max-content;
  width: 100%;
  margin-top: 3.9rem;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-align: center;
  letter-spacing: -0.1rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};

  @media ${({ theme }) => theme.screens.md} {
    margin-top: 3rem;
  }

  strong {
    color: ${({ theme }) => theme.colors.mono.black};
  }
`

export const CartEmptyArrow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 8rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    transform: translateY(-50%);
    width: 100%;
    height: .15rem;
    background: ${({ theme }) => theme.colors.mono.greyLight};
  }
`

export const CartEmptyArrowImg = styled.img`
  width: 8.7rem;
  height: 8.7rem;
  background: ${({ theme }) => theme.colors.mono.white};
`

export const CartEmptyProducts = styled.div`
  margin-top: 8rem;
`

export const CartEmptyProductsTitle = styled.h2`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -.1rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.mono.black};

  span {
    color: ${({ theme }) => theme.colors.green.default};
  }
`

export const CartEmptyProductsGrid = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem 3.6rem;

  @media ${({ theme }) => theme.screens.sm} {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`

export const CartEmptySlideOpportunities = styled(SlideOpportunities)`
  margin-top: 8rem;
`
