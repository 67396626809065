import styled from 'styled-components/macro'
import { ButtonOutline } from '../../commons/Button'

export const BannerSidebar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 26.5rem;
  width: 100%;
  padding: 1.5rem 1.5rem 3rem;
  background-color: ${({ theme }) => theme.colors.mono.white};
  border-radius: .8rem;
  box-shadow: ${({ theme }) => theme.shadows.card};
`

export const Image = styled.img`
  width: 100%;
  margin-bottom: 1rem;
`

export const Text = styled.p`
  text-align: center;
`

export const Button = styled(ButtonOutline)`
  margin-top: 1rem;
`
