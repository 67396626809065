import styled, { css } from 'styled-components/macro'

export const Institutional = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin: 4rem;
  padding: 0 10rem;

  @media ${({ theme }) => theme.screens.md} {
    padding: 0;
  }
`

export const InstitutionalTitle = styled.h2`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const InstitutionalWrapper = styled.div`
  width: 100%
`