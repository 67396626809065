import styled from 'styled-components/macro'
import { Swiper } from 'swiper/react'

export const Gallery = styled.div``

export const GalleryFrame = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem;
  background: ${({ theme }) => theme.colors.mono.white};
  box-shadow: 0px 3px 15px #EAECF1;
  border-radius: .5rem;
  height: 43.5rem;
  @media ${({ theme }) => theme.screens.md} {
    max-height: 30rem;
  }
`
export const GalleryImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const GalleryMain = styled(Swiper)`
  display: block;
  padding: 1.5rem;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
`

export const GalleryThumbs = styled(Swiper)`
  display: block;
  margin-top: 4rem;

  ${GalleryFrame} {
    height: 17.5rem;
    box-shadow: unset;
  }

  ${GalleryImage} {
    width: auto;
    object-fit: cover;
  }

  .swiper-slide {
    border: 0.2rem solid ${({ theme }) => theme.colors.mono.greyLight};
    border-radius: 0.5rem;
    transition: border .3s;

    &.swiper-slide-thumb-active {
      border-color: ${({ theme }) => theme.colors.orange.default};
    }
  }

`
