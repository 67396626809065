import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import Icon from '../../commons/Icon'
import Notifications from '../Notifications'

export const Header = styled.header`
  ${({ $changeHeaderColor, theme }) => $changeHeaderColor === true ? `background-color: ${theme.colors.orange.default};` : ''};
  ${({ $changeHeaderColor, $horizontalMenu }) => $changeHeaderColor && !$horizontalMenu ? `margin-bottom: 1rem;` : ''};
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4.5rem 0;
  position: relative;
  @media ${({ theme }) => theme.screens.lg} {
    padding: 4.5rem 0;
    flex-wrap: wrap;
  }
`

export const HeaderLogoLink = styled(Link)`
  flex-shrink: 0;
  width: 19.8rem;
  margin-right: 1rem;

  @media ${({ theme }) => theme.screens.lg} {
    padding-left: 6rem;
    width: 26rem;
  }
  @media ${({ theme }) => theme.screens.xs} {
    padding-left: 5rem;
    width: 20rem;
  }
`

export const HeaderLogo = styled.img``

export const HeaderMiddleWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${({ theme }) => theme.screens.md} {
    justify-content: flex-end;
  }
`

export const HeaderSearch = styled.input`
  width: 100%;
  padding: 1.3rem;
  padding-left: 5rem;
  /* Mono/Grey */
  border: 2px solid #e3e6e8;
  border-radius: 6px;
  margin-right: 4rem;

  @media ${({ theme }) => theme.screens['3xl']} {
    margin-right: 1rem;
  }
`
export const HeaderSearchMobile = styled(HeaderSearch)``

export const CntSearch = styled.div`
  max-width: 80rem;
  width: 100%;
  position: relative;
  @media ${({ theme }) => theme.screens.md} {
    display: none;
  }
`
export const CntSearchMobile = styled(CntSearch)`
  max-width: 80rem;
  width: 100%;
  position: relative;
  display: none;
  @media ${({ theme }) => theme.screens.md} {
    display: block;
    margin-top: 4rem;
  }
`
export const HeaderIconSearch = styled(Icon)`
  position: absolute;
  left: 1.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  stroke: ${({ theme }) => theme.colors.orange.default};
`
export const HeaderIcon = styled(Icon)``
export const HeaderIconMobile = styled(HeaderIcon)`
  margin-right: 1rem;
`

export const HeaderLastWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${({ theme }) => theme.screens.lg} {
    display: none;
  }
`

export const HeaderLinkWrapper = styled.div`
  position: relative;
  margin-right: 2rem;
  padding-right: 2rem;

  @media ${({ theme }) => theme.screens['2xl']} {
    margin-right: 1rem;
    padding-right: 1rem;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.2rem;
    height: 1.5rem;
    border-radius: 0.1rem;
    background-color: #e3e6e8;
  }
`

export const HeaderNotificationBtn = styled.button`
  ${({ isNotificationActive, theme, $changeHeaderColor }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.mono.greyDarker};

    &:hover {
      color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.grey : theme.colors.orange.default};
    }

    ${isNotificationActive &&
    css`
      color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default};
    `}
  `}
`
export const HeaderNotificationBtnMobile = styled(HeaderNotificationBtn)`
  flex-direction: row;
`

export const HeaderNotifications = styled(Notifications)`
  ${({ isNotificationActive }) => css`
    visibility: hidden;
    position: absolute;
    left: calc(50% - 1rem);
    top: 6rem;
    transform: translate(-50%, 2rem);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

    ${isNotificationActive &&
    css`
      visibility: visible;
      transform: translate(-50%, 0);
      opacity: 1;
    `}
  `}
`

export const HeaderOrdersLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 4.5rem;
  width: 100%;
  color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.mono.greyDarker};

  &:hover {
    color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.grey : theme.colors.orange.default};
  }

  ${HeaderIcon} {
    width: 2.6rem;
    height: 2.6rem;
  }
`
export const HeaderOrdersLinkMobile = styled(HeaderOrdersLink)`
  flex-direction: row;
  max-width: 100%;
`
export const HeaderLinkLabel = styled.div`
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
`
export const HeaderLinkLabelMobile = styled(HeaderLinkLabel)`
  text-align: left;
  font-size: 1.6rem;
`
export const HeaderMobileIcon = styled.div`
  position: absolute;
  width: 3.6rem;
  height: 2.8rem;
  margin: auto;
  top: 5rem;
  left: 0;
  cursor: pointer;
  display: none;
  @media ${({ theme }) => theme.screens.lg} {
    display: block;
  }
`
export const btnLeftMobile = styled.div`
  ${({ isActive }) => css`
    background-color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default};
    position: absolute;
    width: 1.5rem;
    height: 0.4rem;
    top: 1rem;
    left: 0px;
    transition-duration: 0.5s;
    border-radius: 10px 0px 0px 10px;
    &:before {
      background-color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default};
      position: absolute;
      width: 1.5rem;
      height: 0.4rem;
      content: "";
      top: -1rem;
      transition-duration: 0.5s;
      border-radius: 10px 0px 0px 10px;
    }
    &:after {
      background-color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default};
      position: absolute;
      width: 1.5rem;
      height: 0.4rem;
      content: "";
      top: 1rem;
      transition-duration: 0.5s;
      border-radius: 10px 0px 0px 10px;
    }
    ${isActive &&
    css`
      transition-duration: 0.5s;
      background: transparent;

      &:before {
        transform: rotateZ(45deg) scaleX(1.4) translate(0.2rem, 0.2rem);
      }
      &:after {
        transform: rotateZ(-45deg) scaleX(1.4) translate(0.2rem, -0.2rem);
      }
    `}
  `}
  @media ${({ theme }) => theme.screens.xs} {
    transform: scale(0.8) translateX(2px);
  }
`
export const btnRightMobile = styled.div`
  ${({ isActive }) => css`
    background-color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default};
    position: absolute;
    width: 1.5rem;
    height: 0.4rem;
    top: 1rem;
    left: 1.5rem;
    transition-duration: 0.5s;
    border-radius: 0 10px 10px 0;
    &:before {
      background-color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default};
      position: absolute;
      width: 1.5rem;
      height: 0.4rem;
      content: "";
      top: -1rem;
      transition-duration: 0.5s;
      border-radius: 0 10px 10px 0;
    }
    &:after {
      background-color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default};
      position: absolute;
      width: 1.5rem;
      height: 0.4rem;
      content: "";
      top: 1rem;
      transition-duration: 0.5s;
      border-radius: 0 10px 10px 0;
    }
    ${isActive &&
    css`
      transition-duration: 0.5s;
      background: transparent;
      &:before {
        transform: rotateZ(-45deg) scaleX(1.4) translate(-0.2rem, 0.2rem);
      }
      &:after {
        transform: rotateZ(45deg) scaleX(1.4) translate(-0.2rem, -0.2rem);
      }
    `}
  `}
  @media ${({ theme }) => theme.screens.xs} {
    transform: scale(0.8) translateX(-3px);
  }
`
export const btnTextMobile = styled.div`
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.mono.black};
  margin-top: 3rem;
`
export const PersonalMenuSubmenuItem = styled.li`
  position: relative;
  padding: 1.5rem 0;
`

export const PersonalMenuSubmenu = styled.ul`
  ${({ theme, isMenuActive }) => css`
    visibility: hidden;
    position: absolute;
    top: 11rem;
    left: 0;
    transform: translateY(2rem);
    max-width: 28rem;
    width: 28rem;
    z-index: 120;
    padding: 0.5rem 2rem;
    background-color: ${theme.colors.mono.white};
    border-radius: 0.5rem;
    box-shadow: ${theme.shadows.dark};
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

    ${isMenuActive &&
    css`
      visibility: visible;
      transform: translateY(0);
      opacity: 1;
    `}

    &::before {
      content: "";
      position: absolute;
      left: 1rem;
      top: 0;
      transform: translateY(-100%);
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-bottom: 1rem solid ${theme.colors.mono.white};
    }

    ${PersonalMenuSubmenuItem} {
      &:not(:last-of-type) {
        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: calc(100% - 2.8rem);
          height: 0.15rem;
          background-color: ${theme.colors.mono.grey};
        }
      }
    }
  `}
`

export const PersonalMenuSubmenuLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.black};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`
export const PersonalMenuSubmenuButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.black};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const PersonalMenuSubmenuIcon = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.orange.default};
`
export const HeaderLinkWrapperMobile = styled(HeaderLinkWrapper)`
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mono.greyLight};
  &:last-child {
    border-bottom: 0;
  }
  &::after {
    display: none;
  }
`

export const CartIcon = styled(Icon)``

export const CartHeaderWrapper = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 4rem;
  @media ${({ theme }) => theme.screens.sm} {
      margin-left: auto;
      display: none;
  }

  ${CartIcon} {
    width: 3.6rem;
    height: 3.6rem;
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const MobileLoginBtn = styled.button`${({ theme }) => css`
  @media ${({ theme }) => theme.screens.lg} {
    display: inline-block;
    padding: 1rem 1.5rem;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2rem;
    letter-spacing: -0.03rem;
    text-align: center;
    color: ${theme.colors.mono.white};
    background: ${theme.colors.orange.default};
    border-radius: .5rem;
    margin-left: 4rem;

    &:before {
      content: "Entrar/Cadastrar";
    }

    &:hover{
      background: ${theme.colors.orange.dark};
    }

    &:active {
      background: ${({ theme }) => theme.colors.green.default};
      box-shadow: 0 0 0 .4rem ${theme.colors.green.light10};
    }
  }

  @media ${({ theme }) => theme.screens.xs} {
    padding: 0.6rem 1rem;
    font-size: 1.5rem;
    margin: 0;
  }
  
`}`

export const LoginBtn = styled.button`${({ theme }) => css`
  display: inline-block;
  padding: 1rem 1.5rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -0.03rem;
  text-align: center;
  color: ${theme.colors.mono.white};
  background: ${theme.colors.orange.default};
  border-radius: .5rem;

  &:hover{
    background: ${theme.colors.orange.dark};
  }

  &:active {
    background: ${({ theme }) => theme.colors.green.default};
    box-shadow: 0 0 0 .4rem ${theme.colors.green.light10};
  }

`}`

export const LoginBtnLinkWrapper = styled(Link)`
  position: relative;
  margin-right: 2rem;
  padding-right: 2rem;

  @media ${({ theme }) => theme.screens['2xl']} {
    margin-right: 1rem;
    padding-right: 1rem;
  }
`
