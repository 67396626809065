import styled from 'styled-components/macro'
import Icon from '../../../commons/Icon'
import { Swiper } from 'swiper/react'

export const MoreProducts = styled.div``

export const MoreProductsTitle = styled.h1`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.colors.mono.black};

  span {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const MoreProductsSlideContainer = styled.div`
  margin-top: 3rem;
`

export const MoreProductsSwiper = styled(Swiper)`
  padding: 3rem 2rem;
  margin: 0 6rem;

  @media ${({ theme }) => theme.screens.md} {
    margin: 0;
  }

  .swiper-slide {
    height: auto;
  }
`

export const MoreProductsNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
`

export const MoreProductsPagination = styled.div`
  position: relative;
  left: unset !important;
  bottom: unset !important;
  width: auto !important;
  margin: 0 4rem;
  display: flex;
  .swiper-pagination-bullet {
    opacity: 1;
    background: ${({ theme }) => theme.colors.mono.greyDark};
    cursor: pointer;
    border-radius: 0.4rem;
    transition: background-color 0.3s, width 0.3s;
    display: none;
    &.swiper-pagination-bullet-active-next-next,
    &.swiper-pagination-bullet-active-next,
    &.swiper-pagination-bullet-active-prev,
    &.swiper-pagination-bullet-active-prev-prev,
    &.swiper-pagination-bullet-active {
      display: block;
      width: 1rem;
      height: 1rem; 
    }
    &.swiper-pagination-bullet-active-next,
    &.swiper-pagination-bullet-active-prev {
      transform: scale(0.6);
      animation-name: zoomIn6;
      animation-duration: 0.3s;
      animation-fill-mode: both;
    }
    &.swiper-pagination-bullet-active-next-next,
    &.swiper-pagination-bullet-active-prev-prev {
      transform: scale(0.3);
      animation-name: zoomIn3;
      animation-duration: 0.3s;
      animation-fill-mode: both;
    }
    &:not(:last-of-type) {
      margin-right: 1.5rem;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.orange.default};
    }

    &.swiper-pagination-bullet-active {
      width: 4rem;
      background: ${({ theme }) => theme.colors.orange.default};
    }
    @keyframes zoomIn3 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(0.3);
      }
    }
    @keyframes zoomIn6 {
      0% {
        transform: scale(0.3);
      }
      100% {
        transform: scale(0.6);
      }
    }
  }
`

export const MoreProductsBtnIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1rem;
`

export const MoreProductsBtn = styled.button`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.orange.default};

  &:hover {
    color: ${({ theme }) => theme.colors.mono.black};
  }

  &.swiper-button-disabled {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.mono.greyDark};
  }

  &[data-js="more-products-btn-prev"] {
    ${MoreProductsBtnIcon} {
      transform: rotate(-90deg);
    }
  }

  &[data-js="more-products-btn-next"] {
    ${MoreProductsBtnIcon} {
      transform: rotate(90deg);
    }
  }
`
