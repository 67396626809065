import { useState, useEffect } from 'react'
import api from '../../services/api'
import * as S from './styles'
import { useOnOutsideClick } from '../../hooks/useOnOutsideClick'
import LoadingBackground from '../../components/LoadingBackground'
import Filter from '../Filter'

const SidebarModal = ({ isMobile, handleCategorySelectCallback, handleCollectionSelectCallback}) => {
  const [family, setFamily] = useState([])
  const [subcategory, setSubcategory] = useState([])
  const [collection, setCollection] = useState([])
  const [collectionVariations, setCollectionVariations] = useState([])
  const [familySelected, setFamilySelected] = useState([])
  const [collectionSelected, setCollectionSelected] = useState([])
  const [menu, setMenu] = useState([])
  const [isCategoryOpen, setIsCategoryOpen] = useState(false)
  const { innerBorderRef } = useOnOutsideClick(() => setIsCategoryOpen(false))
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))
  const portalMenuType = customer?.environmentConfiguration?.portalMenuType
  const [isLoading, setIsLoading] = useState(false)

  const getFamily = async () => {
    const response = await api.get('/api/v2/Category?OnlyB2B=true&Size=500')
    setFamily(response.data.response.data)
  }

  const getSubcategory = async () => {
    const response = await api.get('/api/v2/SubCategory?OnlyB2B=true&Size=500')
    setSubcategory(response.data.response.data)
  }

  const getCollection = async () => {
    setIsLoading(true)
    const response = await api.get('/api/v2/collection?size=150&OnlyB2B=true')
    setCollection(response.data.response.data)
    setIsLoading(false)
  }

  const handleOpenCategory = () => {
    setIsCategoryOpen(!isCategoryOpen)
  }

  const handleCategorySelect = async (idCategory, idSubCategory) => {
    if(!idSubCategory > 0)
    {
      idSubCategory = 0;
    }

    if(idCategory > 0) {
      setFamilySelected(idCategory);
      await handleCategorySelectCallback(idCategory, idSubCategory);
    } else {
      setFamilySelected(null);
    }
    //const response = await api.get(`/api/v2/Product?CategoryId=${idCategory}${subCategoryGet}&OnlyB2B=true&Size=20&Page=1`)
  }

  const handleCollectionSelect = async (idCollection, idVariation) => {
    if(!idVariation > 0)
    {
      idVariation = 0;
    }
    
    if(idCollection > 0) {
      setCollectionSelected(idCollection);
      const response = await api.get(`/api/v2/collection/${idCollection}/Variations`);
      setCollectionVariations(response.data.response.data);
      await handleCollectionSelectCallback(idCollection, idVariation);
    } else {
      setCollectionSelected(null);
    }
  }

  function newCategoryArray () {
    const newCategory = family.map(item => {
      const tmpSubcategory = []
      subcategory.map(sub => {
        if (sub.categoryId === item.id) {
          tmpSubcategory.push(sub)
        }
      })
      item.subcategory = tmpSubcategory
      return item
    })
    setMenu(newCategory)
  }

  useEffect(() => {
    getFamily()
    getSubcategory()
    getCollection()
  }, [])

  useEffect(() => {
    newCategoryArray()
  }, [subcategory, family])

  const verifyMobileMenuLabel = () => {
    let labelMobileMenu = '';
    if (portalMenuType !== 2) {
      isCategoryOpen ? labelMobileMenu = 'Fechar Categorias' : labelMobileMenu = 'Categorias de Produtos'
    } else {
      isCategoryOpen ? labelMobileMenu = 'Fechar Coleções' : labelMobileMenu = 'Coleções de Produtos'
    }

    return labelMobileMenu
  }

  return (
    <>
     {isLoading && (
        <LoadingBackground />
      )}

      {isMobile &&
        <>
          <Filter category={family} isSidebarModal={true} isMobile={true} />
          <S.SidebarMobile>
            <S.SidebarMobileBtn isCategoryOpen={isCategoryOpen} onClick={handleOpenCategory} ref={innerBorderRef}>
              <S.SidebarMobileBtnIcon type={isCategoryOpen ? 'x' : 'bulletList'} />
              {verifyMobileMenuLabel()}
            </S.SidebarMobileBtn>
            <S.SidebarMobileCntDrop isCategoryOpen={isCategoryOpen}>
              {(portalMenuType === 2 || portalMenuType === 3) && collection.length > 0 ?
                <>
                  <S.SidebarMobileTitle>Coleções</S.SidebarMobileTitle>
                  {collection.map(item => (
                    <S.SidebarMobileMenuItem key={item.id}>
                      <S.SidebarMenuItemLink onClick={() => handleCollectionSelect(collectionSelected !== item.id ? item.id : null)}>
                        <S.SidebarMenuItemLinkIcon type='tag' />
                        <S.SidebarMobileMenuItemLinkLabel>
                          {item.description}
                        </S.SidebarMobileMenuItemLinkLabel>
                        <S.SidebarMenuItemLinkQty>
                          {item.productsCount}
                        </S.SidebarMenuItemLinkQty>
                      </S.SidebarMenuItemLink>

                      {collectionSelected === item.id &&
                        <S.SidebarMenu>
                          {collectionVariations.map((variation) => {
                            return (
                              <S.SidebarMobileSubcategoryItem key={variation.id}>
                                <S.SidebarSubcategoryItemLink onClick={() => handleCollectionSelect(collectionSelected, variation.id )}>
                                  <S.SidebarMobileSubcategoryItemLinkLabel>
                                    {variation.description}
                                  </S.SidebarMobileSubcategoryItemLinkLabel>
                                </S.SidebarSubcategoryItemLink>
                              </S.SidebarMobileSubcategoryItem>
                            )})
                          }
                        </S.SidebarMenu>
                      }
                    </S.SidebarMobileMenuItem>
                  ))}
                </>
              : null}
              {portalMenuType !== 2 ?
                <>
                  <S.SidebarMobileTitle>Categorias</S.SidebarMobileTitle>
                  {menu.map(item => (
                    <S.SidebarMobileMenuItem key={item.id} style={{ cursor: 'pointer' }}>
                      <S.SidebarMenuItemLink onClick={() => handleCategorySelect(familySelected !== item.id ? item.id : null)}>
                        <S.SidebarMenuItemLinkIcon type='tag' />
                        <S.SidebarMobileMenuItemLinkLabel>
                          {item.description}
                        </S.SidebarMobileMenuItemLinkLabel>
                        <S.SidebarMenuItemLinkQty>
                          {item.productsCount}
                        </S.SidebarMenuItemLinkQty>
                      </S.SidebarMenuItemLink>

                      {familySelected === item.id &&
                        <S.SidebarMenu>
                          {Array.isArray(item.subcategory) && item.subcategory.map((subCat) => {
                            return (
                              <S.SidebarMobileSubcategoryItem key={subCat.id}>
                                <S.SidebarSubcategoryItemLink onClick={() => handleCategorySelect(familySelected, subCat.id )}>
                                  <S.SidebarMobileSubcategoryItemLinkLabel>
                                    {subCat.description}
                                  </S.SidebarMobileSubcategoryItemLinkLabel>
                                  <S.SidebarSubcategoryItemLinkQty>
                                    {subCat.productsCount}
                                  </S.SidebarSubcategoryItemLinkQty>
                                </S.SidebarSubcategoryItemLink>
                              </S.SidebarMobileSubcategoryItem>
                            )
                          })}
                        </S.SidebarMenu>
                      }
                    </S.SidebarMobileMenuItem>
                  ))}
                </>
              : null}
            </S.SidebarMobileCntDrop>
          </S.SidebarMobile>
        </>
      }
      {!isMobile &&
        <S.Sidebar>
          <>
            <Filter category={family} isSidebarModal={true} />
            {(portalMenuType === 2 || portalMenuType === 3) && collection.length > 0 ?
              <>
                <S.SidebarTitle>Coleções</S.SidebarTitle>
                <S.SidebarNav>
                  <S.SidebarMenu>
                    {collection.map(item => (
                      <S.SidebarMenuItem key={item.id} style={{ cursor: 'pointer' }}>
                        <S.SidebarMenuItemLink onClick={() => handleCollectionSelect(collectionSelected !== item.id ? item.id : null)}>
                          <S.SidebarMenuItemLinkIcon type='tag' />
                          <S.SidebarMenuItemLinkLabel>
                            {item.description}
                          </S.SidebarMenuItemLinkLabel>
                          <S.SidebarMenuItemLinkQty>
                            {item.productsCount}
                          </S.SidebarMenuItemLinkQty>
                        </S.SidebarMenuItemLink>

                        {collectionSelected === item.id &&
                          <S.SidebarMenu>
                            {collectionVariations.map((variation) => {
                              return (
                                <S.SidebarSubcategoryItem key={variation.id}>
                                  <S.SidebarSubcategoryItemLink onClick={() => handleCollectionSelect(collectionSelected, variation.id )}>
                                    <S.SidebarSubcategoryItemLinkLabel>
                                      {variation.description}
                                    </S.SidebarSubcategoryItemLinkLabel>
                                  </S.SidebarSubcategoryItemLink>
                                </S.SidebarSubcategoryItem>
                              )
                            })}
                          </S.SidebarMenu>
                        }
                      </S.SidebarMenuItem>
                    ))}
                  </S.SidebarMenu>
                </S.SidebarNav>
              </>
            : null}
            {portalMenuType !== 2 ?
              <>
                <S.SidebarTitle>Categorias de produtos</S.SidebarTitle>
                <S.SidebarNav>
                  <S.SidebarMenu>
                    {menu.map(item => (
                      <S.SidebarMenuItem key={item.id} style={{ cursor: 'pointer' }}>
                        <S.SidebarMenuItemLink onClick={() => handleCategorySelect(familySelected !== item.id ? item.id : null)}>
                          <S.SidebarMenuItemLinkIcon type='tag' />
                          <S.SidebarMenuItemLinkLabel>
                            {item.description}
                          </S.SidebarMenuItemLinkLabel>
                          <S.SidebarMenuItemLinkQty>
                            {item.productsCount}
                          </S.SidebarMenuItemLinkQty>
                        </S.SidebarMenuItemLink>
                       
                        {familySelected === item.id &&
                          <S.SidebarMenu>
                            {Array.isArray(item.subcategory) && item.subcategory.map(subCat => (
                              <S.SidebarSubcategoryItem key={subCat.id}>
                                <S.SidebarSubcategoryItemLink onClick={() => handleCategorySelect(familySelected, subCat.id )}>
                                  <S.SidebarSubcategoryItemLinkLabel>
                                    {subCat.description}
                                  </S.SidebarSubcategoryItemLinkLabel>
                                  <S.SidebarSubcategoryItemLinkQty>
                                    {subCat.productsCount}
                                  </S.SidebarSubcategoryItemLinkQty>
                                </S.SidebarSubcategoryItemLink>
                              </S.SidebarSubcategoryItem>
                            ))}
                          </S.SidebarMenu>
                        }
                      </S.SidebarMenuItem>
                    ))}
                  </S.SidebarMenu>
                </S.SidebarNav>
              </>
            : null}  
            {/* <S.Banner /> */}
          </>
        </S.Sidebar>}
    </>
  )
}

export default SidebarModal
