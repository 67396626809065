import styled from 'styled-components/macro'
import { Button } from '../../commons/Button'
import Icon from '../../commons/Icon'
import Container from '../../components/Container'

export const InviteIcon = styled(Icon)``

export const Invite = styled.div`
  background-image: url('/assets/images/login-bg-3.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 20rem;
  background-color: ${({ theme }) => theme.colors.orange.default};
  height: 100vh;

  @media ${({ theme }) => theme.screens.md} {
    background-image: unset;
    min-height: 100vh;
  }
`

export const InviteContainer = styled(Container)`
  height: 100%;
`

export const InviteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin-right: 18%;

  @media ${({ theme }) => theme.screens['2xl']} {
    margin-right: 5%;
  }

  @media ${({ theme }) => theme.screens.xl} {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.screens.md} {
    padding-top: 3rem;
    align-items: unset;
    justify-content: unset;
  }
`

export const InviteInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 52rem;
  width: 100%;

  @media ${({ theme }) => theme.screens.md} {
    max-width: unset;
  }
`

export const InviteLogo = styled.img`
  max-width: 100%;
  max-height: 14rem;
`

export const InviteLogoWrapper = styled.div`
  display: flex;
  width: 24rem;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.screens.md} {
    width: 16rem;
  }
`

export const InviteTitle = styled.h1`
  max-width: 36.7rem;
  width: 100%;
  margin-top: 3rem;
  font-size: 3.2rem;
  line-height: 3.8rem;
  text-align: center;
  letter-spacing: -0.1rem;
  color: ${({ theme }) => theme.colors.mono.white};

  @media ${({ theme }) => theme.screens.lg} {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
`

export const InviteText = styled.p`
  margin-top: 1.5rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.orange.light10};
`

export const InvitePasswordInfoText = styled.p`
  margin-top: 1.5rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const InviteBox = styled.div`
  width: 100%;
  margin-top: 3rem;
  padding: 6rem;
  background: ${({ theme }) => theme.colors.mono.white};
  border-radius: .8rem;

  @media ${({ theme }) => theme.screens.lg} {
    padding: 3rem;
  }
`

export const InviteBoxTitle = styled.h3`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const InviteBoxText = styled.p`
  margin-bottom: 3rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const HomeField = styled.div`
  position: relative;
  margin-bottom: 3rem;
`

export const HomeFieldLabel = styled.span`
  position: absolute;
  left: 0;
  bottom: 2rem;
  transition: all .2s ease;
  display: block;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
  pointer-events: none;
`

export const HomeFieldFixLabel = styled.span`
  display: block;
  margin-bottom: .9rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const HomeFieldInput = styled.input`
  width: 100%;
  padding: 2.9rem 0 1.4rem;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mono.greyDark};

  &:not(:placeholder-shown) + ${HomeFieldLabel},
  &:focus + ${HomeFieldLabel} {
    color: ${({ theme }) => theme.colors.orange.default};
    bottom: 4.6rem;
  }

  &:focus {
    outline: 0;
  }
`

export const HomeFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const HomeFieldButton = styled.button`
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.orange.default};

  &:hover {
    color: ${({ theme }) => theme.colors.mono.black};
  }
`

export const InviteButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 0;
  height: 5rem;
`
