import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTitle } from '../../hooks/useTitle'
import { toast } from 'react-toastify'
import api from '../../services/api'
import * as S from './styles'

const ChangePassword = () => {
  const { hash } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isPassword2Visible, setIsPassword2Visible] = useState(false)
  const [isPassword3Visible, setIsPassword3Visible] = useState(false)

  const [passwordChanged, setPasswordChanged] = useState(false)

  const navigate = useNavigate()
  /* eslint-disable no-unused-vars */
  const { register, handleSubmit, formState: { errors } } = useForm()

  useEffect(() => {
    if (passwordChanged) {
      setTimeout(() => {
        navigate('/login')
      }, 2000)
    }
  }, [passwordChanged, navigate])

  const handleChangePassword = async data => {
    const { password, confirmpassword } = data

    setIsLoading(true)

    const id = toast.loading('Alterando sua senha de acesso...')

    const json = {
      Password: password,
      NewPassword: confirmpassword,
    }

    const response = await api.post(`/Authorize/ChangePassword/Buyer/${hash}`, json)
    if (response.data.success) {
      toast.update(id, {
        render: response.data.successMessages[0],
        type: 'success',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
    } else {
      toast.update(id, {
        render: response.data.errorMessages[0],
        type: 'error',
        isLoading: false,
        delay: 750,
        autoClose: 5000,
      })
    }
    setPasswordChanged(true)
    setIsLoading(false)
  }

  const handleChangePasswordError = (errors, e) => {
    Object.entries(errors).forEach(entry => {
      toast.warning(entry[1].message)
    })
  }

  const handlePassword2Visibility = () => {
    setIsPassword2Visible(visible => !visible)
  }
  const handlePassword3Visibility = () => {
    setIsPassword3Visible(visible => !visible)
  }

  useTitle('Bendito - Acelere suas vendas!')

  return (
    <S.ChangePassword>
      <S.ChangePasswordContainer>
        <S.ChangePasswordWrapper>
          <S.ChangePasswordInner>
            <S.ChangePasswordLogo src='/assets/images/logo-bendito-white.svg' />
            <S.ChangePasswordTitle>Precisa alterar sua senha?</S.ChangePasswordTitle>
            <S.ChangePasswordText>Informe a nova senha para alteração</S.ChangePasswordText>
            <S.ChangePasswordBox>
              <form onSubmit={handleSubmit(handleChangePassword, handleChangePasswordError)}>
                <S.HomeField>
                  <S.HomeFieldInput
                    type={isPassword2Visible ? 'text' : 'password'}
                    placeholder='&nbsp;'
                    {...register('password', { required: 'Senha é obrigatória' })}
                  />
                  <S.HomeFieldLabel>
                    Informe sua nova senha:
                  </S.HomeFieldLabel>
                  <S.ChangePasswordFieldInputPasswordButton
                    isActive={isPassword2Visible}
                    type='button'
                    onClick={handlePassword2Visibility}
                  >
                    <S.ChangePasswordIcon type={isPassword2Visible ? 'eyeOff' : 'eye'} />
                  </S.ChangePasswordFieldInputPasswordButton>
                </S.HomeField>
                <S.HomeField>
                  <S.HomeFieldInput
                    type={isPassword3Visible ? 'text' : 'password'}
                    placeholder='&nbsp;'
                    {...register('confirmpassword', { required: 'Confirmação é obrigatória' })}
                  />
                  <S.HomeFieldLabel>
                    Confirme sua nova senha:
                  </S.HomeFieldLabel>
                  <S.ChangePasswordFieldInputPasswordButton
                    isActive={isPassword3Visible}
                    type='button'
                    onClick={handlePassword3Visibility}
                  >
                    <S.ChangePasswordIcon type={isPassword3Visible ? 'eyeOff' : 'eye'} />
                  </S.ChangePasswordFieldInputPasswordButton>
                </S.HomeField>
                <S.ChangePasswordButton type='submit' disabled={isLoading}>
                  Alterar
                </S.ChangePasswordButton>
              </form>
            </S.ChangePasswordBox>

          </S.ChangePasswordInner>
        </S.ChangePasswordWrapper>
      </S.ChangePasswordContainer>
    </S.ChangePassword>
  )
}

export default ChangePassword
