import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Wrapper } from './styles'
import Sidebar from '../../components/Sidebar'
import Footer from '../../components/Footer'
import Container from '../../components/Container'
import MainContent from '../../components/MainContent'
import Header from '../../components/Header'
import HorizontalMenu from '../../components/HorizontalMenu'

const Dashboard = () => {
  const location = useLocation()
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'));
  const horizontalMenu = customer?.environmentConfiguration?.horizontalMenuTranspose;
  const sidebarType = horizontalMenu ? 'horizontal' : 'vertical';

  useEffect(() => {
    document.documentElement.scrollTop = 0
  }, [location])

  return (
    <>
      <Header />
      { horizontalMenu ? <HorizontalMenu /> : null }
      <Container>
        <Wrapper sidebarType={sidebarType}>
          { !horizontalMenu ? <Sidebar /> : null }
          <MainContent sidebarType={sidebarType}>
            <Outlet />
          </MainContent>
        </Wrapper>
      </Container>
      <Footer />
    </>
  )
}

export default Dashboard
