import * as S from './styles'

import React from 'react'

const Tag = ({ children, className }) => {
  return (
    <S.Tag className={className}>{children}</S.Tag>
  )
}

export default Tag
