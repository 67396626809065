import React, { createContext, useState, useContext, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import { changeThemeColor } from '../util/changeThemeColor';

const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  const [updatedTheme, setUpdatedTheme] = useState(theme);

  const updateTheme = useCallback((receivedTheme) => {
    const tmpUpdatedTheme = changeThemeColor(receivedTheme, theme)
    setUpdatedTheme(tmpUpdatedTheme);
  }, [])

  return (
    <ThemeContext.Provider 
      value={{ 
        updatedTheme, 
        updateTheme 
      }}>
      <ThemeProvider theme={updatedTheme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export function useTheme () {
  const context = useContext(ThemeContext)
  return context
}

export default ThemeContext