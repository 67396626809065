import styled from 'styled-components/macro'

export const Icon = styled.span`
  flex-shrink: 0;
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;

  svg {
    width: 100%;
    height: 100%;
  }
`
