import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import Icon from '../../../commons/Icon'

export const PersonalMenu = styled.div`
  flex-shrink: 0;
  position: relative;
  z-index: 4000;
  display: flex;
  align-items: center;
  max-width: 21.8rem;
  width: 100%;
  @media ${({ theme }) => theme.screens.lg} {
    display: none;
  }
`
export const PersonalMenuMobile = styled.div`
  display: none;
  @media ${({ theme }) => theme.screens.lg} {
    display: flex;
    margin-top: 2.5rem;
  }
`
export const PersonalMenuMobileLeft = styled.div``
export const PersonalMenuMobileRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
`
export const PersonalMenuInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.821rem;
  text-align: right;
`

export const PersonalMenuName = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: right;
  letter-spacing: -0.2px;
  color: ${({ theme, $changeHeaderColor, $isMobile }) => $changeHeaderColor && !$isMobile? theme.colors.mono.greyLight : theme.colors.mono.black};

  @media ${({ theme }) => theme.screens.lg} {
    text-align: left;
  }
`

export const PersonalMenuLink = styled(Link)`
  margin-top: 0.3rem;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme, $changeHeaderColor, $isMobile }) => $changeHeaderColor && !$isMobile ? theme.colors.mono.white : theme.colors.orange.default};

  &:hover {
    color: ${({ theme, $changeHeaderColor, $isMobile }) => $changeHeaderColor && !$isMobile ? theme.colors.mono.grey : theme.colors.mono.black};
  }
`

export const PersonalMenuIcon = styled(Icon)`
  transform: rotate(180deg);
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default};
  transition: transform 0.3s, color 0.3s;
`

export const PersonalMenuButton = styled.button`
  ${({ isMenuActive }) => css`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 6.179rem;
    width: 100%;

    &:hover {
      ${PersonalMenuIcon} {
        color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.grey : theme.colors.mono.black};
      }
    }

    ${isMenuActive &&
    css`
      ${PersonalMenuIcon} {
        transform: rotate(0);
        color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.grey : theme.colors.mono.black};
      }
    `}
  `}
`

export const PersonalMenuAvatar = styled.figure`
  flex-shrink: 0;
  width: 3.857rem;
  height: 3.857rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.mono.grey};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const PersonalMenuSubmenuItem = styled.li`
  position: relative;
  padding: 1.5rem 0;
`

export const PersonalMenuSubmenu = styled.ul`
  ${({ theme, isMenuActive }) => css`
    visibility: hidden;
    position: absolute;
    top: 6.5rem;
    right: 0;
    transform: translateY(2rem);
    max-width: 17.3rem;
    width: 100%;
    padding: 0.5rem 2rem;
    background-color: ${theme.colors.mono.white};
    border-radius: 0.5rem;
    box-shadow: ${theme.shadows.dark};
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

    ${isMenuActive &&
    css`
      visibility: visible;
      transform: translateY(0);
      opacity: 1;
    `}

    &::before {
      content: "";
      position: absolute;
      right: 4rem;
      top: 0;
      transform: translateY(-100%);
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid ${theme.colors.mono.white};
    }

    ${PersonalMenuSubmenuItem} {
      &:not(:last-of-type) {
        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: calc(100% - 2.8rem);
          height: 0.15rem;
          background-color: ${theme.colors.mono.grey};
        }
      }
    }
  `}
`

export const PersonalMenuSubmenuLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.black};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`
export const PersonalMenuSubmenuButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.black};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const PersonalMenuSubmenuIcon = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.orange.default};
`
