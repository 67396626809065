import { useState } from 'react'
import * as S from './styles'

const Input = ({ label, name, type, placeholder, register, required, styleType = 1, disabled, style, onChange }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(visible => !visible)
  }

  return (
    <S.InputField styleType={styleType}>
      <S.InputLabel>
        {label && (
          <S.InputText>{label} {required && (<S.RequiredBadge>*</S.RequiredBadge>)}</S.InputText>
        )}
        <S.InputWrapper>
          <S.Input
            type={isPasswordVisible ? 'text' : type}
            name={name}
            placeholder={placeholder}
            {...register(name, { required })}
            disabled={disabled}
            style={style}
            onChange={onChange}
          />
          {type === 'password' && (
            <S.InputPasswordButton isActive={isPasswordVisible} type='button' onClick={handlePasswordVisibility}>
              <S.InputIcon type={isPasswordVisible ? 'eyeOff' : 'eye'} />
            </S.InputPasswordButton>
          )}
        </S.InputWrapper>
      </S.InputLabel>
    </S.InputField>
  )
}

export default Input
