import * as S from './styles'
import React from 'react'
// import useWindowDimensions from './../../hooks/useWindowDimensions'
import { IMAGE_URL } from '../../constants'
import { makeBannerLink } from '../../util/makeBannerLink'

const ProductBanner = ({ className, banner, linkType, linkId }) => {
  // const { width } = useWindowDimensions()
  const url = makeBannerLink(linkType, linkId)
  return (
    <S.ProductBanner href={url} className={className}>
      <S.ProductBannerImage src={`${IMAGE_URL}${banner}`} alt='' />
    </S.ProductBanner>
  )
}

export default ProductBanner
