export const customStylesForSearchableSelect = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#f8f8f8',
    borderColor: state.isFocused ? '#c0c0c0' : '#c0c0c0',
    '&:hover': {
      borderColor: '#c0c0c0',
    },
    boxShadow: state.isFocused ? '0 0 0 1px #c0c0c0' : 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isFocused ? '#dcdcdc' : 'transparent',
    color: '#333',
    '&:hover': {
      backgroundColor: state.isFocused ? '#c0c0c0' : '#f8f8f8',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#f8f8f8',
    color: '#333',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#555',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#555',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  })
};