import styled, { css } from 'styled-components/macro'

export const Button = styled.button`${({ theme, fullwidth }) => css`
  display: inline-block;
  padding: 1.5rem 2rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -0.03rem;
  text-align: center;
  color: ${theme.colors.mono.white};
  background: ${theme.colors.orange.default};
  border-radius: .5rem;

  &:hover{
    background: ${theme.colors.orange.dark};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 .4rem ${theme.colors.orange.light10};
  }

  &:active {
    background: ${({ theme }) => theme.colors.green.default};
    box-shadow: 0 0 0 .4rem ${theme.colors.green.light10};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.mono.greyDarker};
    background: ${({ theme }) => theme.colors.mono.grey};
    cursor: not-allowed;
  }

  ${fullwidth && css`
    width: 100%;
  `}
`}`

export const ButtonOutline = styled(Button)`${({ theme }) => css`
  padding: 2rem;
  font-size: 1.4rem;
  line-height: 1.5rem;
  letter-spacing: -0.02rem;
  color: ${theme.colors.orange.default};
  background: transparent;
  border: 0.12rem solid ${theme.colors.orange.default};

  @media ${({ theme }) => theme.screens.lg} {
    padding: 1.5rem;
    width: 100%;
  }

  &:hover {
    color: ${theme.colors.mono.white};
    background: ${theme.colors.orange.default};
  }

  &:active {
    background: ${({ theme }) => theme.colors.green.default};
    border-color: ${({ theme }) => theme.colors.green.default};
    box-shadow: 0 0 0 .4rem ${theme.colors.green.light10};
  }
`}`
