import { useState, useEffect } from 'react'
import api from '../../services/api'
import * as S from './styles'
import { useOnOutsideClick } from '../../hooks/useOnOutsideClick'
import { useSearch } from '../../context/SearchContext'
import LoadingBackground from '../../components/LoadingBackground'
import Filter from '../Filter'

const Sidebar = ({ isMobile }) => {
  const [family, setFamily] = useState([])
  const [subcategory, setSubcategory] = useState([])
  const [collection, setCollection] = useState([])
  const [collectionVariations, setCollectionVariations] = useState([])
  const [familySelected, setFamilySelected] = useState([])
  const [collectionSelected, setCollectionSelected] = useState([])
  const [menu, setMenu] = useState([])
  const [isCategoryOpen, setIsCategoryOpen] = useState(false)
  const { innerBorderRef } = useOnOutsideClick(() => setIsCategoryOpen(false))
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))
  const portalMenuType = customer ? customer?.environmentConfiguration?.portalMenuType : customerInfo?.portalMenuType
  const { setCurrentPage } = useSearch()
  const [isLoading, setIsLoading] = useState(false)

  const getFamily = async () => {
    setIsLoading(true)
    const response = await api.get('/api/v2/Category?OnlyB2B=true&Size=500')
    setFamily(response.data.response.data)
    setIsLoading(false)
  }

  const getSubcategory = async () => {
    setIsLoading(true)
    const response = await api.get('/api/v2/SubCategory?OnlyB2B=true&Size=500')
    setSubcategory(response.data.response.data)
    setIsLoading(false)
  }

  const getCollection = async () => {
    setIsLoading(true)
    const response = await api.get('/api/v2/collection?size=150&OnlyB2B=true')
    setCollection(response.data.response.data)
    setIsLoading(false)
  }

  const getCollectionVariations = async (item) => {
    setIsLoading(true)
    setCollectionSelected(collectionSelected !== item.id ? item.id : null)
    const response = await api.get(`/api/v2/collection/${item?.id}/Variations`)
    setCollectionVariations(response.data.response.data)
    setIsLoading(false)
  }

  const handleOpenCategory = () => {
    setIsCategoryOpen(!isCategoryOpen)
  }

  const handleResetPage = () => {
    setCurrentPage(1)
  }

  function newCategoryArray () {
    const newCategory = family.map(item => {
      const tmpSubcategory = []
      subcategory.map(sub => {
        if (sub.categoryId === item.id) {
          tmpSubcategory.push(sub)
        }
      })
      item.subcategory = tmpSubcategory
      return item
    })
    setMenu(newCategory)
  }

  useEffect(() => {
    getFamily()
    getSubcategory()
    getCollection()
  }, [])

  useEffect(() => {
    newCategoryArray()
  }, [subcategory, family])

  const verifyMobileMenuLabel = () => {
    let labelMobileMenu = ''
    if (portalMenuType !== 2) {
      isCategoryOpen ? labelMobileMenu = 'Fechar Categorias' : labelMobileMenu = 'Categorias de Produtos'
    } else {
      isCategoryOpen ? labelMobileMenu = 'Fechar Coleções' : labelMobileMenu = 'Coleções de Produtos'
    }

    return labelMobileMenu
  }

  return (
    <>
      {isLoading && (
        <LoadingBackground />
      )}

      {isMobile &&
        <>
          <Filter category={family} subcategory={subcategory} isSidebarModal={false} isMobile />
          <S.SidebarMobile>
            <S.SidebarMobileBtn isCategoryOpen={isCategoryOpen} onClick={handleOpenCategory} ref={innerBorderRef}>
              <S.SidebarMobileBtnIcon type={isCategoryOpen ? 'x' : 'bulletList'} />
              {verifyMobileMenuLabel()}
            </S.SidebarMobileBtn>
            <S.SidebarMobileCntDrop isCategoryOpen={isCategoryOpen}>
              {(portalMenuType === 2 || portalMenuType === 3) && collection.length > 0
                ? <>
                  <S.SidebarMobileTitle>Coleções</S.SidebarMobileTitle>
                  {collection.map(item => (
                    <S.SidebarMobileMenuItem key={item.id}>
                      <S.SidebarMobileMenuItemLink to='#' state={{ collectionId: item.id }} onClick={() => { getCollectionVariations(item), setIsCategoryOpen(true), handleResetPage() }}>
                        <S.SidebarMenuItemLinkIcon type='tag' />
                        <S.SidebarMobileMenuItemLinkLabel>
                          {item.description}
                        </S.SidebarMobileMenuItemLinkLabel>
                        <S.SidebarMenuItemLinkQty>
                          {item.productsCount}
                        </S.SidebarMenuItemLinkQty>
                      </S.SidebarMobileMenuItemLink>
                      {collectionSelected === item.id &&
                        <S.SidebarMenu>
                          <S.SidebarMobileSubcategoryItem key={0}>
                            <S.SidebarMobileSubcategoryItemLink to={`/collection/${item.id}`} state={{ collectionId: item.id }} onClick={() => { getCollectionVariations(item), handleResetPage() }}>
                              <S.SidebarMobileSubcategoryItemLinkLabel>
                                Ver todos os produtos
                              </S.SidebarMobileSubcategoryItemLinkLabel>
                            </S.SidebarMobileSubcategoryItemLink>
                          </S.SidebarMobileSubcategoryItem>
                          {collectionVariations.map((variation) => {
                            return (
                              <S.SidebarMobileSubcategoryItem key={variation.id}>
                                <S.SidebarMobileSubcategoryItemLink to={`/collection/${item.id}/variation/${variation.id}`} state={{ variationId: variation.id }} onClick={() => handleResetPage()}>
                                  <S.SidebarMobileSubcategoryItemLinkLabel>
                                    {variation.description}
                                  </S.SidebarMobileSubcategoryItemLinkLabel>
                                </S.SidebarMobileSubcategoryItemLink>
                              </S.SidebarMobileSubcategoryItem>
                            )
                          })}
                        </S.SidebarMenu>}
                    </S.SidebarMobileMenuItem>
                  ))}
                  </>
                : null}
              {portalMenuType !== 2
                ? <>
                  <S.SidebarMobileTitle>Categorias</S.SidebarMobileTitle>
                  {menu.map(item => (
                    <S.SidebarMobileMenuItem key={item.id}>
                      <S.SidebarMobileMenuItemLink to='#' state={{ familyId: item.id }} onClick={() => { setFamilySelected(familySelected !== item.id ? item.id : null), setIsCategoryOpen(true), handleResetPage() }}>
                        <S.SidebarMenuItemLinkIcon type='tag' />
                        <S.SidebarMobileMenuItemLinkLabel>
                          {item.description}
                        </S.SidebarMobileMenuItemLinkLabel>
                        <S.SidebarMenuItemLinkQty>
                          {item.productsCount}
                        </S.SidebarMenuItemLinkQty>
                      </S.SidebarMobileMenuItemLink>
                      {familySelected === item.id &&
                        <S.SidebarMenu>
                          <S.SidebarMobileSubcategoryItem key={0}>
                            <S.SidebarMobileSubcategoryItemLink to={`/category/${item.id}/subcategory/0`} state={{ familyId: item.id }} onClick={() => handleResetPage()}>
                              <S.SidebarMobileSubcategoryItemLinkLabel>
                                Ver todos os produtos
                              </S.SidebarMobileSubcategoryItemLinkLabel>
                            </S.SidebarMobileSubcategoryItemLink>
                          </S.SidebarMobileSubcategoryItem>
                          {item.subcategory.map((subCat) => {
                            return (
                              <S.SidebarMobileSubcategoryItem key={subCat.id}>
                                <S.SidebarMobileSubcategoryItemLink to={`/category/${item.id}/subcategory/${subCat.id}`} state={{ subcategoryId: subCat.id }} onClick={() => handleResetPage()}>
                                  <S.SidebarMobileSubcategoryItemLinkLabel>
                                    {subCat.description}
                                  </S.SidebarMobileSubcategoryItemLinkLabel>
                                  <S.SidebarSubcategoryItemLinkQty>
                                    {subCat.productsCount}
                                  </S.SidebarSubcategoryItemLinkQty>
                                </S.SidebarMobileSubcategoryItemLink>
                              </S.SidebarMobileSubcategoryItem>
                            )
                          })}
                        </S.SidebarMenu>}
                    </S.SidebarMobileMenuItem>
                  ))}
                  </>
                : null}
            </S.SidebarMobileCntDrop>
          </S.SidebarMobile>
        </>}
      {!isMobile &&
        <S.Sidebar>
          <>
            <Filter category={family} subcategory={subcategory} isSidebarModal={false} isMobile={false} />
            {(portalMenuType === 2 || portalMenuType === 3) && collection.length > 0
              ? <>
                <S.SidebarTitle>Coleções</S.SidebarTitle>
                <S.SidebarNav>
                  <S.SidebarMenu>
                    {collection.map(item => (
                      <S.SidebarMenuItem key={item.id}>
                        <S.SidebarMenuItemLink to={`/collection/${item.id}`} state={{ collectionId: item.id }} onClick={() => { getCollectionVariations(item), handleResetPage() }}>
                          <S.SidebarMenuItemLinkIcon type='tag' />
                          <S.SidebarMenuItemLinkLabel>
                            {item.description}
                          </S.SidebarMenuItemLinkLabel>
                          <S.SidebarMenuItemLinkQty>
                            {item.productsCount}
                          </S.SidebarMenuItemLinkQty>
                        </S.SidebarMenuItemLink>
                        {collectionSelected === item.id &&
                          <S.SidebarMenu>
                            {collectionVariations.map((variation) => {
                              return (
                                <S.SidebarSubcategoryItem key={variation.id}>
                                  <S.SidebarSubcategoryItemLink to={`/collection/${item.id}/variation/${variation.id}`} state={{ variationId: variation.id }} onClick={() => handleResetPage()}>
                                    <S.SidebarSubcategoryItemLinkLabel>
                                      {variation.description}
                                    </S.SidebarSubcategoryItemLinkLabel>
                                  </S.SidebarSubcategoryItemLink>
                                </S.SidebarSubcategoryItem>
                              )
                            })}
                          </S.SidebarMenu>}
                      </S.SidebarMenuItem>
                    ))}
                  </S.SidebarMenu>
                </S.SidebarNav>
                </>
              : null}
            {portalMenuType !== 2
              ? <>
                <S.SidebarTitle>Categorias de produtos</S.SidebarTitle>
                <S.SidebarNav>
                  <S.SidebarMenu>
                    {menu.map(item => (
                      <S.SidebarMenuItem key={item.id}>
                        <S.SidebarMenuItemLink to={`/category/${item.id}/subcategory/0`} state={{ familyId: item.id }} onClick={() => { setFamilySelected(familySelected !== item.id ? item.id : null), handleResetPage() }}>
                          <S.SidebarMenuItemLinkIcon type='tag' />
                          <S.SidebarMenuItemLinkLabel>
                            {item.description}
                          </S.SidebarMenuItemLinkLabel>
                          <S.SidebarMenuItemLinkQty>
                            {item.productsCount}
                          </S.SidebarMenuItemLinkQty>
                        </S.SidebarMenuItemLink>
                        {familySelected === item.id &&
                          <S.SidebarMenu>
                            {item.subcategory.map((subCat) => {
                              return (
                                <S.SidebarSubcategoryItem key={subCat.id}>
                                  <S.SidebarSubcategoryItemLink to={`/category/${item.id}/subcategory/${subCat.id}`} state={{ subcategoryId: subCat.id }} onClick={() => handleResetPage()}>
                                    <S.SidebarSubcategoryItemLinkLabel>
                                      {subCat.description}
                                    </S.SidebarSubcategoryItemLinkLabel>
                                    <S.SidebarSubcategoryItemLinkQty>
                                      {subCat.productsCount}
                                    </S.SidebarSubcategoryItemLinkQty>
                                  </S.SidebarSubcategoryItemLink>
                                </S.SidebarSubcategoryItem>
                              )
                            })}
                          </S.SidebarMenu>}
                      </S.SidebarMenuItem>
                    ))}
                  </S.SidebarMenu>
                </S.SidebarNav>
                </>
              : null}
            {/* <S.Banner /> */}
          </>
        </S.Sidebar>}
    </>
  )
}

export default Sidebar
