import { createContext, useContext, useEffect, useState } from 'react'
import api, { setAuthToken } from '../services/api'
import LoadingBackground from '../components/LoadingBackground'
import { getSubdomain } from '../util/getSubdomain'
import { useTheme } from './ThemeContext'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const { updateTheme } = useTheme()

  const signIn = async (username, password) => {
    try {
      const response = await api.post('/Authorize/Logon/Buyer', { username, password })
      const resData = response.data
      setAuthToken(resData?.token)
      setUser(resData)
      localStorage.setItem('@DAuth:user', JSON.stringify(resData))

      // Versionamento antigo do e-commerce
      // const responseVersion = await api.get('/HealthCheck/BenditoFrontEcommerce')
      // localStorage.setItem('@DAuth:version', responseVersion.data.version)

      // Novo versionamento do e-commerce
      const currentVersion = '1.2.2'
      localStorage.setItem('@DAuth:version', currentVersion)

      setAuthenticated(true)
      setLoading(false)
      return resData
    } catch (err) {
      localStorage.clear()
      setAuthToken(undefined)
      setAuthenticated(false)
      return err.response.data
    }
  }

  const signInNoClient = async (subdomain) => {
    try {
      const response = await api.get(`api/v2/Customer?subDomain=${subdomain}`)
      const resData = response.data.response.data
      if (resData && resData.portalAnonimousAccess === true && authenticated === false) {
        const tmpUser = {
          companyID: resData.customerId
        }
        setUser(tmpUser)
      }
      localStorage.setItem('customerInfo', JSON.stringify(resData))
      return resData
    } catch (err) {
      // localStorage.clear()
      return null
    }
  }

  const verifyAcessWithoutLogin = () => {
    const customerInfo = JSON.parse(localStorage.getItem('customerInfo'));
    if (customerInfo && customerInfo.portalAnonimousAccess === true && authenticated === false) {
      return true
    } else {
      return false
    }
  }

  const getCustomer = async (companyId) => {
    const response = await api.get(`/Customer/${companyId}`)
    const data = {...response.data}
    delete data.users
    localStorage.setItem('DAuth:customer', JSON.stringify(data))
    return response.data
  }

  const selectCompany = async (companyID, customerBuyerId) => {
    try {
      const customerRes = await api.get(`/Authorize/Logon/Buyer/Customer/${companyID}?CustomerBuyerId=${customerBuyerId}`)
      setAuthToken(customerRes.data.token)
      const paymentMethodRes = await api.get('/api/v2/PaymentMethod?OnlyB2B=true')
      const deadlineRes = await api.get('/Deadline')
      const domainRes = await api.get('/Domain?DomainId=TIPO_PEDIDO_VENDA')

      const resData = {
        ...customerRes.data,
        companyID,
        paymentMethod: [...paymentMethodRes.data.response.data],
        deadline: [...deadlineRes.data],
        domain: [...domainRes.data],
        date: null,
      }
      setUser(resData)
      localStorage.setItem('@DAuth:user', JSON.stringify(resData))
      getCustomer(companyID)
      return resData
    } catch (err) {
      return err.response.data
    }
  }

  const signOut = async () => {
    localStorage.clear()
    setUser(undefined)
    setAuthToken(undefined)
    setAuthenticated(false)
 
    if (!window.location.href.indexOf('portal') > -1 || !window.location.href.indexOf('localhost:3000') > -1) {
      setLoading(true)
      await signInNoClient(getSubdomain()).then(res => {
        if (res && res?.portalAnonimousAccess === true && authenticated === false) {
          updateTheme(res?.ecommerceColors)
        }
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    async function loadStoredData () {
      const storedUser = JSON.parse(localStorage.getItem('@DAuth:user'))

      if (storedUser) {
        try {
          // const response = await api.get('/HealthCheck/BenditoFrontEcommerce')
          const currentVersion = '1.2.2'
          const storedVersion = localStorage.getItem('@DAuth:version')

          if (currentVersion === storedVersion) {
            setUser(storedUser)
            setAuthenticated(true)
            setAuthToken(storedUser.token)
          } else {
            localStorage.clear()
            setUser(undefined)
            setAuthToken(undefined)
            setAuthenticated(false)
          }
        } catch (err) {
          localStorage.clear()
          setUser(undefined)
          setAuthToken(undefined)
          setAuthenticated(false)
        }
      }
      setLoading(false)
    }
    loadStoredData()
  }, [])

  if (loading) {
    return (
      <LoadingBackground />
    )
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        loading,
        user,
        signIn,
        signOut,
        selectCompany,
        signInNoClient,
        verifyAcessWithoutLogin,
        getCustomer,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth () {
  const context = useContext(AuthContext)
  return context
}

export default AuthContext
