import styled from 'styled-components'
import { Button } from '../../commons/Button'
import { Link } from 'react-router-dom'
import Icon from '../../commons/Icon'

export const Cnt = styled.div`
  margin-bottom: 3.5rem;
`
export const h1Styled = styled.h1`
  margin-top: 6rem;
`
export const BtnPay = styled(Button)`
  margin-left: auto;
  display: flex;
  margin-top: 1rem;
  width: 25.2rem;
  height: 5.8rem;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.screens.sm} {
    margin: 5rem auto 0;
    width: 100%;
  }
`
export const BtnRoute = styled(Link)``
export const botInfoTotal = styled.div`
  margin-top: 3.5rem;
  min-height: 69px;
  background: ${({ theme }) => theme.colors.mono.greyLight};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1.5rem 5rem;
  max-width: fit-content;
  margin-left: auto;
  .mid {
    display: flex;
    align-items: center;
    gap: 2rem 4rem;
    margin-left: auto;
    flex-wrap: wrap;
    @media ${({ theme }) => theme.screens.sm} {
      margin: 0 auto;
      justify-content: center;
      margin-left: initial;
    }
    .values {
      .name {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.4rem;
        text-align: center;
        color: ${({ theme }) => theme.colors.mono.greyDarker};
      }
      .value {
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.7rem;
        text-align: center;
        color: ${({ theme }) => theme.colors.mono.black};
      }
      &.total {
        .name {
          font-weight: 700;
          font-size: 1.3rem;
          line-height: 1.7rem;
          color: ${({ theme }) => theme.colors.mono.black};
        }
        .value {
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.2rem;
          letter-spacing: -0.3px;
          color: ${({ theme }) => theme.colors.orange.default};
        }
      }
    }
  }
`
export const Container = styled.li`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.mono.greyLight};
  padding-bottom: 3.5rem;
  margin-top: 3.5rem;
  .box-img {
    max-width: 16rem;
    max-height: 16rem;
    min-height: 16rem;
    background: #ffffff;
    box-shadow: 0px 3px 15px #eaecf1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    @media ${({ theme }) => theme.screens.xs} {
      display: none;
    }
    img {
      max-height: 100%;
      min-height: 16rem;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  .info {
    flex: 1;
    .top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 3.2rem;
      .left {
        .cod {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.4rem;
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme.colors.mono.greyDarker};
          margin-bottom: 0.5rem;
        }

        .title {
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2.2rem;
          display: flex;
          align-items: center;
          letter-spacing: -0.2px;
          margin-bottom: 0.5rem;

          color: ${({ theme }) => theme.colors.orange.default};
        }

        .desc {
          ul {
            display: flex;
            li {
              margin-right: 3.5rem;
              margin-bottom: 0.5rem;
              font-weight: 400;
              font-size: 1.2rem;
              line-height: 1.4rem;
              display: flex;
              align-items: center;
              color: ${({ theme }) => theme.colors.mono.greyDarker};
              position: relative;
            }
          }
        }
      }
      .mobile-img-qtd {
        width: 100%;
        display: none;
        flex-wrap: wrap;
        @media ${({ theme }) => theme.screens.xs} {
          display: flex;
        }
        .box-img {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem;
          @media ${({ theme }) => theme.screens.xs} {
            display: block;
          }
          .img {
          }
        }
        .right {
          @media ${({ theme }) => theme.screens.xs} {
            display: flex;
            flex-direction: column;
          }
          .qtd {
            display: flex;
            align-items: center;
            button {
              width: 32px;
              height: 32px;
              font-size: 30px;
              background: ${({ theme }) => theme.colors.mono.grey};
              border-radius: 50%;
              color: ${({ theme }) => theme.colors.mono.greyDarker};
            }

            input {
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.2rem;
              text-align: center;
              letter-spacing: -0.2px;
              color: ${({ theme }) => theme.colors.mono.black};
              border: none;
              max-width: 60px;
              transition: all 0.3s ease;
              height: 32px;
              border-radius: 32px;
              &:hover {
                background: ${({ theme }) => theme.colors.mono.greyLight};
              }
            }
          }
          .total-value {
            display: flex;
            align-items: flex-end;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.2rem;
            letter-spacing: -0.3px;
            /* Orange/Orange */

            color: ${({ theme }) => theme.colors.orange.default};
          }
        }
      }
      .mid {
        display: flex;
        flex-wrap: wrap;
        gap: 4rem;
        margin-left: auto;
        @media ${({ theme }) => theme.screens.sm} {
          margin: 0 auto;
          justify-content: center;
        }
        .values {
          .name {
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.4rem;
            text-align: center;
            color: ${({ theme }) => theme.colors.mono.greyDarker};
            margin-bottom: 1.4rem;
          }
          .value {
            font-weight: 400;
            font-size: 1.3rem;
            line-height: 1.7rem;
            text-align: center;
            color: ${({ theme }) => theme.colors.mono.black};
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 4rem;
        @media ${({ theme }) => theme.screens.sm} {
          margin: 0 auto;
          justify-content: center;
        }
        @media ${({ theme }) => theme.screens.xs} {
          display: none;
        }
        .qtd {
          display: flex;
          align-items: center;
          button {
            width: 32px;
            height: 32px;
            font-size: 30px;
            background: ${({ theme }) => theme.colors.mono.grey};
            border-radius: 50%;
            color: ${({ theme }) => theme.colors.mono.greyDarker};
          }

          input {
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: center;
            letter-spacing: -0.2px;
            color: ${({ theme }) => theme.colors.mono.black};
            border: none;
            max-width: 60px;
            height: 32px;
            border-radius: 32px;
            transition: all 0.3s ease;
            &:hover {
              background: ${({ theme }) => theme.colors.mono.greyLight};
            }
          }
        }

        .total-value {
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.2rem;
          letter-spacing: -0.3px;
          /* Orange/Orange */

          color: ${({ theme }) => theme.colors.orange.default};
        }
      }
    }

    .bot {
      min-height: 44px;
      background: ${({ theme }) => theme.colors.mono.greyLight};
      border-radius: 5px;
      margin-top: 30px;
      padding: 12px;
      .select-box {
        display: flex;
        align-items: center;
        label {
          font-weight: 400;
          font-size: 1.3rem;
          line-height: 1.7rem;
          color: ${({ theme }) => theme.colors.mono.greyDarker};
          margin-left: 10px;
        }
        input {
          width: 2rem;
          height: 2rem;
        }
      }
      textarea {
        margin-top: 10px;
        min-height: 9.6rem;
        background: #ffffff;
        border: 2px solid ${({ theme }) => theme.colors.mono.greyDark};
        border-radius: 6px;
        padding: 12px;
        &:focus,
        &:focus-visible {
          border-color: ${({ theme }) => theme.colors.mono.greyDark};
          outline: none;
        }
      }
    }
  }
`

export const CartDeleteProductBtn = styled.button`
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};

  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }

  @media ${({ theme }) => theme.screens.xs} {
    margin-left: 1.5rem;
  }
`
export const CartIcon = styled(Icon)``
