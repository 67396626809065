import * as S from './styles'

const Select = ({ children, name, register, onChange, required, message, value }) => {
  return (
    <S.Select>
      {(register && required) &&
        <S.SelectInput
          name={name}
          {...register(name, { required: message })}
          onChange={onChange}
          value={value}
        >
          {children}
        </S.SelectInput>}
      {(register && !required) &&
        <S.SelectInput
          name={name}
          {...register(name)}
          onChange={onChange}
          value={value}
        >
          {children}
        </S.SelectInput>}
      {!register &&
        <S.SelectInput
          name={name}
          onChange={onChange}
          value={value}
        >
          {children}
        </S.SelectInput>}

      <S.SelectIcon type='upChevron' />
    </S.Select>
  )
}

export default Select
