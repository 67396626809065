import * as S from './styles'

const MessageBox = ({ className, content, title }) => {
  return (
    <S.MessageBox className={className}>
      <S.Title>{title}</S.Title>
      <S.Content>
        <p>{content}</p>
      </S.Content>
    </S.MessageBox>
  )
}

export default MessageBox
