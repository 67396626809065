import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import BannerSidebar from '../BannerSidebar'
import Icon from '../../commons/Icon'
import { Button } from '../../commons/Button'

export const Sidebar = styled.aside`
  position: relative;
  flex-shrink: 0;
  max-width: 28.75rem;
  width: 100%;
  margin-right: 3.4rem;
  padding: 4rem 2rem 17.7rem 0;
  background: #f4f5f6;
  border-radius: 0 4rem 0 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: 1000px;
    height: 100%;
    background: #f4f5f6;
  }
  @media ${({ theme }) => theme.screens.lg} {
    display: none;
  }
`
export const SidebarMobile = styled.aside`
  display: none;
  width: 100%;
  margin-top: 3rem;

  @media ${({ theme }) => theme.screens.lg} {
    display: block;
  }
`
export const SidebarMobileCntDrop = styled.div`
  position: absolute;
  z-index: 105;
  width: 100%;
  margin-top: 1.5rem;
  background-color: ${({ theme }) => theme.colors.orange.default};
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  max-height: 50vh;
  overflow-y: auto;
  ${({ isCategoryOpen }) =>
    isCategoryOpen &&
    css`
      opacity: 1;
      pointer-events: initial;
    `}
`

export const SidebarTitle = styled.span`
  display: table;
  margin: 2rem auto;
  padding: 1rem 2.85rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.1rem;
  text-align: center;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  /* Mono/Grey Darker */
  color: #8f99a3;
  background-color: #fff;
  border-radius: 100px;

  @media screen and (min-width: 1200px) {
   width: 100%;
  }
`

export const SidebarNav = styled.nav`
  margin-top: 2rem;
`

export const SidebarMenu = styled.ul``

export const SidebarMenuItem = styled.li`
  margin-bottom: 0.5rem;
`
export const SidebarMobileMenuItem = styled(SidebarMenuItem)`
  &:first-child {
    padding-top: 1rem;
  }
  ::marker {
    content: "";
  }
`

export const SidebarMenuItemLinkIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.mono.greyDark};
`
export const SidebarMobileBtn = styled(Button)`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border: 0.15rem solid transparent;

  ${({ isCategoryOpen }) => css`
    ${isCategoryOpen &&
    css`
      border: 0.15rem solid ${({ theme }) => theme.colors.orange.default};
      border-radius: 5px;
      background: white;
      color: ${({ theme }) => theme.colors.orange.default};
      svg {
        fill: ${({ theme }) => theme.colors.orange.default};
      }
    `}
  `}

  &:active,&:hover {
    border: 0.15rem solid ${({ theme }) => theme.colors.orange.default};
    border-radius: 5px;
    background: white;
    color: ${({ theme }) => theme.colors.orange.default};
    svg {
      fill: ${({ theme }) => theme.colors.orange.default};
    }
  }
`
export const SidebarMobileBtnIcon = styled(Icon)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.mono.greyDark};
`

export const SidebarMenuItemLinkLabel = styled.span`
  flex-grow: 1;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  color: ${({ theme }) => theme.colors.orange.default};
  transition: color 0.3s;
`
export const SidebarMobileMenuItemLinkLabel = styled(SidebarMenuItemLinkLabel)`
  color: #fff;
`

export const SidebarMenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 1.5rem;
  border-radius: 0.4rem;

  &:hover {
    background: ${({ theme }) => theme.colors.orange.default};

    ${SidebarMenuItemLinkLabel},
    ${SidebarMenuItemLinkIcon} {
      color: ${({ theme }) => theme.colors.mono.white};
    }
  }
`
export const SidebarMobileMenuItemLink = styled(SidebarMenuItemLink)`
  padding: 1rem 1.5rem;
`

export const SidebarMenuItemLinkQty = styled.span`
  padding: 0.2rem 1rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  /* Mono/Black */
  color: #434647;
  background-color: #fff;
  border-radius: 2.5rem;
`

export const SidebarMobileTitle = styled.span`
  display: table;
  margin: 2rem auto;
  padding: 1rem 2.85rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.1rem;
  text-align: center;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  /* Mono/Grey Darker */
  color: #8f99a3;
  background-color: #fff;
  border-radius: 100px;

  @media screen and (min-width: 1200px) {
   width: 100%;
  }
`

export const Banner = styled(BannerSidebar)`
  margin-top: 6rem;
`

export const SidebarSubcategoryItemLinkLabel = styled.span`
  flex-grow: 1;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  color: ${({ theme }) => theme.colors.orange.default};
  transition: color 0.3s;
`
export const SidebarSubcategoryItemLinkQty = styled.span`
  padding: 0.2rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.7rem;
  /* Mono/Black */
  color: #434647;
  background-color: #fff;
  border-radius: 2.5rem;
`
export const SidebarSubcategoryItemLink = styled(Link)`
display: flex;
align-items: center;
justify-content: space-between;
padding: 0.5rem 1.5rem;
border-radius: 0.4rem;
margin-left: 4rem;

&:hover {
  background: ${({ theme }) => theme.colors.orange.default};

  ${SidebarSubcategoryItemLinkLabel} {
    color: ${({ theme }) => theme.colors.mono.white};
  }
};
`
export const SidebarSubcategoryItem = styled.li`
  margin-bottom: 0.5rem;
`

export const SidebarMobileSubcategoryItemLink = styled(SidebarSubcategoryItemLink)`
  padding: 1rem 1.5rem;
`
export const SidebarMobileSubcategoryItem = styled(SidebarSubcategoryItem)`
  &:first-child {
    padding-top: 1rem;
  }
  ::marker {
    content: "";
  }
`

export const SidebarMobileSubcategoryItemLinkLabel = styled(SidebarSubcategoryItemLinkLabel)`
  color: #fff;
`
