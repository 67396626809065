import { useState, useEffect } from 'react'
import { useOnOutsideClick } from '../../../hooks/useOnOutsideClick'
import * as S from './styles'
import api from '../../../services/api'
import { useAuth } from '../../../context/AuthContext'
import { useCart } from '../../../context/CartContext'
import { IMAGE_URL } from '../../../constants'

const PersonalMenu = ({ changeHeaderColor, isMobile }) => {
  const { user, signOut } = useAuth()
  const { clearCart } = useCart()
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [userPerfil, setUserPerfil] = useState({})
  const { innerBorderRef } = useOnOutsideClick(() => setIsMenuActive(false))

  const getUser = async () => {
    const response = await api.get(`/Buyer/${user.buyerId}`)
    await setUserPerfil(response.data)
  }

  useEffect(() => {
    getUser()
  }, [])

  const handleMenu = () => {
    setIsMenuActive(!isMenuActive)
  }

  return (
    <>
      {!isMobile &&
        <S.PersonalMenu ref={innerBorderRef}>
          <S.PersonalMenuInfos>
            <S.PersonalMenuName $changeHeaderColor={changeHeaderColor}>
              {userPerfil.firstName} {userPerfil.lastName}
            </S.PersonalMenuName>
            <S.PersonalMenuLink $changeHeaderColor={changeHeaderColor} to='/profile'>Editar meu perfil</S.PersonalMenuLink>
          </S.PersonalMenuInfos>
          <S.PersonalMenuButton $changeHeaderColor={changeHeaderColor} onClick={handleMenu} isMenuActive={isMenuActive}>
            <S.PersonalMenuAvatar>
              {userPerfil.image && (
                <img src={`${IMAGE_URL}${userPerfil.image}`} alt={`${userPerfil.firstName} ${userPerfil.lastName}`} />
              )}
            </S.PersonalMenuAvatar>
            <S.PersonalMenuIcon $changeHeaderColor={changeHeaderColor} type='upChevron' />
          </S.PersonalMenuButton>
          <S.PersonalMenuSubmenu isMenuActive={isMenuActive}>
            <S.PersonalMenuSubmenuItem>
              <S.PersonalMenuSubmenuLink to='/profile' onClick={handleMenu}>
                <S.PersonalMenuSubmenuIcon type='user' />
                Editar meu perfil
              </S.PersonalMenuSubmenuLink>
            </S.PersonalMenuSubmenuItem>
            <S.PersonalMenuSubmenuItem>
              <S.PersonalMenuSubmenuButton onClick={() => { handleMenu(); clearCart(); signOut() }}>
                <S.PersonalMenuSubmenuIcon type='signOut' />
                Fazer logoff
              </S.PersonalMenuSubmenuButton>
            </S.PersonalMenuSubmenuItem>
          </S.PersonalMenuSubmenu>
        </S.PersonalMenu>}
      {isMobile &&
        <S.PersonalMenuMobile>
          <S.PersonalMenuMobileLeft>
            <S.PersonalMenuAvatar>
              {userPerfil.image && (
                <img src={`${IMAGE_URL}${userPerfil.image}`} alt={`${userPerfil.firstName} ${userPerfil.lastName}`} />
              )}
            </S.PersonalMenuAvatar>
          </S.PersonalMenuMobileLeft>
          <S.PersonalMenuMobileRight>
            <S.PersonalMenuName $changeHeaderColor={changeHeaderColor} $isMobile>
              {userPerfil.firstName} {userPerfil.lastName}
            </S.PersonalMenuName>
            <S.PersonalMenuLink $changeHeaderColor={changeHeaderColor} $isMobile to='/profile'>Editar meu perfil</S.PersonalMenuLink>
          </S.PersonalMenuMobileRight>
        </S.PersonalMenuMobile>}

    </>
  )
}

export default PersonalMenu
