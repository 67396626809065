import { useState, useEffect } from 'react'
import { useOnOutsideClick } from '../../hooks/useOnOutsideClick'
import * as S from './styles'
import Container from '../Container'
import Cart from './Cart'
import PersonalMenu from './PersonalMenu'
import { useAuth } from '../../context/AuthContext'
import { useCart } from '../../context/CartContext'
import { useSearch } from '../../context/SearchContext'
import Sidebar from './../Sidebar'
import Minibar from './MiniBar'
import api from '../../services/api'
import { IMAGE_URL } from '../../constants'
import { Link } from 'react-router-dom'
import { useTheme } from '../../context/ThemeContext'
import HorizontalMenu from '../HorizontalMenu'

const Header = () => {
  const [isNotificationActive, setIsNotificationActive] = useState(false)
  const [notifications, setNotifications] = useState([])
  const { innerBorderRef } = useOnOutsideClick(() => setIsNotificationActive(false))
  const [isMenuActive, setIsMenuActive] = useState(false)
  const { handleSearch } = useSearch()
  const [searchValue, setSearchValue] = useState('')
  const { signOut, verifyAcessWithoutLogin, authenticated } = useAuth()
  const { clearCart } = useCart()
  const { updatedTheme } = useTheme()
  const accessWithoutLogin = verifyAcessWithoutLogin()
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))

  const ecommerceSearchBar = customer?.environmentConfiguration?.ecommerceSearchBar
  const searchBarPlaceholder = !!ecommerceSearchBar && !!ecommerceSearchBar.searchBarPlaceholder ? ecommerceSearchBar.searchBarPlaceholder : 'O que você está procurando?'
  const horizontalMenu = customer?.environmentConfiguration?.horizontalMenuTranspose
  const changeHeaderColor= customer?.environmentConfiguration?.customHeaderColor
  const currentThemeColor = updatedTheme.colors.orange.default
  const institutionalPageName = customer?.environmentConfiguration?.ecommerceInstitutionalInformation?.pageName
  const institutionalPageContent = customer?.environmentConfiguration?.ecommerceInstitutionalInformation?.pageHtml  

  const handleNotification = () => {
    setIsNotificationActive(!isNotificationActive)
  }
  const handleMenu = () => {
    setIsMenuActive(!isMenuActive)
  }

  const getNotifications = async () => {
    const response = await api.get('/api/v2/notification')
    if (response.data.success) {
      setNotifications([...response.data.response.data])
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])

  return (
    <>
      {customer && customer.b2BWelcomeMessage && customer.b2BWelcomeMessage != "<p><br></p><p></p>" && (
        <Minibar message={customer.b2BWelcomeMessage} />
      )}
      <S.Header $changeHeaderColor={changeHeaderColor} $horizontalMenu={horizontalMenu}>
        <Container>
          <S.HeaderWrapper>
            {
              accessWithoutLogin === true ?
              <>
                <S.HeaderLogoLink to='/' style={{ padding: 0 }}>
                  <S.HeaderLogo src={(customerInfo && customerInfo.logo) ? `${IMAGE_URL}${customerInfo.logo}` : '/assets/images/logo-bendito.svg'} />
                </S.HeaderLogoLink>
              </> 
              :
              <>
                <S.HeaderMobileIcon onClick={handleMenu}>
                  <S.btnLeftMobile $changeHeaderColor={changeHeaderColor} isActive={isMenuActive} />
                  <S.btnRightMobile $changeHeaderColor={changeHeaderColor} isActive={isMenuActive} />
                  <S.btnTextMobile $changeHeaderColor={changeHeaderColor}>Menu</S.btnTextMobile>
                </S.HeaderMobileIcon>
                <S.PersonalMenuSubmenu isMenuActive={isMenuActive}>
                  <PersonalMenu changeHeaderColor={changeHeaderColor} isMobile />
                  <S.PersonalMenuSubmenuItem>
                    <S.HeaderLinkWrapperMobile>
                      <S.HeaderNotificationBtnMobile
                        ref={innerBorderRef}
                        onClick={handleNotification}
                        isNotificationActive={isNotificationActive}
                      >
                        <S.HeaderIconMobile type='bell' />
                        <S.HeaderLinkLabelMobile>Notificações</S.HeaderLinkLabelMobile>
                      </S.HeaderNotificationBtnMobile>
                      <S.HeaderNotifications notifications={notifications} isNotificationActive={isNotificationActive} />
                    </S.HeaderLinkWrapperMobile>
                    <S.HeaderLinkWrapperMobile>
                      <S.HeaderOrdersLinkMobile to='orders' onClick={handleMenu}>
                        <S.HeaderIconMobile type='copy' />
                        <S.HeaderLinkLabelMobile>Meus Pedidos</S.HeaderLinkLabelMobile>
                      </S.HeaderOrdersLinkMobile>
                    </S.HeaderLinkWrapperMobile>
                    {institutionalPageName && institutionalPageContent &&
                      <S.HeaderLinkWrapperMobile>
                        <S.HeaderOrdersLinkMobile to='institutional'>
                          <S.HeaderIconMobile type='users' />
                          <S.HeaderLinkLabelMobile>{institutionalPageName}</S.HeaderLinkLabelMobile>
                        </S.HeaderOrdersLinkMobile>
                      </S.HeaderLinkWrapperMobile>
                    }
                    <S.HeaderLinkWrapperMobile>
                      <S.HeaderOrdersLinkMobile onClick={() => { handleMenu(); clearCart(); signOut() }} to='login'>
                        <S.HeaderIconMobile type='signOut' />
                        <S.HeaderLinkLabelMobile> Fazer logoff</S.HeaderLinkLabelMobile>
                      </S.HeaderOrdersLinkMobile>
                    </S.HeaderLinkWrapperMobile>
                  </S.PersonalMenuSubmenuItem>
                </S.PersonalMenuSubmenu>
                <S.HeaderLogoLink to='/'>
                  <S.HeaderLogo src={(customer?.person && customer?.person.image) ? `${IMAGE_URL}${customer?.person.image}` : changeHeaderColor && currentThemeColor === "#FF6300" ? '/assets/images/logo-bendito-white.svg' : '/assets/images/logo-bendito.svg'} />
                </S.HeaderLogoLink>
              </>
            }
            <S.HeaderMiddleWrapper>
              <S.CntSearch>
                <S.HeaderSearch onChange={e => { handleSearch(e.target.value); setSearchValue(e.target.value) }} placeholder={searchBarPlaceholder} />
                <S.HeaderIconSearch $changeHeaderColor={changeHeaderColor} type='search' onClick={handleSearch(searchValue)} />
              </S.CntSearch>
              {(!!authenticated || !customerInfo.b2BSellingInfoDisabled) &&
                (accessWithoutLogin === true ? (
                  <>
                    <Link to='login'>
                      <S.CartHeaderWrapper>
                        <S.CartIcon type='cart' />
                      </S.CartHeaderWrapper>
                    </Link>
                    <Link to='login'>
                      <S.MobileLoginBtn />
                    </Link>
                  </>
                
                ) : (
                    <Cart changeHeaderColor={changeHeaderColor} />
                ))
              }
            </S.HeaderMiddleWrapper>
            <S.CntSearchMobile>
              <S.HeaderSearchMobile onChange={e => { handleSearch(e.target.value); setSearchValue(e.target.value) }} placeholder='O que você está procurando?' />
              <S.HeaderIconSearch type='search' onClick={handleSearch(searchValue)} />
            </S.CntSearchMobile>
            {horizontalMenu ? <HorizontalMenu isMobile /> : <Sidebar isMobile />}
            {(!!authenticated || !customerInfo.b2BSellingInfoDisabled) && 
              <S.HeaderLastWrapper>
                <S.HeaderLinkWrapper>
                  <S.HeaderNotificationBtn
                    $changeHeaderColor={changeHeaderColor}
                    ref={innerBorderRef}
                    onClick={handleNotification}
                    isNotificationActive={isNotificationActive}
                  >
                    <S.HeaderIcon type='bell' />
                    <S.HeaderLinkLabel>Notificações</S.HeaderLinkLabel>
                  </S.HeaderNotificationBtn>
                  <S.HeaderNotifications notifications={notifications} isNotificationActive={isNotificationActive} />
                </S.HeaderLinkWrapper>
                {
                  accessWithoutLogin === true ? (
                    <>
                      <S.HeaderLinkWrapper>
                        <S.HeaderOrdersLink $changeHeaderColor={changeHeaderColor} to='login'>
                          <S.HeaderIcon type='copy' />
                          <S.HeaderLinkLabel>Meus Pedidos</S.HeaderLinkLabel>
                        </S.HeaderOrdersLink>
                      </S.HeaderLinkWrapper>
                      {institutionalPageName && institutionalPageContent &&
                        <S.HeaderLinkWrapper>
                          <S.HeaderOrdersLink $changeHeaderColor={changeHeaderColor} to='institutional'>
                            <S.HeaderIcon type='users' />
                            <S.HeaderLinkLabel>{institutionalPageName}</S.HeaderLinkLabel>
                          </S.HeaderOrdersLink>
                        </S.HeaderLinkWrapper>
                      }
                      <S.LoginBtnLinkWrapper to='login'>
                          <S.LoginBtn>Entrar/Cadastrar</S.LoginBtn>
                      </S.LoginBtnLinkWrapper>
                    </>
                  ) : (
                    <>
                      <S.HeaderLinkWrapper>
                        <S.HeaderOrdersLink $changeHeaderColor={changeHeaderColor} to='orders'>
                          <S.HeaderIcon type='copy' />
                          <S.HeaderLinkLabel>Meus Pedidos</S.HeaderLinkLabel>
                        </S.HeaderOrdersLink>
                      </S.HeaderLinkWrapper>
                      {institutionalPageName && institutionalPageContent &&
                        <S.HeaderLinkWrapper>
                          <S.HeaderOrdersLink $changeHeaderColor={changeHeaderColor} to='institutional'>
                            <S.HeaderIcon type='users' />
                            <S.HeaderLinkLabel>{institutionalPageName}</S.HeaderLinkLabel>
                          </S.HeaderOrdersLink>
                        </S.HeaderLinkWrapper>
                      }
                      <PersonalMenu changeHeaderColor={changeHeaderColor} />
                    </>
                  )
                }
              </S.HeaderLastWrapper>
            }
          </S.HeaderWrapper>
        </Container>
      </S.Header>
    </>
  )
}

export default Header
