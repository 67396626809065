import styled from 'styled-components/macro'

export const MiniBar = styled.div`
  background-color: ${({ theme }) => theme.colors.orange.light10};
  padding: .65rem 0;
  text-align: center;

  @media ${({ theme }) => theme.screens.md} {
    padding: 1rem 0;
  }
`

export const Text = styled.div`
  p {
    font-size: 1.3rem;
    line-height: 1.7rem;
    color: ${({ theme }) => theme.colors.orange.default};
  }

  span {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.mono.black};
  }

  a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.orange.default};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.mono.blackDark};
    }
  }
`
