import { useEffect, useState } from 'react'
import api from '../../../services/api'
import LoadingBackground from '../../LoadingBackground'
import * as S from './styles'

const CustomerList = ({ productId, model, isDetailProductPage, characteristicValues, unitMeasureList, allCharacteristicsList, productCharacteristicsList }) => {
  const [loading, setLoading] = useState(false)
  const [characteristics, setCharacteristics] = useState([])
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))
  const characteristicsB2B = customer?.environmentConfiguration?.characteristicsB2B

  useEffect(() => {
    const getCustomer = async () => {
      setLoading(true);
      let allCharacteristicsData = allCharacteristicsList || [];
      let productCharacteristicsData = productCharacteristicsList || [];

      try {
        if (!isDetailProductPage) {
          const [responseAllC, responseProduct] = await Promise.all([
            api.get('/api/v2/Characteristic'),
            api.get(`/api/v2/Product/${productId}/Characteristics`)
          ]);
          
          allCharacteristicsData = responseAllC.data.response.data;
          productCharacteristicsData = responseProduct.data.response.data;
        }

        const unitMeasure = isDetailProductPage 
          ? unitMeasureList 
          : (await api.get('/Domain?DomainId=UNIDADE_MEDIDA')).data;
  
        let data = productCharacteristicsData.map(item => {
          const characteristic = allCharacteristicsData.find(element => element.id === item.characteristicId);
          return {
            ...item,
            description: characteristic?.description || '',
            unitMeasure: characteristic?.unitMeasure || ''
          };
        });

        if (characteristicsB2B?.length > 0) {
          const updatedCharacteristics = [];
          for (const element of characteristicsB2B) {
            for (const item of data) {
              const unit = unitMeasure?.find((elem) => elem.value === item.unitMeasure);              
              if (item.characteristicId === element) {
                if (item.value) {
                  updatedCharacteristics.push({
                    ...item,
                    value: item.value,
                    unitMeasure: unit?.shortDescription || ''
                  });
                } else {
                  // Só faz a chamada de API se item.value for null
                  const currentCharValue = characteristicValues?.find(char => char.charId === item.characteristicId);
                  const getCustomerNull = isDetailProductPage
                    ? currentCharValue?.response
                    : (await api.get(`/api/v2/Characteristic/${item.characteristicId}/Values`));

                  const customerNull = getCustomerNull?.data.response.data.find(customer => customer.id === item.valueId);
                  updatedCharacteristics.push({
                    ...item,
                    value: customerNull?.description || '',
                    unitMeasure: unit?.shortDescription || ''
                  });
                }
              }
            }
          }
          setCharacteristics(updatedCharacteristics);
        }
      } catch (error) {
        console.error('Erro ao buscar características:', error);
      } finally {
        setLoading(false);
      }
    };
  
    getCustomer();
  }, [productCharacteristicsList, characteristicValues]);
  
  return (
    <S.CustomerLine>
      {loading ? (
        <LoadingBackground />
      ) : (
        characteristics.map((item, i) => (
          <S.ProductInfo key={i}>
            <S.ProductInfoLabel>{item.description}:</S.ProductInfoLabel>
            <S.ProductInfoValue model={model}>{item.value ? `${item.value}${item.unitMeasure}` : `${item.description}${item.unitMeasure}`}</S.ProductInfoValue>
          </S.ProductInfo>
        ))
      )}
    </S.CustomerLine>
  )
}

export default CustomerList
