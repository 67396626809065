import { useState, useEffect } from 'react'
import { useTitle } from '../../hooks/useTitle'
import { toast } from 'react-toastify'
import api from '../../services/api'
import * as S from './styles'
import ProductCard from '../../components/ProductCard'
import BannerSlide from '../../components/BannerSlide'
import LoadingBackground from '../../components/LoadingBackground'
import { useAuth } from '../../context/AuthContext'
import { useSearch } from '../../context/SearchContext'
import Select from '../../components/Select'
import { useTheme } from '../../context/ThemeContext'
import BannerTopPage from '../../components/BannerTopPage'
import BannerEndPage from '../../components/BannerEndPage'

const Home = () => {
  const [visualization, setVisualization] = useState(2)
  const [customer, setCustomer] = useState({})
  const [opportunitiesProducts, setOpportunitiesProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { user, verifyAcessWithoutLogin, authenticated, getCustomer } = useAuth()
  const { loadingBackground, products } = useSearch()
  const { updateTheme } = useTheme()
  const [currentPage] = useState(1)
  const accessWithoutLogin = verifyAcessWithoutLogin()
  const { orderFilter, setOrderFilter } = useSearch();
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'));
  const [horizontalMenu, setHorizontalMenu] = useState()
  const [newProductsBeforeBanners, setNewProductsBeforeBanners] = useState()
  const [imagesCarousel, setImagesCarousel] = useState()
  const [imagesPurchasingPortal, setImagesPurchasingPortal] = useState()
  const [bannerAtTopPage, setBannerAtTopPage] = useState()
  const [bannerAtEndPage, setBannerAtEndPage] = useState()

  let bannerCount = -1

  const getOpportunitiesProducts = async (order, page) => {
    const response = await api.get(`/api/v2/Product/HighLights?Order=${order}&Page=${page}&Size=20&OnlyB2B=true`)
    setOpportunitiesProducts(response.data.response.data)
    saveDateToStorage()
  }

  const getCustomerInfos = async () => {
    setIsLoading(true)
    const response = await getCustomer(user.companyID)
    const colors = response?.environmentConfiguration.ecommerceColors
    updateTheme(colors)
    setCustomer(response)
    setHorizontalMenu(response?.environmentConfiguration?.horizontalMenuTranspose)
    setNewProductsBeforeBanners(response?.environmentConfiguration?.newProductsBeforeBanners)
    setImagesCarousel(response?.environmentConfiguration?.ecommerceImages?.imagesCarousel)
    setImagesPurchasingPortal(response?.environmentConfiguration?.ecommerceImages?.imagesPurchasingPortal)
    setBannerAtTopPage(response?.environmentConfiguration?.ecommerceImages?.bannerAtTopPage)
    setBannerAtEndPage(response?.environmentConfiguration?.ecommerceImages?.bannerAtEndPage)
    setIsLoading(false)
  }
  const handleOrder = (e) => {
    setOrderFilter(e)
    getOpportunitiesProducts(e, currentPage)
  }

  const saveDateToStorage = () => {
    const formatted = JSON.parse(localStorage.getItem('@DAuth:user'))
    const currentDate = new Date()
    const storageDate = formatted?.date

    if (storageDate === null) {
      toast.warning('Lembre-se de atualizar seus dados na área meu perfil, localizada no canto superior direito da tela.', {
        position: 'top-right',
        closeOnClick: true,
        autoClose: false,
      })
      localStorage.setItem('@DAuth:user', JSON.stringify({ ...formatted, date: currentDate }))
    } else {
      const diffTime = Math.abs(currentDate - new Date(storageDate))
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      if (diffDays >= 30) {
        toast.warning('Lembre-se de atualizar seus dados na área meu perfil, localizada no canto superior direito da tela.', {
          position: 'top-right',
          closeOnClick: false,
          autoClose: false,
        })
        localStorage.setItem('@DAuth:user', JSON.stringify({ ...formatted, date: currentDate }))
      }
    }
  }

  const invertBannerCarrosselOrder = () => {
    if (customer && newProductsBeforeBanners) {
      return (
        <>
          {(!!authenticated || !customerInfo.b2BSellingInfoDisabled) && <S.HomeSlideNewProducts />}
          {imagesCarousel?.length > 0 && <BannerSlide banners={imagesCarousel} />}
        </>
      )
    } else {
      return (
        <>
          {imagesCarousel?.length > 0 && <BannerSlide banners={imagesCarousel} />}
          {(!!authenticated || !customerInfo.b2BSellingInfoDisabled) && <S.HomeSlideNewProducts />}
        </>
      )
    }
  }

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      await getOpportunitiesProducts(orderFilter, currentPage)
      await getCustomerInfos()
      setIsLoading(false)
    }
    loadData()
  }, [])
  useEffect(() => {
    setOpportunitiesProducts(products)
  }, [products])

  useTitle('Página Inicial')

  return (
    <>
      {(isLoading || loadingBackground) ? (
        <LoadingBackground />
      ) : (
        <>
          {customer && horizontalMenu && bannerAtTopPage?.active && bannerAtTopPage?.image ? (
            <BannerTopPage banner={bannerAtTopPage?.image} linkType={bannerAtTopPage?.linkType} linkId={bannerAtTopPage?.linkId}/>
          ) : null}

          { invertBannerCarrosselOrder() }

          <S.HomeTopInfo>
            <S.HomeTopInfoTitle>
              Destaques para você
            </S.HomeTopInfoTitle>
            <S.HomeTopOrderCnt>
              <label htmlFor=''>Ordenação</label>
              <Select value={orderFilter} onChange={(e) => handleOrder(e.target.value)}>
                <option value='0'>Nome - De A para Z</option>
                <option value='1'>Nome - De Z para A</option>
                {
                  accessWithoutLogin === false &&
                  <>
                    <option value='2'>Preço - Do menor para o maior</option>
                    <option value='3'>Preço - Do maior para o menor</option>
                  </>
                }
              </Select>
            </S.HomeTopOrderCnt>
            <S.HomeViewCnt>
              <S.HomeViewBtnText>Visualização</S.HomeViewBtnText>
              <S.HomeViewBtnCnt isMobile>
                <S.HomeViewBtn isActive={visualization === 1} onClick={() => setVisualization(1)}>
                  <S.HomeViewBtnIcon type='oneCol' />
                </S.HomeViewBtn>
                <S.HomeViewBtn isActive={visualization === 2} onClick={() => setVisualization(2)}>
                  <S.HomeViewBtnIcon type='twoCol' />
                </S.HomeViewBtn>
              </S.HomeViewBtnCnt>
              <S.HomeViewBtnCnt>
                <S.HomeViewBtn isActive={visualization === 2} onClick={() => setVisualization(2)}>
                  <S.HomeViewBtnIcon type='twoCol' />
                </S.HomeViewBtn>
                <S.HomeViewBtn isActive={visualization === 3} onClick={() => setVisualization(3)}>
                  <S.HomeViewBtnIcon type='thrCol' />
                </S.HomeViewBtn>
              </S.HomeViewBtnCnt>
            </S.HomeViewCnt>
          </S.HomeTopInfo>
          <S.HomeGridProducts visualization={visualization}>
            {opportunitiesProducts.length > 0 &&
              opportunitiesProducts.map((product, index) => {
                bannerCount = !((index + 1) % (visualization === 1 ? 4 : 6)) ? bannerCount + 1 : bannerCount
                return (index + 1) % (visualization === 1 ? 4 : 6)
                  ? (<ProductCard key={product.id} product={product} styleQtd={visualization} />)
                  : (
                    <>
                      <ProductCard key={product.id} product={product} styleQtd={visualization} />
                      {customer.imagesPurchasingPortal && imagesPurchasingPortal && imagesPurchasingPortal[bannerCount] && (
                        <S.HomeProductBanner banner={imagesPurchasingPortal[bannerCount]?.image} key={index} linkType={imagesPurchasingPortal[bannerCount]?.linkType} linkId={imagesPurchasingPortal[bannerCount]?.linkId} />
                      )}
                    </>
                    )
              },
              )}
          </S.HomeGridProducts>
          {(!!authenticated || !customerInfo.b2BSellingInfoDisabled) &&
            <S.HomeSlideOpportunities />
          }
          {opportunitiesProducts.length === 0 &&
            <S.NoResults>
              Nenhum produto encontrado
            </S.NoResults>}
          {customer.b2BFAQClient && customer.b2BFAQClient != "<p><br></p><p></p>" && (
            <S.HomeFaq questions={customer.b2BFAQClient} />
          )}

          {customer && bannerAtEndPage?.image && (
            <BannerEndPage banner={bannerAtEndPage?.image} linkType={bannerAtEndPage?.linkType} linkId={bannerAtEndPage?.linkId}/>
          )}
        </>
      )}
    </>
  )
}

export default Home
//
