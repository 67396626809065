import * as S from './styles'

export const BannerSidebar = ({ className }) => {
  return (
    <S.BannerSidebar className={className}>
      <S.Image src='/assets/images/banner-sidebar.png' />
      <S.Text>Acesse agora nossas ofertas exclusivas de Natal e aproveite os descontos!</S.Text>
      <S.Button>Ver ofertas de Natal</S.Button>
    </S.BannerSidebar>
  )
}

export default BannerSidebar
