import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTitle } from '../../hooks/useTitle'
import { toast } from 'react-toastify'
import { useAuth } from '../../context/AuthContext'
import api from '../../services/api'
import * as S from './styles'
import Input from '../../components/Input'
import Select from '../../components/Select'
import { Button } from '../../commons/Button'
import Breadcrumb from '../../components/Breadcrumb'
import LoadingBackground from '../../components/LoadingBackground'

const Profile = () => {
  const { user } = useAuth()
  const [profile, setProfile] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBackground, setIsLoadingBackground] = useState(true)
  const [cepValue, setCepValue] = useState('')

  /* eslint-disable no-unused-vars */
  const { register, handleSubmit, reset, formState: { errors } } = useForm()

  const getUser = async () => {
    const response = await api.get(`/Buyer/${user.buyerId}`)
    const responseAdr = await api.get(`api/v2/Client/${user.clientId}/Address`)
    const addressResponse = responseAdr.data.response.data.at(-1)
    
    setIsLoadingBackground(false)
    const [day, month, year] = response.data.birthdate === null ? [0, 0, 0] : response.data.birthdate.split('/')
    setProfile({
      activeNewsLetter: response.data.activeNewsLetter,
      username: response.data.username,
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      phoneNumber: response.data.phonePrefix ? `(${response.data.phonePrefix?.toString() || ''})${(response.data.phoneNumber?.toString() || '')}` : '',
      cellphoneNumber: response.data.cellphonePrefix ? `(${(response.data.cellphonePrefix?.toString() || '')})${(response.data.cellphoneNumber?.toString() || '')}` : '',
      birthdate: response.data.birthdate,
      day: +day,
      month,
      year,

      addressId: addressResponse?.id,
      type: 1,
      cep: addressResponse?.cep,
      address: addressResponse?.address,
      number: addressResponse?.number,
      complement: addressResponse?.complement,
      neighborhood: addressResponse?.neighborhood,
      city: addressResponse?.city,
      state: addressResponse?.state,
    })
  }

  const cepChangeHandler = (e) => {
    e.preventDefault()
    setCepValue(e.target.value)
  }

  const getAddressByCEP = async () => {
    const cepFormatted = cepValue.substring(0, 5) + '-' + cepValue.substring(5, 10)

    setIsLoading(true)
    if (cepValue.length !== 8) {
      toast.warning('Informe somente os números de um CEP válido', {
        position: 'top-right',
      })
      return false
    }

    try {
      const response = await api.get(`api/v2/Client/GetCepData?cep=${cepFormatted}`)
      const data = response.data.response.data
      setProfile({
        ...profile,
        type: 1,
        cep: data.cep.replace('-', ''),
        address: data.address,
        number: data.number,
        complement: data.complement,
        neighborhood: data.neighborhood,
        city: data.city,
        state: data.state,
      })
    } catch (error) {
      toast('Ocorreu um erro ao buscar o CEP', {
        type: 'error',
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handlePhonesChange = (event) => {
    const inputValue = event.target.value;
    const formattedInputValue = inputValue.replace(/[^0-9()]/g, ''); 
    event.target.value = formattedInputValue;
  };

  useEffect(() => {
    getUser()
  }, [])

  useEffect(() => {
    reset(profile)
  }, [reset, profile])

  const updateUserData = async data => {
    const formattedPhoneNumber = data.phoneNumber.replace(/\D/g, '');
    const formattedCellphoneNumber = data.cellphoneNumber.replace(/\D/g, '');

    if (formattedPhoneNumber.length > 0 && !(formattedPhoneNumber.length >= 10 && formattedPhoneNumber.length <= 11)) {
      toast.warning('Número do telefone inválido!', {
        position: 'top-right',
      })
      return false
    }

    if (formattedCellphoneNumber.length > 0 && !(formattedCellphoneNumber.length >= 10 && formattedCellphoneNumber.length <= 11)) {
      toast.warning('Número do celular inválido!', {
        position: 'top-right',
      })
      return false
    }

    let birthdate = null

    if (data.day && data.month && data.year) {
      birthdate = `${data.day}/${data.month}/${data.year}`
    } else if (!(!data.day && !data.month && !data.year)) {
      toast.warning('Todos os campos da data de nascimento devem ser preenchidos!', {
        position: 'top-right',
      })
      return false
    }

    if ((data.password || data.newPassword) && data.password !== data.newPassword) {
      toast.warning('Os dois campos de senha precisam ser preenchidos e/ou estarem iguais!', {
        position: 'top-right',
      })
      return false
    }

    if (+data.state === 0) {
      toast.warning('Informe um estado', {
        position: 'top-right',
      })
      return false
    }

    const newData = {
      ...data,
      birthdate,
      phonePrefix: formattedPhoneNumber.substring(0, 2),
      phoneNumber: formattedPhoneNumber.substring(2),
      cellphonePrefix: formattedCellphoneNumber.substring(0, 2),
      cellphoneNumber: formattedCellphoneNumber.substring(2),
    }

    const addressData = {
      id: data.addressId ? data.addressId : 0,
      type: 1,
      cep: data.cep,
      address: data.address,
      number: +data.number,
      complement: data.complement,
      neighborhood: data.neighborhood,
      city: data.city,
      state: +data.state,
    }

    setIsLoading(true)
    try {
      await toast.promise(
        api.put(`/Buyer/${user.buyerId}`, newData), {
          pending: 'Atualizando o comprador',
          success: {
            render ({ data }) {
              return data.data.successMessages[0]
            },
            delay: 750,
          },
          error: {
            render ({ data }) {
              console.log('error data', data)
              return data.title ? data.title : 'Erro ao salvar dados do perfil'
            },
            delay: 750,
          },
        },
        api.post(`api/v2/Client/${user.clientId}/Address`, addressData).then(res => {
          const addressRes = res.data.response.data;
          setProfile({
            ...data,
            addressId: addressRes.id
          })
        }),
      )
    } catch (error) {
      console.log('erro no catch - ', error)
      // toast.error('Erro ao salvar dados do perfil!')
    } finally {
      setIsLoading(false)
    }
  }

  const updateUserDataError = (errors, e) => {
    Object.entries(errors).forEach(entry => {
      toast.warning(entry[1].message)
    })
  }

  const generateOptions = (min, max) => {
    const options = []
    for (let i = min; i <= max; i++) {
      options.push(<option key={i} value={i}>{i}</option>)
    }
    return options
  }

  useTitle('Meus dados')

  const selectEstados = [
    { label: '', value: 0 },
    { label: 'Acre', value: 1 },
    { label: 'Alagoas', value: 2 },
    { label: 'Amapá', value: 3 },
    { label: 'Amazonas', value: 4 },
    { label: 'Bahia', value: 5 },
    { label: 'Ceará', value: 6 },
    { label: 'Espírito Santo', value: 7 },
    { label: 'Goiás', value: 8 },
    { label: 'Maranhão', value: 9 },
    { label: 'Mato Grosso', value: 10 },
    { label: 'Mato Grosso do Sul', value: 11 },
    { label: 'Minas Gerais', value: 12 },
    { label: 'Pará', value: 13 },
    { label: 'Paraíba', value: 14 },
    { label: 'Paraná', value: 15 },
    { label: 'Pernambuco', value: 16 },
    { label: 'Piauí', value: 17 },
    { label: 'Rio de Janeiro', value: 18 },
    { label: 'Rio Grande do Norte', value: 19 },
    { label: 'Rio Grande do Sul', value: 20 },
    { label: 'Rondônia', value: 21 },
    { label: 'Roraima', value: 22 },
    { label: 'Santa Catarina', value: 23 },
    { label: 'São Paulo', value: 24 },
    { label: 'Sergipe', value: 25 },
    { label: 'Tocantins', value: 26 },
    { label: 'Distrito Federal', value: 27 },
  ]

  return (
    <S.Profile>
      {isLoadingBackground && (
        <LoadingBackground />
      )}

      <Breadcrumb
        current='Editar meus dados'
        parent='Meu Perfil'
      />
      <S.ProfileInner>
        <S.ProfileTitle>Editar meus dados</S.ProfileTitle>
        <S.ProfileForm onSubmit={handleSubmit(updateUserData, updateUserDataError)}>
          <Input type='hidden' name='birthdate' register={register} />

          <S.ProfileFormBox>
            {/* Email */}
            <Input
              type='text'
              label='E-mail'
              name='username'
              register={register}
              disabled
            />
            {/* Names */}
            <S.ProfileRowNames>
              <Input
                type='text'
                label='Nome'
                name='firstName'
                register={register}
                required='Informe o nome'
              />
              <Input
                type='text'
                label='Sobrenome'
                name='lastName'
                register={register}
                required='Informe o sobrenome'
              />
            </S.ProfileRowNames>

            {/* Phones */}
            <S.ProfileRowPhones>
              <Input
                type='text'
                name='phoneNumber'
                label='(DDD) + Telefone'
                maxlength='11'
                pattern='[0-9]+'
                title='Apenas números'
                register={register}
                onChange={handlePhonesChange}
              />
              <Input
                type='text'
                name='cellphoneNumber'
                label='(DDD) + Celular'
                maxlength='11'
                pattern='[0-9]+'
                title='Apenas números'
                register={register}
                onChange={handlePhonesChange}
              />
            </S.ProfileRowPhones>

            {/* Birthday */}
            <S.ProfileRowBirthday style={{ marginBottom: '1rem' }}>
              <S.ProfileLabel>Data de nascimento</S.ProfileLabel>
              <S.ProfileRowGrid>
                <Select
                  name='day'
                  register={register}
                >
                  {generateOptions(1, 31)}
                </Select>
                <Select
                  name='month'
                  register={register}
                >
                  <option value='01'>Janeiro</option>
                  <option value='02'>Fevereiro</option>
                  <option value='03'>Março</option>
                  <option value='04'>Abril</option>
                  <option value='05'>Maio</option>
                  <option value='06'>Junho</option>
                  <option value='07'>Julho</option>
                  <option value='08'>Agosto</option>
                  <option value='09'>Setembro</option>
                  <option value='10'>Outubro</option>
                  <option value='11'>Novembro</option>
                  <option value='12'>Dezembro</option>
                </Select>
                <Select
                  name='year'
                  register={register}
                >
                  {generateOptions(1950, 2010)}
                </Select>
              </S.ProfileRowGrid>
            </S.ProfileRowBirthday>

            {/* Address */}
            <S.ProfileRowCEP>
              <Input
                type='number'
                label='CEP'
                name='cep'
                register={register}
                required='Informe um CEP'
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 0 }}
                onChange={cepChangeHandler}
              />
              <S.InputSearchCEPButton onClick={(e) => { e.preventDefault(); getAddressByCEP() }}>
                <S.InputIcon type='search' />
              </S.InputSearchCEPButton>
            </S.ProfileRowCEP>
            <S.ProfileRowAddress>
              <Input
                type='text'
                label='Endereço'
                name='address'
                register={register}
                required='Informe o endereço'
              />
              <Input
                type='number'
                label='Número'
                name='number'
                register={register}
                required='Informe o número'
              />
            </S.ProfileRowAddress>
            <Input
              type='text'
              label='Complemento'
              name='complement'
              register={register}
            />
            <Input
              type='text'
              label='Bairro'
              name='neighborhood'
              register={register}
              required='Informe o bairro'
            />
            <Input
              type='text'
              label='Cidade'
              name='city'
              register={register}
              required='Informe a cidade'
            />
            <S.ProfileRowState>
              <S.ProfileLabel>Estado <span style={{ color: 'red' }}>*</span></S.ProfileLabel>
              <Select
                name='state'
                register={register}
              >
                {selectEstados.map(item => {
                  return (<option key={item.value} value={item.value}>{item.label}</option>)
                })}
              </Select>
            </S.ProfileRowState>

            {/* Password */}
            <S.ProfileRowGrid>
              <Input
                type='password'
                label='Alterar senha'
                name='password'
                register={register}
              />
              <Input
                type='password'
                label='Confirmar nova senha'
                name='newPassword'
                register={register}
              />
            </S.ProfileRowGrid>
          </S.ProfileFormBox>

          <S.ProfileFormCheckboxWrapper>
            <label>
              <input type='checkbox' name='politica' {...register('politica')} checked />
              Eu concordo com a Política de Privacidade e os Termos de Uso de dados aplicados neste website.
            </label>
          </S.ProfileFormCheckboxWrapper>

          <S.ProfileFormCheckboxWrapper>
            <label>
              <input type='checkbox' name='activeNewsLetter' {...register('activeNewsLetter')} />
              Eu gostaria de receber novidades da Bendito no meu e-mail eventualmente.
            </label>
          </S.ProfileFormCheckboxWrapper>

          <Button
            fullwidth
            type='submit'
            disabled={isLoading}
          >
            Salvar alterações
          </Button>
        </S.ProfileForm>
      </S.ProfileInner>
    </S.Profile>
  )
}

export default Profile
