import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Footer = styled.footer`
  padding: 1.2rem 0 1rem;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.colors.mono.grey};
`

export const Text = styled.span`
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};

  br {
    display: none;

    @media ${({ theme }) => theme.screens.md} {
      display: block;
    }
  }

  span {
    @media ${({ theme }) => theme.screens.md} {
      display: none;
    }
  }
`

export const TextLink = styled.a`
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};

  br {
    display: none;

    @media ${({ theme }) => theme.screens.md} {
      display: block;
    }
  }

  span {
    @media ${({ theme }) => theme.screens.md} {
      display: none;
    }
  }

  &:hover{
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const CustomFooter = styled.footer`
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.colors.mono.grey};
`

export const CFooterContainer = styled.div`
  padding: 3rem 0;
  background-color: ${({ theme }) => theme.colors.mono.white};
  display: grid;
  grid-auto-flow: column;
  align-items: start;
  justify-content: space-around;

  @media ${({ theme }) => theme.screens.md} {
    grid-auto-flow: row;
  }
`

export const CFooterInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${({ theme }) => theme.screens.md} {
    margin-bottom: 2rem;
    align-items: center;
  }
`

export const CFooterSocialMedia = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.screens.md} {
    align-items: center;
  }
`

export const CFooterCompanySocialMediaWrapper = styled.div`
  padding-y: 2rem;
  display: flex;
`

export const CFooterLogo = styled.img`
  max-width: 100%;
  height: auto;
`

export const CFooterLogoWrapper = styled.div`
  display: flex;
  width: 16rem;

  @media ${({ theme }) => theme.screens.md} {
    display: flex;
    justify-content: center;
  }
`

export const CFooterCompanyName = styled.h3`
  color: ${({ theme }) => theme.colors.mono.blackDark};
  margin-top: 1.5rem;
`

export const CFooterCompanyCNPJ = styled.p`
  color: ${({ theme }) => theme.colors.mono.blackDark};
  margin-top: 0.5rem;
`

export const CFooterCompanyMail = styled.p`
  color: ${({ theme }) => theme.colors.mono.blackDark};
  margin-top: 2rem;
  font-weight: bold;
`

export const CFooterCompanyPhone = styled.p`
  color: ${({ theme }) => theme.colors.mono.blackDark};
  margin-top: 1rem;
`

export const CFooterCompanyAddress = styled.p`
  color: ${({ theme }) => theme.colors.mono.blackDark};
  margin-top: 2rem;
`

export const CFooterCompanyCity = styled.p`
  color: ${({ theme }) => theme.colors.mono.blackDark};
`

export const CFooterIconLink = styled.a`
  color: ${({ theme }) => theme.colors.mono.blackDark};
  cursor: pointer;
  margin-right: 2rem;
`

export const CFooterIconWrapper = styled.span`
  background: ${({ theme }) => theme.colors.orange.default};
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 20%;

  &:hover{
    transform: translateY(-6px);
    transition: 0.4s;
  }

  @media ${({ theme }) => theme.screens.md} {
    pointer-events: none;
  }
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.orange.default}
`

export const StyledFontAwesomeSocialMediaIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.mono.white}
`

export const CFooterCompanyContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const WhatsAppIconLink = styled.a`
  cursor: pointer;
  margin-bottom: 2.5rem;
  margin-right: 2rem;
  left: auto;
  position: fixed;
  left: auto;
  right: 0;
  bottom: 0;
  z-index: 10001;

  @media ${({ theme }) => theme.screens.md} {
    margin-bottom: 5rem;
  }
`

export const WhatsAppIconWrapper = styled.span`
  height: 35px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(37,211,102);
  border-radius: 3rem;
  gap: 1rem;
  font-size: 14px;
  color: white;

  @media ${({ theme }) => theme.screens.md} {
   display: none;
  }
`

export const WhatsAppIconWrapperMobile = styled.span`
  display: none;
  
  @media ${({ theme }) => theme.screens.md} {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(37,211,102);
    border-radius: 3rem;
    color: white;
  }
`

export const WhatsAppIcon = styled(FontAwesomeIcon)`
  color: white;
`
