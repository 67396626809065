import * as S from './styles'
import React from 'react'
import { IMAGE_URL } from '../../constants'
import { makeBannerLink } from '../../util/makeBannerLink'

const BannerTopPage = ({ banner, linkType, linkId }) => {
  const url = makeBannerLink(linkType, linkId)
  return (
    <S.BannerTopPage href={url}>
        <S.BannerTopPageImage src={`${IMAGE_URL}${banner}`} alt='' />
    </S.BannerTopPage>
  )
}

export default BannerTopPage
