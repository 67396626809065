import styled, { css } from 'styled-components/macro'
import SlideOpportunities from '../../components/SlideOpportunities'
import ProductBanner from '../../components/ProductBanner'
import Icon from '../../commons/Icon'
import FaqContent from '../../components/FaqContent'
import SlideNewProducts from '../../components/SlideNewProducts'
// import { Button } from '../../commons/Button'

export const HomeProductBanner = styled(ProductBanner)``

export const HomeGridProducts = styled.div`
  margin-bottom: 6.2rem;

  ${({ visualization }) => css`
    display: grid;
    gap: 4rem 3.6rem;

    @media ${({ theme }) => theme.screens.md} {
      gap: 5.5rem 2rem;
    }
    @media ${({ theme }) => theme.screens.xs} {
      gap: 2.5rem 1.4rem;
    }
    ${visualization === 1 &&
    css`
      grid-template-columns: 1fr;
    `}

    ${visualization === 2 &&
    css`
      grid-template-columns: repeat(2, 1fr);
    `}
    ${visualization === 3 &&
    css`
      grid-template-columns: repeat(3, 1fr);
      @media ${({ theme }) => theme.screens.md} {
        grid-template-columns: repeat(2, 1fr);
      }
    `}

  ${HomeProductBanner} {
      grid-column: 1 / -1;
    }
  `}
`

export const HomeSlideOpportunities = styled(SlideOpportunities)`
  margin: 8rem 0;
`

export const HomeSlideNewProducts = styled(SlideNewProducts)`
  margin: 5rem 0;
`

export const HomeViewCnt = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 15px;
  @media ${({ theme }) => theme.screens.sm} {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const HomeViewBtnText = styled.div`
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 17px;
  padding-left: 4rem;
  margin-left: 4rem;
  border-left: 2px solid ${({ theme }) => theme.colors.mono.grey};
  color: ${({ theme }) => theme.colors.mono.greyDarker};
  @media ${({ theme }) => theme.screens.md} {
    font-size: 1.6rem;
    padding-left: 0;
    margin-left: 0;
    border: none;
  }
`
export const HomeViewBtnCnt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 4px;
  background: ${({ theme }) => theme.colors.mono.grey};
  border-radius: 8px;
  @media ${({ theme }) => theme.screens.md} {
    display: none;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      display: none;
      @media ${({ theme }) => theme.screens.md} {
        display: flex;
      }
    `}
`
export const HomeViewBtn = styled.button`
  width: 34px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  svg {
    path {
      transition: all 0.3s ease;
    }
  }
  ${({ isActive }) => css`
    ${isActive &&
    css`
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    `}
    ${!isActive &&
    css`
      svg {
        path {
          fill: ${({ theme }) => theme.colors.mono.greyDarker};
        }
      }
    `}
  `}
`
export const HomeViewBtnIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.orange.default};
`

export const HomeTopInfo = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 7.8rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media ${({ theme }) => theme.screens.sm} {
    gap: 3.2rem;
    justify-content: space-between;
  }
  @media ${({ theme }) => theme.screens.xs} {
    justify-content: center;
  }
`
export const HomeTopInfoTitle = styled.h3`
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.colors.mono.black};
  @media ${({ theme }) => theme.screens.sm} {
    font-size: 2.2rem;
    width: 100%;
  }
  @media ${({ theme }) => theme.screens.xs} {
    text-align: center;
    display:none;
  }
`
export const HomeTopOrderCnt = styled.div`
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media ${({ theme }) => theme.screens.sm} {
    margin-left: initial;
    flex-direction: column;
    align-items: flex-start;
  }
  label {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 17px;
    margin-right: 1.5rem;
    color: ${({ theme }) => theme.colors.mono.greyDarker};
    @media ${({ theme }) => theme.screens.md} {
      font-size: 1.6rem;
    }
  }
`

export const HomeFaq = styled(FaqContent)`
  margin: 0 0 12rem;
`
export const NoResults = styled.div`
  font-size: 3rem;
  text-align: center;
  margin: 10rem 0;
`
