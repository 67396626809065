export const changeThemeColor = (receivedTheme, defaultTheme) => {
  const updatedTheme = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors, // Copia todas as cores originais
      orange: {
        ...defaultTheme.colors.orange, // Copia todas as propriedades de "orange"
        default: receivedTheme?.defaultColor || defaultTheme.colors.orange.default,
        dark: receivedTheme?.dark || defaultTheme.colors.orange.dark,
        light10: receivedTheme?.light || defaultTheme.colors.orange.light10,
      }
    }
  }
  return updatedTheme
}