import styled, { css } from 'styled-components/macro'
import Icon from '../../commons/Icon'
import { Swiper } from 'swiper/react'

export const SlideNewProducts = styled.div`
  position: relative;
  margin-top: 5rem;
`

export const SlideNewProductsTitle = styled.h2`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -.1rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.mono.black};

  span {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const SlideNewProductsContainer = styled.div`
  position: relative;
  width: 100%;
`

export const SlideNewProductsSwiper = styled(Swiper)`
  padding: 3rem 2rem;
  margin: 0 6rem;

  @media ${({ theme }) => theme.screens.md} {
    margin: 0;
  }

  .swiper-slide {
    height: auto;
  }
`

export const SlideNewProductsBtnIcon = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
`

export const SlideNewProductsBtn = styled.button`${({ theme }) => css`
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1081;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.4rem;
  height: 5.4rem;
  color: ${({ theme }) => theme.colors.mono.white};
  background: ${({ theme }) => theme.colors.orange.default};
  border-radius: 10rem;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px ${theme.colors.orange.light10};
  }

  ${SlideNewProductsBtnIcon} {
    transform: rotate(-90deg);
  }

  &[data-js=discount-btn-next] {
    left: unset;
    right: 0;

    ${SlideNewProductsBtnIcon} {
      transform: rotate(90deg);
    }
  }

  &.swiper-button-disabled {
    background-color: transparent;
    border: 2px solid #E3E6E8;
    color: ${({ theme }) => theme.colors.mono.grey};
  }

  &:hover:not(.swiper-button-disabled) {
    background-color: ${({ theme }) => theme.colors.orange.dark};
  }

  @media ${({ theme }) => theme.screens.xl} {
    display: none;
  }`
}`
