import { useState, useRef, useEffect } from 'react'
import * as S from './styles'

const Faq = ({ title, content, productDetails }) => {
  const [isFaqActive, setIsFaqActive] = useState(false)
  const faqContentRef = useRef()

  useEffect(() => {
    if (isFaqActive) {
      faqContentRef.current.style.height = `${faqContentRef.current.scrollHeight}px`
    } else {
      faqContentRef.current.style.height = 0
    }
  }, [isFaqActive])

  const handleFaq = () => {
    setIsFaqActive(!isFaqActive)
  }

  return (
    <S.Faq isFaqActive={isFaqActive} productDetails={productDetails} onClick={handleFaq}>
      <S.FaqHeader>
        <S.FaqTitle dangerouslySetInnerHTML={{ __html: title }} />
        <S.FaqIcon type={isFaqActive ? 'minus' : 'plus'} />
      </S.FaqHeader>
      <S.FaqContent ref={faqContentRef} dangerouslySetInnerHTML={{ __html: content }} />
    </S.Faq>
  )
}

export default Faq
