import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

const ProtectedRoutes = () => {
  const { authenticated } = useAuth()

  return authenticated ? <Outlet /> : <Navigate to='/login' />
}

export default ProtectedRoutes
