import Breadcrumb from '../../components/Breadcrumb';
import { useTitle } from '../../hooks/useTitle'
import * as S from './styles'

const Institutional = () => {
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'));
  const institutionalPageName = customer?.environmentConfiguration?.ecommerceInstitutionalInformation?.pageName
  const institutionalPageContent = customer?.environmentConfiguration?.ecommerceInstitutionalInformation?.pageHtml  
  
  useTitle(institutionalPageName)
  return (
    <>
      <Breadcrumb current={institutionalPageName} />
      <S.Institutional>
        <S.InstitutionalTitle>
          {institutionalPageName}
        </S.InstitutionalTitle>
        <S.InstitutionalWrapper>
        <span className="ql-editor" dangerouslySetInnerHTML={{ __html: institutionalPageContent }} />
        </S.InstitutionalWrapper>
      </S.Institutional>
    </>

  )
}
export default Institutional