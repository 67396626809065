import { useState, useEffect } from 'react'
import { SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import * as S from './styles'
import api from '../../../services/api'
import ProductDiscountCard from '../../ProductDiscountCard'

import 'swiper/css'

const MoreProducts = ({ className }) => {
  const [moreProducts, setMoreProducts] = useState([])
  const [mostPurchased, setMostPurchased] = useState([])

  // Concatena o array de novos produtos com o array de produtos mais comprados
  const newProductsArr = [...mostPurchased, ...moreProducts]
  // Remove objetos duplicados do array, caso haja
  const formattedProductsArr = newProductsArr.filter((value, index, array) => array.findIndex(item => (item.id === value.id)) === index)

  const getMoreProducts = async () => {
    const response = await api.get('/api/v2/Product/Recommendations?Size=10')
    setMoreProducts(response.data.response.data.slice(0, 10))
  }

  const getMostPurchasedProducts = async () => {
    const response = await api.get('/api/v2/Product/Recommendations?OnlyClients=true')
    setMostPurchased(response.data.response.data.slice(0, 10))
  }

  useEffect(() => {
    getMoreProducts()
    getMostPurchasedProducts()
  }, [])

  return (
    <S.MoreProducts className={className}>
      <S.MoreProductsTitle><span>Aproveite</span> e leve também</S.MoreProductsTitle>
      <S.MoreProductsSlideContainer>
        <S.MoreProductsSwiper
          slidesPerView={1}
          spaceBetween={30}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          pagination={{
            el: '[data-js=more-products-pagination]',
            clickable: true,
            dynamicBullets: true,
          }}
          navigation={{
            prevEl: '[data-js=more-products-btn-prev]',
            nextEl: '[data-js=more-products-btn-next]',
          }}
          modules={[Pagination, Navigation]}
          className='mySwiper'
        >
          {formattedProductsArr.map((product, index) => (
            <SwiperSlide key={product.productId + '_' + index}>
              {/* <ProductCard product={product} /> */}
              <ProductDiscountCard product={product} />
            </SwiperSlide>
          ))}
        </S.MoreProductsSwiper>
        <S.MoreProductsNav>
          <S.MoreProductsBtn data-js='more-products-btn-prev'>
            <S.MoreProductsBtnIcon type='upChevron' />
            Anterior
          </S.MoreProductsBtn>
          <S.MoreProductsPagination data-js='more-products-pagination' />
          <S.MoreProductsBtn data-js='more-products-btn-next'>
            Próximo
            <S.MoreProductsBtnIcon type='upChevron' />
          </S.MoreProductsBtn>
        </S.MoreProductsNav>
      </S.MoreProductsSlideContainer>
    </S.MoreProducts>
  )
}

export default MoreProducts
