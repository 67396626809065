import * as S from './styles'
import LoadingBackground from '../LoadingBackground'
import { useCart } from '../../context/CartContext'

const MainContent = ({ children, sidebarType }) => {
  const { loadingBackground } = useCart()

  return (
    <S.MainContent sidebarType={sidebarType}>
      {loadingBackground && (
        <LoadingBackground />
      )}
      {children}
    </S.MainContent>
  )
}

export default MainContent
