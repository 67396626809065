import * as S from './styles'

const NavGroup = ({ btnIcon, data }) => {
  return (
    <S.NavGroup>
      <S.NavGroupBtn data-js={`${data}-btn-prev`}>
        <S.NavGroupBtnIcon type={btnIcon} disabled={false} />
      </S.NavGroupBtn>
      <S.NavGroupPagination data-js={`${data}-pagination`} />
      <S.NavGroupBtn data-js={`${data}-btn-next`}>
        <S.NavGroupBtnIcon type={btnIcon} />
      </S.NavGroupBtn>
    </S.NavGroup>
  )
}

export default NavGroup
