import { useRef } from 'react'

export const useDebounce = (fn, delay = 900) => {
  const timeoutRef = useRef(null)
  const debounceFn = (...args) => {
    window.clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      fn(...args)
    }, delay)
  }
  return debounceFn
}
