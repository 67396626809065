import { Routes, Route } from 'react-router-dom'
import Auth from './layouts/Auth'
import Dashboard from './layouts/Dashboard'
import Home from './pages/Home'
import Category from './pages/Category'
import Companies from './pages/Companies'
import Login from './pages/Login'
import Invite from './pages/Invite'
import ChangePassword from './pages/ChangePassword'
import Orders from './pages/Orders'
import OrdersDetail from './pages/OrdersDetail'
import Profile from './pages/Profile'
import Search from './pages/Search'
import Product from './pages/Product'
import Cart from './pages/Cart'
import CartPayment from './pages/CartPayment'
import ProtectedRoutes from './components/ProtectedRoutes'
import { setAuthToken } from './services/api'
import { useAuth } from '../src/context/AuthContext'
import { useState, useEffect, useCallback } from 'react'
import LoadingBackground from '../src/components/LoadingBackground'
import Collection from './pages/Collection'
import PageNotFound from './pages/PageNotFound'
import FilterPage from './pages/FilterPage'
import theme from './styles/theme'
import { getSubdomain } from './util/getSubdomain'
import { useTheme } from './context/ThemeContext'
import Institutional from './pages/Institutional'

const routes = () => {
  const { signInNoClient, authenticated, user } = useAuth()
  const [customerInfo, setCustomerInfo] = useState(null)
  const [loading, setLoading] = useState(true)
  const {updateTheme} = useTheme()
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'));
  
  const getCustomerInfo = useCallback(() => {
    // Busca informações da URL customizada
    const verifyURL = async () => {
      const subdomain = getSubdomain()
      await signInNoClient(subdomain).then(res => {
        setCustomerInfo(res)
        if (res && res?.ecommerceColors) {
          updateTheme(res?.ecommerceColors)
        }
        if (res && res?.portalAnonimousAccess === true && authenticated === false) {
            setAuthToken(res.tokenPortalAccess)
        } 
        setLoading(false)
      })
    }

    if (!window.location.href.indexOf('portal') > -1) {  
      verifyURL()
    }
  }, [user])

  useEffect(() => {
    getCustomerInfo();
  }, []);

  useEffect(() => {
    if (!customerInfo) {
      // Verificando informações de tema customizado quando o usuário está logado
      const themeLogged = customer?.environmentConfiguration?.ecommerceColors;
      !!themeLogged ? updateTheme(themeLogged) : updateTheme(theme)
    }
  }, [customerInfo, user]);

  if (loading) {
    return <LoadingBackground />
  }

  return (
    <Routes>
      <Route element={<Auth />}>
        {  
          !!customerInfo && customerInfo.portalAnonimousAccess === true && authenticated === false &&
          <Route path='/' element={<Dashboard />}>
            <Route index element={<Home />} />
            <Route path='category/:id/subcategory/:subId' element={<Category />} />
            <Route path='collection/:id' element={<Collection />} />
            <Route path='collection/:id/variation/:variationId' element={<Collection />} />
            <Route path='product/:id' element={<Product />} />
            <Route path='search' element={<Search />} />
            <Route path='filter' element={<FilterPage />} />
            <Route path='institutional' element={<Institutional />} />
          </Route>
        }
        <Route path='/login' element={<Login />} />
        <Route path='/change-password/:hash' element={<ChangePassword />} />
        <Route path='/invite/:hash' element={<Invite />} />
        <Route path='/companies' element={<Companies />} />
        <Route path="*" element={<PageNotFound/>} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/' element={<Dashboard />}>
          <Route index element={<Home />} />
          <Route path='category/:id/subcategory/:subId' element={<Category />} />
          <Route path='collection/:id' element={<Collection />} />
          <Route path='collection/:id/variation/:variationId' element={<Collection />} />
          <Route path='companies' element={<Companies />} />
          <Route path='login' element={<Login />} />
          <Route path='orders' element={<Orders />} />
          <Route path='orders/:id' element={<OrdersDetail />} />
          <Route path='profile' element={<Profile />} />
          <Route path='product/:id' element={<Product />} />
          <Route path='search' element={<Search />} />
          <Route path='filter' element={<FilterPage />} />
          <Route path='cart' element={<Cart />} />
          <Route path='cart-payment' element={<CartPayment />} />
          <Route path='institutional' element={<Institutional />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default routes
