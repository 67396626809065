import { useState, useEffect } from 'react'
import api from '../../../services/api'
import * as S from './styles'
import ProductCard from '../../ProductCard'

const CartEmpty = ({ children }) => {
  const [products, setProducts] = useState([])
  const getProducts = async () => {
    const response = await api.get('/api/v2/Product/Recommendations?Size=20')
    setProducts(response.data.response.data.slice(0, 20))
  }
  useEffect(() => {
    getProducts()
  }, [])

  return (
    <S.CartEmpty>
      <S.CartEmptyWrapper>
        <S.CartEmptyFigure>
          <S.CartEmptyImg src='/assets/images/cart-empty.svg' />
        </S.CartEmptyFigure>

        <S.CartEmptyText>
          {children}
        </S.CartEmptyText>

        <S.CartEmptyArrow>
          <S.CartEmptyArrowImg src='/assets/images/cart-down.svg' />
        </S.CartEmptyArrow>
      </S.CartEmptyWrapper>

      <S.CartEmptyProducts>
        <S.CartEmptyProductsTitle>
          Olha o que esta fazendo <span>sucesso também!</span>
        </S.CartEmptyProductsTitle>
        <S.CartEmptyProductsGrid>
          {products.map(product => (
            <ProductCard model='shadow' key={product.id} product={product} />
          ))}
        </S.CartEmptyProductsGrid>
      </S.CartEmptyProducts>

      {/* <S.CartEmptySlideOpportunities /> */}
    </S.CartEmpty>
  )
}

export default CartEmpty
