import { useAuth } from '../../../context/AuthContext'
import Breadcrumb from '../../Breadcrumb'
import * as S from './styles'

const OrderSuccess = () => {
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'));
  return (
    <S.OrderSuccess>
      <Breadcrumb
        current='Pedido gerado com sucesso'
      />
      <S.OrderSuccessWrapper>
        <S.OrderSuccessIllustration src='/assets/images/ilustracao.svg' alt='Ilustração pedido efetuado com sucesso' />
        <S.OrderSuccessTitle>
          Seu pedido foi gerado com sucesso!
        </S.OrderSuccessTitle>
        <S.OrderSuccessSubtitle>
          Recebemos sua solicitação e já estamos realizando o processo de separação e envio para você.
        </S.OrderSuccessSubtitle>
        <S.OrderSuccessText dangerouslySetInnerHTML={{ __html: customer.b2BInvoiceClosedMessage }} />
        <S.OrderSuccessButtonLink to='/orders'>
          <S.OrderSuccessButton>Ir para tela de Meus Pedidos</S.OrderSuccessButton>
        </S.OrderSuccessButtonLink>
      </S.OrderSuccessWrapper>
    </S.OrderSuccess>
  )
}

export default OrderSuccess
