import styled, { css } from 'styled-components/macro'
import { Button } from '../../commons/Button'
import OrderPrices from '../../components/OrderPrices'
import {
  OrderPricesTotal,
  OrderPricesValueTotal,
} from '../../components/OrderPrices/styles'

export const CartPayment = styled.div`
  margin: 8.3rem 0 24.3rem;
`

export const CartPaymentWrapper = styled.div`
  max-width: 71rem;
  width: 100%;
  margin: 0 auto;
`

export const CartPaymentTop = styled.div`
  display: flex;
  align-items: center;
`

export const CartPaymentTitle = styled.h2`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.colors.mono.black};
`

export const CartPaymentBoxContainer = styled.div``

export const CartPaymentField = styled.span`
  width: 100%;
`

export const CartPaymentLabel = styled.span`
  display: block;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -0.03rem;
`

export const CartPaymentTextarea = styled.textarea`
  width: 100%;
  height: 10.5rem;
  margin-top: 1.5rem;
  background: ${({ theme }) => theme.colors.mono.white};
  border: 0.2rem solid ${({ theme }) => theme.colors.mono.greyDark};
  border-radius: 0.6rem;

  &:focus {
    outline: 0;
  }
`

export const CartPaymentOrderPrices = styled(OrderPrices)`
  padding: 0;
  background-color: transparent;
  border-radius: 0;

  ${OrderPricesTotal} {
    align-items: flex-start;
  }

  ${OrderPricesValueTotal} {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const CartPaymentDeliveryItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.8rem 0;
`

export const CartPaymentDeliveryContainer = styled.div`
  width: 100%;
  ${CartPaymentDeliveryItem} {
    &:not(:last-of-type) {
      border-bottom: 1.5px solid ${({ theme }) => theme.colors.mono.grey};
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`

export const CartPaymentDeliveryLabel = styled.span`
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const CartPaymentDeliveryValue = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  color: ${({ theme }) => theme.colors.mono.black};
`

export const CartPaymentBox = styled.div`
  margin-top: 2rem;
  padding: 3rem;
  background: ${({ theme }) => theme.colors.mono.greyLight};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const CartPaymentDisclaimer = styled.p`
  ${({ theme }) => css`
    margin-top: 2rem;
    padding: 2rem 3.5rem;
    border: 0.2rem solid ${theme.colors.orange.default};
    border-radius: 0 0.8rem 0.8rem 3.2rem;

    strong {
      color: ${theme.colors.orange.default};
    }
  `}
`

export const CartPaymentButton = styled(Button)`
  width: 100%;
  margin-top: 2rem;
`
export const SelectPayment = styled.div`
  flex: 1;
`
export const CartPaymentBold = styled.span`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.mono.black};
  margin-right: 1.5rem;
`
