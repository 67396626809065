import styled, { css } from 'styled-components/macro'
import Icon from '../../commons/Icon'

export const Select = styled.div`
  position: relative;
`

export const SelectInput = styled.select`${({ theme }) => css`
  width: 100%;
  padding: 1.1rem 4.5rem 1.1rem 1.5rem;
  appearance: none;
  background: ${theme.colors.mono.white};
  border: 2px solid ${theme.colors.mono.greyDark};
  border-radius: 5px;
`}`

export const SelectIcon = styled(Icon)`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  width: 1.6rem;
  height: 1.6rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
  pointer-events: none;
`
