import styled from 'styled-components/macro'

export const IncrementStepper = styled.div`
  display: inline-flex;
  align-items: center;
  width: max-content;
  margin-bottom: 1.5rem;
  align-self: center;
  gap: 0.5rem;
  ${({ $isProductCard }) => $isProductCard && `gap: 1rem;`}
  ${({ $isProductCard, $styleQtd }) => $isProductCard && $styleQtd === 3 && `margin-right: 1rem;`}
`

export const IncrementStepperInput = styled.input`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.colors.mono.black};
  border: none;
  max-width: 60px;
  height: 3.2rem;
  border-radius: 32px;
  transition: all 0.3s ease;
  ${({ $isProductCard, theme }) => $isProductCard && `
    color: ${theme.colors.mono.black};
    background-color: ${theme.colors.mono.white};
    max-width: 12rem;
    &:focus {
      outline: none;
    }

  `}

  @media ${({ theme }) => theme.screens.xl} {
    ${({ $styleQtd }) => $styleQtd === 3 && `
      max-width: 6rem;
    `}
  }

  @media ${({ theme }) => theme.screens.sm} {
    max-width: 6rem;
  }
`

export const IncrementStepperValue = styled.input`
  width: 4.6rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  display: none;
  ${({ $isProductCard, theme }) => $isProductCard && `color: ${theme.colors.mono.black};`}
`

export const IncrementStepperBtn = styled.button`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  background-color: ${({ theme }) => theme.colors.mono.grey};
  border-radius: 50%;
  ${({ $isProductCard, theme }) => $isProductCard && `color: ${theme.colors.mono.black};`}
`
