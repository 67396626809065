import * as S from './styles'
import formatMoney from '../../util/formatMoney'

const OrderPrices = ({ className, value, tax, freight, discount, totalValue, addition }) => {
  return (
    <S.OrderPrices className={className}>
      <S.OrderPricesItem>
        <S.OrderPricesLabel>Subtotal</S.OrderPricesLabel>
        <S.OrderPricesValue>{formatMoney(value)}</S.OrderPricesValue>
      </S.OrderPricesItem>
      <S.OrderPricesItem>
        <S.OrderPricesLabel>Desconto</S.OrderPricesLabel>
        <S.OrderPricesValue>{formatMoney(discount)}</S.OrderPricesValue>
      </S.OrderPricesItem>
      <S.OrderPricesItem>
        <S.OrderPricesLabel>Impostos</S.OrderPricesLabel>
        <S.OrderPricesValue>{formatMoney(tax)}</S.OrderPricesValue>
      </S.OrderPricesItem>
      <S.OrderPricesItem>
        <S.OrderPricesLabel>Frete</S.OrderPricesLabel>
        <S.OrderPricesValue>{formatMoney(freight)}</S.OrderPricesValue>
      </S.OrderPricesItem>
      <S.OrderPricesItem>
        <S.OrderPricesLabel>Adição</S.OrderPricesLabel>
        <S.OrderPricesValue>{formatMoney(addition)}</S.OrderPricesValue>
      </S.OrderPricesItem>
      <S.OrderPricesTotal>
        <S.OrderPricesLabelTotal>Total Geral</S.OrderPricesLabelTotal>
        <S.OrderPricesValueTotal>{formatMoney(totalValue)}</S.OrderPricesValueTotal>
      </S.OrderPricesTotal>
    </S.OrderPrices>
  )
}

export default OrderPrices
