const theme = {
  colors: {
    mono: {
      white: '#FFF',
      greyLight: '#F4F5F6',
      grey: '#E3E6E8',
      greyDark: '#D5D8DD',
      greyDarker: '#8F99A3',
      black: '#434647',
      blackDark: '#282828',
    },
    orange: {
      default: '#FF6300',
      dark: '#CC4F00',
      light10: '#FFEBE0',
    },
    yellow: {
      default: '#FED500',
      light10: '#FFFBE6',
    },
    red: {
      default: '#DC1212',
      light10: '#FEF2F2',
    },
    green: {
      default: '#73AA13',
      light10: '#F1F6E7',
    },
  },
  shadows: {
    orange: '0px 70px 80px rgba(255, 99, 0, 0.12), 0px 30px 30px rgba(208, 81, 0, 0.08), 0px 20px 30px rgba(157, 61, 0, 0.2), 0px 0px 20px rgba(71, 69, 52, 0.05), 0px 0px 20px rgba(71, 69, 52, 0.05), 0px 0px 40px rgba(71, 69, 52, 0.05)',
    dark: '0px 48px 70px rgba(0, 0, 0, 0.15), 0px 15px 30px rgba(0, 0, 0, 0.08), 0px 15px 15px rgba(0, 0, 0, 0.05), 0px 0px 15px rgba(0, 0, 0, 0.05), 0px 0px 5px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.0196802)',
    smooth: '0px 10px 30px rgba(0, 0, 0, 0.08), 0px 9px 9px rgba(0, 0, 0, 0.04), 0px 5px 5px rgba(0, 0, 0, 0.03), 0px 2px 2px rgba(0, 0, 0, 0.0196802)',
    card: '0px 3px 15px #EAECF1',
  },
  screens: {
    '3xl': '(max-width: 1750px)',
    '2xl': '(max-width: 1536px)',
    xl: '(max-width: 1399.98px)',
    lg: '(max-width: 1199.98px)',
    md: '(max-width: 991.98px)',
    sm: '(max-width: 767.98px)',
    xs: '(max-width: 575.98px)',
  },
}

export default theme
