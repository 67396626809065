import styled from 'styled-components/macro'
import { Swiper } from 'swiper/react'

export const BannerSlide = styled.div``

export const BannerSlideSwiper = styled(Swiper)`
  padding: 1.5rem;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
`
