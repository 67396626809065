import styled, { css } from 'styled-components/macro'
import { Button } from '../../commons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin: 1rem;
`

export const FilterWrapper = styled.div`
  margin-left: 0.2rem;
`

export const FilterTitle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  margin-bottom: 0;
  padding: 0rem 2.85rem;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.1rem;
  text-align: center;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  /* Mono/Grey Darker */
  color: #8f99a3;
  background-color: #fff;
  border-radius: 100px;
  transition: 300ms ease-out;
  
  ${({theme, $isHorizontalMenu, $changeHeaderColor}) => $isHorizontalMenu && $changeHeaderColor && `background-color: ${theme.colors.mono.white};`}
  ${({theme, $isHorizontalMenu, $changeHeaderColor}) => $isHorizontalMenu && !$changeHeaderColor && `background-color: ${theme.colors.orange.light10}; border-radius: 0;`}
  ${({ theme, $isHorizontalMenu }) => 
    $isHorizontalMenu &&
    css`
      margin: 0;
      height: 100%;
      color: ${theme.colors.orange.default};
      padding: 0rem 1rem;
      
    `
  }

  @media screen and (min-width: 1200px) {
   width: 100%;
  }
`

export const FilterContent = styled.div`
  width: 100%;
  max-width: 28.75rem;
  display: flex;
  flex-direction: column;
  height: 0;
  transition: height 200ms ease-out, transform 200ms ease-out;
  padding: 2rem 0.5rem;
  background: #f4f5f6;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
   transform: scaleY(0);
   transform-origin: top;
  ${({ isOpen }) =>
    isOpen &&
    css` 
      height: auto;
      gap: 2rem;
      transform: scaleY(1);
    `
  }

  ${({ theme, $isHorizontalMenu }) => 
    $isHorizontalMenu &&
    css`
      position: absolute;
      max-width: 36rem;
      padding: 2rem 1.5rem;
      border-radius: 0 0 4px 4px;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
      background-color: ${({ theme }) => theme.colors.mono.white};
      z-index: 10;
    `
  }
`

export const FilterSelectWrapper = styled.div`
  flex: 1;
`

export const FilterSelectLabel = styled.label`
  font-size: 1.6rem;
  line-height: 1.4rem;
  color: #8f99a3;
  text-align: center;
  margin-bottom: 2rem;
  @media ${({ theme }) => theme.screens.lg} {
    color: ${({ theme }) => theme.colors.mono.white};
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`

export const FilterButton = styled.button`
  width: 100%;
  ${({ $isHorizontalMenu }) => $isHorizontalMenu && `height: 100%; background-color: white; border-radius: 0;`}
  ${({ $isHorizontalMenu, $changeHeaderColor }) => $isHorizontalMenu && $changeHeaderColor &&`height: 100%; background-color: white; border-radius: 4px 4px 0 0;`}
`

export const FilterApplyButton = styled(Button)`
  width: 100%;
  padding: 0.5rem;

  @media ${({ theme }) => theme.screens.lg} {
    background-color: ${({ theme }) => theme.colors.mono.white};
    color: ${({ theme }) => theme.colors.orange.default};
    margin-top: 2rem;
    width: 50%;
    align-self: center;
    &:active,&:hover {
      background-color: ${({ theme }) => theme.colors.mono.white};
      color: ${({ theme }) => theme.colors.orange.default};
    }
  }
`

/*---------------------------MOBILE---------------------------*/
export const FilterMobile = styled.aside`
  display: none;
  width: 100%;
  margin-top: 3rem;

  @media ${({ theme }) => theme.screens.lg} {
    display: block;
  }
`

export const ScrollableContentMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  overflow-y: auto;
  gap: 1rem;
`;

export const FilterContentMobile = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 200;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.orange.default};
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease-out;
  max-height: 70vh;
  height: 0;
  margin-top: 1.5rem;
  padding: 2rem 0; /* Remove side padding from here */
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      height: auto;
      pointer-events: initial;
    `}

  @media ${({ theme }) => theme.screens.xs} {
    max-height: 55vh;
  }
`;

export const FilterButtonMobile = styled(Button)`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border: 0.15rem solid transparent;
  background: ${({ theme }) => theme.colors.mono.greyLight};
  color: ${({ theme }) => theme.colors.orange.default};
  padding: 0;
  ${({ isOpen }) => css`
    ${isOpen &&
    css`
      border: 0.15rem solid ${({ theme }) => theme.colors.orange.default};
      border-radius: 5px;
      background: white;
      color: ${({ theme }) => theme.colors.orange.default};
    `}
  `}

  &:active,&:hover {
    border: 0.15rem solid ${({ theme }) => theme.colors.orange.default};
    border-radius: 5px;
    background: white;
    color: ${({ theme }) => theme.colors.orange.default};
  }
`