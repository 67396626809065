import * as S from './styles'

const BackButton = ({ label, to }) => {
  return (
    <S.BackButton to={to}>
      <S.BackButtonIconContainer>
        <S.BackButtonIcon type='upChevron' />
      </S.BackButtonIconContainer>
      {label && (
        <S.BackButtonLabel>Voltar para listagem</S.BackButtonLabel>
      )}
    </S.BackButton>
  )
}
 
export default BackButton
