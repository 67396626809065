import styled from 'styled-components/macro'

export const FaqContent = styled.div``

export const FaqContentTitle = styled.h2`
  font-weight: 400;
  font-size: 6.4rem;
  line-height: 6.6rem;
  letter-spacing: -3px;
  text-align: center;
  color: ${({ theme }) => theme.colors.mono.grey};

  @media ${({ theme }) => theme.screens.md} {
    font-size: 4.8rem;
    line-height: 5rem;
    letter-spacing: -2px;
  }
`

export const FaqContentWrapper = styled.div`
  margin-top: 2rem;

  @media ${({ theme }) => theme.screens.md} {
    margin-top: 3rem;
  }
`
