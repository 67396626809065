import styled from 'styled-components'

export const Tag = styled.span`
  padding: .65rem 2rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.black};
  background-color: ${({ theme }) => theme.colors.mono.greyLight};
  border-radius: 10rem;
`
