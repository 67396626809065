import styled, { css } from 'styled-components/macro'

export const Notifications = styled.ul`
  ${({ theme }) => css`
    z-index: 10000;
    width: 27.9rem;
    padding: .5rem 1.5rem;
    background: ${theme.colors.mono.black};
    border-radius: .4rem;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -100%);
      border-left: .5rem solid transparent;
      border-right: .5rem solid transparent;
      border-bottom: .5rem solid ${theme.colors.mono.black};
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 90%;
      height: 1rem;
      background-color: ${theme.colors.mono.black};
    }
  `}
`

export const NotificationIcon = styled.span`
  flex-shrink: 0;
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
  opacity: 0.5;
`

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const NotificationText = styled.p`
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const Notification = styled.li`${({ isNew, theme }) => css`
  position: relative;
  padding: 1.5rem 0;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - 3rem);
    height: .15rem;
    background-color: ${({ theme }) => theme.colors.mono.greyDarker};
    opacity: .2;
  }

  ${isNew && css`
    ${NotificationIcon} {
      color: ${theme.colors.orange.default};
      opacity: 1;
    }

    ${NotificationText} {
      font-weight: 700;
      color: white;
    }
  `}
`}`
