import * as S from './styles'

export const ProductCartQuantity = ({ qtd, moreProductsCarrossel }) => {
  return (
    <S.ProductCartQuantityWrapper $moreProductsCarrossel={moreProductsCarrossel}>
      <S.ProductCartQuantityIcon type='cart' />
      {qtd > 0 && (
        <S.ProductCartQuantityQtd>{qtd}</S.ProductCartQuantityQtd>
      )}
    </S.ProductCartQuantityWrapper>
  )
}