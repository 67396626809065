import * as S from './styles'
import Container from '../../Container'

const MiniBar = ({ message }) => {
  return (
    <S.MiniBar>
      <Container>
        <S.Text dangerouslySetInnerHTML={{ __html: message }} />
      </Container>
    </S.MiniBar>
  )
}

export default MiniBar
