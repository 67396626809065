import React from 'react'
import ReactPaginate from 'react-paginate'

// import { Container } from './styles';
import * as S from '../../components/Product/MoreProducts/styles'

function Paginator ({
  handlePageClick,
  totalPages,
  totalDisplayed = 3,
  currentPage,
}) {
  return (
    <ReactPaginate
      forcePage={currentPage - 1}
      containerClassName='paginator-cnt'
      pageClassName='page-number'
      breakLabel='...'
      nextLabel={
        <S.MoreProductsBtn>
          {/* Próximo */}
          <S.MoreProductsBtnIcon type='rightChevron' />
        </S.MoreProductsBtn>
      }
      onPageChange={handlePageClick}
      pageRangeDisplayed={totalDisplayed}
      pageCount={totalPages}
      previousLabel={
        <S.MoreProductsBtn>
          <S.MoreProductsBtnIcon type='leftChevron' />
          {/* Anterior */}
        </S.MoreProductsBtn>
      }
      renderOnZeroPageCount={null}
    />
  )
}

export default Paginator
