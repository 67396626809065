import theme from '../styles/theme'

export const themeForSearchableSelect = () => {
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'));
  const customerColors = customer.environmentConfiguration.ecommerceColors;

  const tmpTheme = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? (customerColors?.defaultColor || theme.colors.orange.default) : theme.colors.mono.grey,
      boxShadow: state.isFocused ? (customerColors?.defaultColor || theme.colors.orange.default) : 'none',
      overflow: 'visibsl',
      marginTop: '0.5rem',
      '&:hover': {
        borderColor: (customerColors?.defaultColor || theme.colors.orange.default),
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      color: '#333',
      '&:hover': {
        backgroundColor: state.isFocused ? (customerColors?.light || theme.colors.orange.light10) : '#f8f8f8',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#f8f8f8',
      color: '#333',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '160px', // Ajuste a altura máxima conforme necessário
      overflowY: 'auto', // Adiciona rolagem se o conteúdo exceder a altura máxima
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#555',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: (customerColors?.light || theme.colors.orange.light10),
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  }
  return tmpTheme
}