export default function formatMoney (value = 0, withCurrency = true) {
  if (!value) {
    value = 0
  }
  if (withCurrency) {
    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  } else {
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
  }
}
