import styled, { css } from 'styled-components/macro'

export const CustomerLine = styled.ul`
${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    flex-wrap: wrap;

    @media ${({ theme }) => theme.screens.md} {
        position: relative;
    }
  `}
`

export const ProductInfo = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.2rem;

  &:not(:last-of-type) {
    margin-right: 2rem;

    &::after {
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.orange.default};
      margin-left: 2rem;
    }
  }
`

export const ProductInfoLabel = styled.span`
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const ProductInfoValue = styled(ProductInfoLabel)`
  ${({ theme, model }) => css`
    margin-left: 0.7rem;
    font-weight: ${model === 'small' ? 400 : 700};
    color: ${model === 'small' ? theme.colors.mono.greyDarker : theme.colors.orange.default};
  `}
`
