import * as S from './styles'
import React from 'react'
import { IMAGE_URL } from '../../constants'
import { makeBannerLink } from '../../util/makeBannerLink'

const BannerEndPage = ({ banner, linkType, linkId }) => {
  const url = makeBannerLink(linkType, linkId)
  return (
    <S.BannerEndPage href={url}>
      <S.BannerEndPageImage src={`${IMAGE_URL}${banner}`} alt='' />
    </S.BannerEndPage>
  )
}

export default BannerEndPage
