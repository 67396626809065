import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Icon from '../../commons/Icon'

export const Breadcrumb = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-radius: 0.05rem;
    background-color: ${({ theme }) => theme.colors.mono.greyLight};
  }

  @media ${({ theme }) => theme.screens.lg} {
    display: none;
  }
`

export const BreadcrumbItem = styled.li`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  &:not(:last-of-type)::after {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    background: url('/assets/images/icon-breadcrumb.svg') center / cover no-repeat;
  }
`

export const BreadcrumbHome = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.mono.greyDark};

  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const BreadcrumbIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
`

export const BreadcrumbParent = styled.div`
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const BreadcrumbParentLink = styled(Link)`
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.orange.default};

  &:hover {
    color: ${({ theme }) => theme.colors.mono.black};
  }
`

export const BreadcrumbActive = styled.div`
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`
