import React from 'react'
import * as S from './styles'
import theme from '../../styles/theme'

const Loading = ({ color = theme.colors.orange.default, size = 30, className }) => {
  return (
    <S.LoadingWrapper size={size} color={color} className={className}>
      <div />
      <div />
      <div />
      <div />
    </S.LoadingWrapper>
  )
}

export default Loading
