import { useState, useEffect, useLayoutEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { useTitle } from '../../hooks/useTitle'
import { toast } from 'react-toastify'
import api from '../../services/api'
import * as S from './styles'
import { useAuth } from '../../context/AuthContext'
import { IMAGE_URL } from '../../constants'
import LoadingBackground from '../../components/LoadingBackground'

const Companies = () => {
  const { user, selectCompany, authenticated } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isSkipping, setIsSkipping] = useState(false)
  const [companies, setCompanies] = useState([])
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'));

  const navigate = useNavigate()

  const getCompanies = async () => {
    const response = await api.get(`/api/v2/Buyer/${user.buyerId}/Customers`)
    setCompanies(response.data.response.data)
  }

  const handleSelectCompany = async (company, customerBuyerId) => {
    setIsLoading(true)
    const id = toast.loading('Carregando dados da empresa...')
    const companyResponse = await selectCompany(company, customerBuyerId)
    toast.update(id, {
      render: companyResponse.success ? companyResponse.successMessages[0] : companyResponse.errorMessages[0],
      type: companyResponse.success ? 'success' : 'error',
      isLoading: false,
      delay: 750,
      autoClose: companyResponse.success ? 2000 : 5000,
    })
    if (companyResponse.success) {
      setTimeout(() => {
        setIsSkipping(false)
        setIsLoading(false)
        navigate('/')
      }, 2000)
    }
  }

  const skipCompaniesPage = async () => {
    if (!!customerInfo) {
      const subdomainCompanies = companies.filter(comp => comp.id === customerInfo.customerId)
      if (subdomainCompanies.length === 1) {
        setIsSkipping(true)
        handleSelectCompany(subdomainCompanies[0].id, subdomainCompanies[0].customerBuyerId)
      }
    }
  }

  useLayoutEffect(() => {
    getCompanies()
  }, [])

  useEffect(() => {
    skipCompaniesPage()
  }, [companies])

  useTitle('Empresas')

  return (!authenticated ? <Navigate to='/login' /> : 
    isSkipping ? <LoadingBackground /> :
      <S.Companies>
        <S.CompaniesWrapper>
          <S.CompaniesLogo src='/assets/images/logo-bendito.svg' />
          <S.CompaniesText>
            Selecione a empresa que deseja acessar o portal de compras B2B:
          </S.CompaniesText>
          <S.CompaniesBox>
            {companies.map(company => (
              <S.Company key={company.customerBuyerId}>
                <S.CompanyLogoWrapper>
                  <S.CompanyLogo src={company.image ? `${IMAGE_URL}${company.image}` : '/logo.png'} alt={company.companyName} />
                </S.CompanyLogoWrapper>
                <S.CompanyContent>
                  <S.CompanyName>{company.companyName}</S.CompanyName>
                  <S.ClientName>{company.clientName}</S.ClientName>
                  <S.CompanySocialName>{company.socialName}</S.CompanySocialName>
                  <S.ClientCnpj>{company.cnpj}</S.ClientCnpj>
                </S.CompanyContent>
                <S.CompanyButton onClick={() => handleSelectCompany(company.id, company.customerBuyerId)} disabled={isLoading}>
                  <S.CompanyIcon type='upChevron' />
                </S.CompanyButton>
              </S.Company>
            ))}
          </S.CompaniesBox>
        </S.CompaniesWrapper>
      </S.Companies>
  )
}

export default Companies
