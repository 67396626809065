import { createContext, useContext, useEffect, useState } from 'react'
import api from '../services/api'
import { useDebounce } from './../hooks/useDebounce'
import { useNavigate } from 'react-router-dom'
import { objectToQueryString } from '../util/objToQueryString'

const SearchContext = createContext()

export const SearchProvider = (props) => {
  const [loadingBackground, setLoadingBackground] = useState(false)
  const [search, setSearch] = useState()
  const [filter, setFilter] = useState()
  const [products, setProducts] = useState('')
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [orderFilter, setOrderFilter] = useState(0)
  const [usingFilter, setUsingFilter] = useState(false)
  const [isSidebarModal, setIsSidebarModal] = useState(false)

  const handleSearch = (value) => {
    if (!!value) {
      setSearch(value)
    }
  }
  const requestSearch = async () => {
    navigate('/search')
    setLoadingBackground(true)
    
    const response = await api.get(
      `/api/v2/Product/SearchBar?Order=${orderFilter}&Page=1&Size=20&OnlyB2B=true&Description=${search}`,
    )
    setProducts(response.data.response.data)
    setTotalPages(response.data.response.totalPages)
    setCurrentPage(1)
    setLoadingBackground(false)
  }

  const handleFilter = (value) => {
    setFilter(value)
  }

  const requestFilter = async () => {
    if (filter) {
      const filterquery = objectToQueryString(filter)
      if (!isSidebarModal) {
        navigate('/filter')
      }
      setLoadingBackground(true)
      const response = await api.get(`api/v2/Product/FilterBar?${filterquery}`)
      setProducts(response.data.response.data)
      setTotalPages(response.data.response.totalPages)
      setCurrentPage(1)
      setLoadingBackground(false)
      setUsingFilter(false)
    }
  }

  const debouncedChange = useDebounce(requestSearch)
  const debouncedFilter = useDebounce(requestFilter)
  useEffect(() => {
    if (search && !usingFilter) {
      debouncedChange(requestSearch)
    }
  }, [search])

  useEffect(() => {
    if (usingFilter) {
      debouncedFilter(requestFilter)
    }
  }, [usingFilter])

  return (
    <SearchContext.Provider
      value={{
        search,
        products,
        setProducts,
        loadingBackground,
        handleSearch,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        orderFilter,
        setOrderFilter,
        setUsingFilter,
        filter,
        setFilter,
        handleFilter,
        setIsSidebarModal,
        usingFilter,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  )
}

export function useSearch () {
  return useContext(SearchContext)
}

export default SearchContext
