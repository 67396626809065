import styled, { keyframes, css } from 'styled-components'

const ring = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const LoadingWrapper = styled.div`${({ size, color }) => css`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${size - (((size * 10) / 100) * 2)}px;
    height: ${size - (((size * 10) / 100) * 2)}px;
    margin: ${(size * 10) / 100}px;
    border: ${(size * 10) / 100}px solid ${color};
    border-radius: 50%;
    animation: ${ring} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${color} transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`}`
