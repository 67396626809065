import * as S from './styles'

const Breadcrumb = ({ current, parent, parentLink, currentSubcategory }) => {
  return (
    <S.Breadcrumb>
      <S.BreadcrumbItem>
        <S.BreadcrumbHome to='/'>
          <S.BreadcrumbIcon type='home' />
        </S.BreadcrumbHome>
      </S.BreadcrumbItem>
      {parent && (
        <S.BreadcrumbItem>
          <S.BreadcrumbParent>
            {parent}
          </S.BreadcrumbParent>
        </S.BreadcrumbItem>
      )}
      {parentLink && (
        <S.BreadcrumbItem>
          <S.BreadcrumbParentLink to={parentLink.url}>
            {parentLink.label}
          </S.BreadcrumbParentLink>
        </S.BreadcrumbItem>
      )}
      <S.BreadcrumbItem>
        <S.BreadcrumbActive>
          {current}
        </S.BreadcrumbActive>
      </S.BreadcrumbItem>
      {currentSubcategory !== ''
        ? <S.BreadcrumbItem><S.BreadcrumbActive>{currentSubcategory}</S.BreadcrumbActive></S.BreadcrumbItem>
        : null}
    </S.Breadcrumb>
  )
}

export default Breadcrumb
