import styled, { css } from 'styled-components/macro'
import { Button } from '../../commons/Button'
import Icon from '../../commons/Icon'

export const Companies = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;
  padding: 5rem 0;
`

export const CompaniesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 76rem;
  width: 100%;
`

export const CompaniesLogo = styled.img`
  max-width: 24.5rem;
  width: 100%;
`

export const CompaniesText = styled.p`
  max-width: 36.7rem;
  margin-top: 3rem;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  letter-spacing: -.03rem;
`

export const CompaniesBox = styled.div`${({ theme }) => css`
  margin-top: 3rem;
  padding: 3rem 6rem;
  background: ${theme.colors.mono.white};
  box-shadow: ${theme.shadows.card};
  border-radius: .8rem;

  ${Company} {
    &:not(:last-of-type) {
      border-bottom: .1rem solid ${({ theme }) => theme.colors.mono.grey};
    }
  }
`}`

export const Company = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0;
  margin-right: 0;
`

export const CompanyLogoWrapper = styled.figure`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
  border-radius: .5rem;
  overflow: hidden;
`

export const CompanyLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const CompanyContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`

export const CompanyName = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -.02rem;
`

export const CompanySocialName = styled.span`
  margin-top: .5rem;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.orange.default};
`
export const ClientName = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -.02rem;
`

export const ClientCnpj = styled.span`
  margin-top: .5rem;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const CompanyButton = styled(Button)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
`

export const CompanyIcon = styled(Icon)`
  width: 2.222rem;
  height: 2.222rem;
  transform: rotate(90deg);
`
