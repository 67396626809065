import * as S from './styles'
import { useState, useEffect, useCallback } from 'react'
import {  faChevronDown, faChevronUp, faFilter } from '@fortawesome/free-solid-svg-icons'
import SearchableSelect from 'react-select'
import { themeForSearchableSelect } from '../../util/themeForSearchableSelect'
import api from '../../services/api'
import { makeOptionsForSelect } from '../../util/makeOptionsReactSelect'
import { useSearch } from '../../context/SearchContext'
import { useOnOutsideClick } from '../../hooks/useOnOutsideClick'

const Filter = ({ category, isSidebarModal, isMobile, isHorizontalMenu, closeOtherMenuHoritontalOptions, changeHeaderColor }) => {
  const [customer, setCustomer] = useState()
  const [isOpen, setIsOpen] = useState(isHorizontalMenu ? false : true)
  const [characteristics, setCharacteristics] = useState([])
  const [characteristicsFilter, setCharacteristicsFilter] = useState()
  const [customFields, setCustomFields] = useState([])
  const [customFieldsValues, setCustomFieldsValues] = useState()
  const [customFieldsFilter, setCustomFieldsFilter] = useState()
  const [ecommerceFilterBar, setEcommerceFilterBar] = useState()
  const { orderFilter, setUsingFilter, handleFilter, setIsSidebarModal } = useSearch()
  const { innerBorderRef } = useOnOutsideClick(() => setIsOpen(false))
  const [tmpCharacteristicsBody, setTmpCharacteristicsBody] = useState([]);
  const [tmpCustomFieldBody, setTmpCustomFieldBody] = useState([]);

  const [subcategory, setSubcategory] = useState([]);
  const [disableSubcategorySelect, setDisableSubcategorySelect] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  // var tipocustomfield = {
  //   1: 'Texto curto',
  //   2: 'Texto longo',
  //   3: 'Numérico',
  //   4: 'Escolha única',
  //   5: 'Múltiplas escolhas',
  //   6: 'Moeda',
  // };

  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  const getCharacteristics = useCallback(async () => {
    const response = await api.get('/api/v2/Characteristic')
    setCharacteristics(response?.data?.response?.data)
  }, [characteristics])

  const getCustomFields = useCallback(async () => {
    try {
      const response = await api.get('api/v2/CustomField?OrderByOrder=false&Entity=2&page=1&size=500')
      const customFieldsResponse = response?.data?.response?.data
      setCustomFields(customFieldsResponse)

      let tmpCustomFieldValue = [];
      for (const field of customFieldsResponse) {
        const fieldId = +field.id;
        // Chama o customFieldValues somente quando a entity do campo customizado for Produto
        if (field.entity === 2) {
          const result = await api.get(`api/v2/CustomFieldValue?CustomFieldId=${fieldId}`);
          const data = result?.data?.response?.data;
  
          if (result && result.status === 200) {
            const fieldValueData = data.filter(item => item.customFieldId === field.id);
            if (!!fieldValueData) {
              tmpCustomFieldValue.push(fieldValueData) 
            };
          }
        }
      }

      const fieldWithValue = concatCustomFieldAndValue(tmpCustomFieldValue);
      setCustomFieldsValues(fieldWithValue)
    } catch (error) {
      console.log('error', error);
    }
  }, [customer, customFields, customFieldsValues])

  const concatCustomFieldAndValue = (data) => {
    // Recebe o objeto do CustomFieldValue
    if (!data || data.length === 0) {
      return [];
    }
    const result = {};
    data.flat().forEach(item => {
      // Transforma em um único array e extrai do item somente as propriedades necessárias
      const { id, customFieldId, values } = item;
      // Verifica se já foi criado um objeto com customFieldId dentro do result, se não, cria o objeto com as propriedades descritas
      if (!result[customFieldId]) {
        result[customFieldId] = { customFieldId, values: [] };
      }
      // Adiciona todos os elementos do array values do item atual ao array values dentro do result
      values.forEach(value => {
        result[customFieldId].values.push({ id, value });
      });
    });
    return Object.values(result);
  };

  const transformObjToOptions = (data, id, entity) => {
    // Recebe o objeto das Características ou Campos customizados
    const obj = data?.find(item => (item.customFieldId || item.id) === id);
    if (!obj) return [];
    // Retorna o objeto no formato aceito pelo ReactSelect
    const makeEachpOtion = obj.values
    .filter(value => value.value !== "")
    .map(value => ({
      value: value.id.toString(),
      label: value.value || value.description,
    }));

    if (entity === 1) {
      // Transformando o objeto de Campo Customizado para opções
      // Agrupa os valores pela label (descrição)
      const groupedByLabel = makeEachpOtion.reduce((acc, item) => {
        // Se o label ainda não existir no objeto acumulador, inicializa com um array vazio
        const lowercaseLabel = item.label.toLowerCase();
        // Verificando se já existe um grupo com a mesma label ignorando maiúsculas e minúsculas
        const existingGroup = acc.find(group => group.label.toLowerCase() === lowercaseLabel);
        if (existingGroup) {
          // Adicionando apenas o valor se já existe um grupo com a label
          existingGroup.value.push(item.value);
        } else {
          // Criando um novo grupo se não existe um grupo com a label
          acc.push({
            label: item.label,
            value: [item.value]
          });
        }
    
        return acc;
      }, []);
    
      // Convertendo o array de grupos em formato aceito pelo React Select
      const transformedArray = groupedByLabel.map(group => ({
        label: group.label,
        value: group.value
      }));

      return transformedArray
    }
    return makeEachpOtion
  }

  const handleSelectChange = (selectedOption, id, entity) => {
    if (entity === 1) {
      // É uma alteração em select de Campo Customizado
      setTmpCustomFieldBody((prevState) => ({
        ...prevState,
        [id]: selectedOption ? selectedOption.value : ''
      }));
    } else if (entity === 2) {
      // É uma alteração em select de Característica
      setTmpCharacteristicsBody((prevState) => ({
        ...prevState,
        [id]: selectedOption ? selectedOption.value : ''
      }));
    }
  };

  const handleChangeCategorySubcategory = (selectedOption, entity) => {
    if (entity === 3) {
      // É uma alteração em select de Categoria
      const findCategory = selectedOption ? category.find(item => item.id === selectedOption.value) : null
      if (!!selectedOption && !!findCategory) {
        setDisableSubcategorySelect(false)
        setSubcategory(findCategory.subcategory)
        setSelectedCategory(selectedOption.value)
      } else {
        setDisableSubcategorySelect(true)
        setSubcategory([])
        setSelectedSubcategory(null)
        setSelectedCategory(null)
      }
    } else if (entity === 4) {
      // É uma alteração em select de Subcategoria
      setSelectedSubcategory(selectedOption)
    }
  };

  const handleButtonClick = async () => {
    // Filtro para tirar string vazia do array
    const filterValues = (items) => {
      return Object.values(items).filter(value => value.trim() !== '')
    }

    // Os objetos chegam aqui com o id do campo/char seguido dos ids dos valores (Ex: {57: [111, 222, 333]}). Abaixo capturamos apenas os ids dos valores
    const formattedChar = filterValues(Object.values(tmpCharacteristicsBody))
    const formattedCustomField = filterValues(Object.values(tmpCustomFieldBody).flat())

    const body = {
      order: orderFilter,
      page: '1',
      size: '20',
      onlyB2B: true,
      categoriesIds: selectedCategory ? [selectedCategory] : [], 
      subCategoriesIds: selectedSubcategory ? [selectedSubcategory.value] : [],
      characteristicsValueIds: tmpCharacteristicsBody && formattedChar ? formattedChar : [],
      customFieldValuesIds: tmpCustomFieldBody&& formattedCustomField ? formattedCustomField : [],
    }

    try {
      handleFilter(body)
      setUsingFilter(true)
      isSidebarModal ? setIsSidebarModal(true) : setIsSidebarModal(false)
      if (isMobile || isHorizontalMenu) {
        setIsOpen(false)
      }
      window.scrollTo({ top: 0 })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const mountSelectsJSX = () => {
    return (
      <>
        {/* Categorias */}
        {!!ecommerceFilterBar?.filterBarCategories &&
          <S.FilterSelectWrapper>
            <S.FilterSelectLabel>Categoria</S.FilterSelectLabel>
            <SearchableSelect
              noOptionsMessage={() => 'Sem resultados.'}
              defaultValue=''
              styles={themeForSearchableSelect()}
              options={makeOptionsForSelect(category)}
              placeholder='Selecione...'
              isClearable              
              onChange={(selectedOption) => handleChangeCategorySubcategory(selectedOption, 3)}
            />
          </S.FilterSelectWrapper>
        }

        {/* Subcategorias */}
        {!!ecommerceFilterBar?.filterBarSubcategories &&
          <S.FilterSelectWrapper>
            <S.FilterSelectLabel>Subcategoria</S.FilterSelectLabel>
            <SearchableSelect
              id='subcategoryField'
              // ref={selectRef}
              noOptionsMessage={() => 'Sem resultados.'}
              defaultValue=''
              value={selectedSubcategory}
              styles={themeForSearchableSelect()}
              options={makeOptionsForSelect(subcategory)}
              placeholder='Selecione...'
              isClearable
              isDisabled={disableSubcategorySelect}
              onChange={(selectedOption) => handleChangeCategorySubcategory(selectedOption, 4)}
            />
          </S.FilterSelectWrapper>
        }

        {/* Características */}
        {characteristicsFilter?.length > 0 && 
          <>
            {characteristicsFilter.map(char => (
              <S.FilterSelectWrapper key={char.id}>
                <S.FilterSelectLabel>{char.description}</S.FilterSelectLabel>
                <SearchableSelect
                  noOptionsMessage={() => 'Sem resultados.'}
                  defaultValue=''
                  styles={themeForSearchableSelect()}
                  options={transformObjToOptions(characteristics, char.id)}
                  placeholder='Selecione...'
                  onChange={(selectedOption) => handleSelectChange(selectedOption, char.id, 2)}
                  isClearable
                />
              </S.FilterSelectWrapper>
            ))}
          </>
        }

        {/* Campos customizados */}
        {customFieldsFilter?.length > 0 &&
          <>
            {customFieldsFilter.map(field => (
              <S.FilterSelectWrapper key={field.id}>
                <S.FilterSelectLabel>{field.name}</S.FilterSelectLabel>
                <SearchableSelect
                  noOptionsMessage={() => 'Sem resultados.'}
                  defaultValue=''
                  styles={themeForSearchableSelect()}
                  options={transformObjToOptions(customFieldsValues, field.id, 1)}
                  placeholder='Selecione...'
                  onChange={(selectedOption) => handleSelectChange(selectedOption, field.id, 1)}
                  isClearable
                />
              </S.FilterSelectWrapper>
            ))}
          </>
        }
      </>
    )
  }

  useEffect(() => {
    const getCustomer = JSON.parse(localStorage.getItem('DAuth:customer'));
    setCustomer(getCustomer)
    getCharacteristics()
    getCustomFields()
    if (isMobile || isHorizontalMenu) {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    const loadFilterConfig = () => {
      if (!!customer) {
        const environmentConfiguration = customer?.environmentConfiguration
        setEcommerceFilterBar(environmentConfiguration?.ecommerceFilterBar)

        const findCustomField = customFields.filter(field => ecommerceFilterBar?.filterBarCustomFields.includes(field.id))
        setCustomFieldsFilter(findCustomField ? findCustomField : null)
    
        const findCharacteristic = characteristics.filter(char => ecommerceFilterBar?.filterBarCharacteristics.includes(char.id))
        setCharacteristicsFilter(!!findCharacteristic ? findCharacteristic : null)
      }
    }

    loadFilterConfig()
  }, [customer, ecommerceFilterBar, customFields, characteristics])

  return (
    (!!ecommerceFilterBar?.filterBarCategories || !!ecommerceFilterBar?.filterBarSubcategories || customFieldsFilter?.length > 0 || characteristicsFilter?.length > 0) &&
    <>
      {isMobile &&
        <>
          <S.FilterMobile isMobile ref={innerBorderRef}>
            <S.FilterButtonMobile isOpen={isOpen} onClick={handleOpen}>
              <S.StyledFontAwesomeIcon icon={faFilter} />
              Filtrar por...
            </S.FilterButtonMobile>
            <S.FilterContentMobile isOpen={isOpen}>
              <S.ScrollableContentMobile>
                {mountSelectsJSX()}
              </S.ScrollableContentMobile>
              <S.FilterApplyButton onClick={handleButtonClick}>Filtrar</S.FilterApplyButton>
            </S.FilterContentMobile>
          </S.FilterMobile>
        </>
      }
      {!isMobile &&
        <>
          <S.FilterWrapper onMouseEnter={closeOtherMenuHoritontalOptions} ref={isHorizontalMenu && innerBorderRef}>
            <S.FilterButton onClick={handleOpen} $isHorizontalMenu={isHorizontalMenu} $changeHeaderColor={changeHeaderColor}>
              <S.FilterTitle isOpen={isOpen} $isHorizontalMenu={isHorizontalMenu} $changeHeaderColor={changeHeaderColor}>
                <S.StyledFontAwesomeIcon icon={faFilter} />
                Filtrar por...
                {isOpen ?  <S.StyledFontAwesomeIcon icon={faChevronUp} /> :  <S.StyledFontAwesomeIcon icon={faChevronDown} />}
              </S.FilterTitle>
            </S.FilterButton>
            <S.FilterContent isOpen={isOpen} $isHorizontalMenu={isHorizontalMenu}>
              {mountSelectsJSX()}
              {/* Botão para filtrar */}
              <S.FilterApplyButton onClick={handleButtonClick}>Filtrar</S.FilterApplyButton>
            </S.FilterContent>
          </S.FilterWrapper>
        </>
      }
    </>
  )
}

export default Filter
