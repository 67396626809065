import styled, { css } from 'styled-components/macro'

export const MessageBox = styled.div`
  border: .1rem solid ${({ theme }) => theme.colors.mono.grey};
  border-radius: .5rem;
  overflow: hidden;
`

export const Title = styled.h4`${({ theme }) => css`
  padding: .65rem 2.2rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${theme.colors.mono.greyDarker};
  background-color: ${theme.colors.mono.greyLight};
`}`

export const Content = styled.div`${({ theme }) => css`
  padding: 1rem 2.2rem;
  background-color: ${theme.colors.mono.white};
  border-top: .1rem solid ${theme.colors.mono.grey};

  p {
    font-size: 1.3rem;
    line-height: 1.7rem;

    &:not(:last-of-type) {
      margin-bottom: 1.7rem;
    }
  }
`}`
