import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const ProductCard = styled.div`${({ theme }) => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem 1.5rem 3rem;
  background: ${theme.colors.mono.white};
  box-shadow: ${theme.shadows.card};
  border-radius: 0.8rem;
`}`

export const ImageWrapper = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30rem;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

export const Title = styled.h3`
  margin-top: 3rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -.03rem;
  text-align: center;
`

export const SubTitle = styled.small`
  margin-top: 3rem;
  font-size: 1rem;
  line-height: 1.8rem;
  letter-spacing: -.03rem;
  text-align: center;
`

export const NewPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  margin-top: 0;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -.03rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const NewPrice = styled.span`
  margin-left: 0.5rem;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -0.1rem;
`

export const OriginalPriceWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -.03rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const OriginalPrice = styled.span`
  font-weight: 700;
  font-size: 1.8rem;
  letter-spacing: -0.1rem;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const MoreDetails = styled(Link)`
  margin-top: 1.9rem;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -.03rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};

  &:hover {
    color: ${({ theme }) => theme.colors.mono.black};
  }
`
export const ProductCardLink = styled(Link)`
  display: table;
  margin: 1.5rem auto 0;
  font-weight: 800;
  font-size: 2.3rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.orange.default};
  flex: 1;
  &:hover {
    color: ${({ theme }) => theme.colors.mono.black};
  }
  @media ${({ theme }) => theme.screens.md} {
    color: ${({ theme }) => theme.colors.mono.greyDarker};
  }
`
