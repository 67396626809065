import styled from 'styled-components/macro'

export const MainContent = styled.main`
  max-width: ${({ sidebarType }) => sidebarType === 'vertical' ? 'calc(100% - 32.4rem)' : 'calc(100% - 8rem)'};
  width: 100%;

  @media ${({ theme }) => theme.screens.lg} {
    max-width: unset;
  }
`
