import { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './AuthContext'
import { toast } from 'react-toastify'
import api from '../services/api'

const CartContext = createContext()

export const CartProvider = (props) => {
  const [cart, setCart] = useState(null)
  const [loadingBackground, setLoadingBackground] = useState(false)
  const [productAdded, setProductAdded] = useState(false)
  const { user } = useAuth()
  const cartInfo = JSON.parse(localStorage.getItem('@bendito:cart'))
  let previousCartItems = !!cartInfo ? cartInfo.items.length : 0
  let currentCartItems = !!cartInfo ? cartInfo.items.length : 0

  const startSaveCart = async (cart) => {
    if (!!cart) {
      const cartId = JSON.parse(localStorage.getItem('@bendito:cartId'))
      const cartItemsFormatted = cart.items.map(item => {
        return {
          ProductId: item.productId,
          ProductSkuDataId: !!item.productSkuDataId ? item.productSkuDataId : 0,
          Quantity: item.quantity,
          Value: item.value,
          Tax: item.tax,
          Freight: item.freight,
          Discount: item.politicsDiscount + item.politicsUnitDiscount,
          Addition: item.addition,
          TotalValue: item.totalValue,
          Comments: item.comments,
        }
      })
   
      const data = {
        Status: cart.status,
        ClientId: cart.clientId,
        Value: cart.value,
        Tax: cart.tax,
        Freight: cart.freight,
        Discount: cart.discount,
        PoliticsDiscount: cart.politicsDiscount,
        TotalValue: cart.totalValue,
        Advance: !!cart.advance ? cart.advance : 0,
        Addition: cart.addition,
        Notes: cart.notes,
        Items: cartItemsFormatted ? cartItemsFormatted : [],
        PaymentMethodId: cart.paymentMethodId,
        DeadlineId: cart.deadlineId,
        DeliveryForecast: cart.deliveryForecast,
      }

      if (!!cartId) {
        data.Id = cartId
      } else {
        delete data.id
      }
  
      if (!cartId && cart && cart.items.length === 1 && previousCartItems === 0) {
        try {
          const response = await api.post('/api/v2/Cart', data)
          const responseData = response.data.response.data
          localStorage.setItem('@bendito:cartId', JSON.stringify(responseData.id))
        } catch (error) {
          console.log('error', error);
        }
      } else {
        if (!!cartId) {
          try {
            const response = await api.post(`/api/v2/Cart`, data)
          } catch (error) {
            console.log('error', error);
          }
        }
      }
      previousCartItems = 0
      currentCartItems = 0
    }
  }

  useEffect(() => {
    function loadStoredCart () {
      setCart(JSON.parse(localStorage.getItem('@bendito:cart')))
    }
    loadStoredCart()
    startSaveCart(cartInfo);
  }, [])

  const addProduct = async (productId, qty = 1, gradeId) => {
    previousCartItems = !!cart ? cart.items.length : 0
    if (user.paymentMethod[0] == null) {
      toast.error('Nenhuma forma de pagamento cadastrada pelo vendedor, por favor contate a loja!', {
        position: 'top-right',
      })
    }

    const data = {
      AddProductGradeId: gradeId ? +gradeId : null,
      AddProductId: productId,
      AddQuantity: qty,
      ClientId: user.clientId,
      DeadlineId: (() => {
        var activeDeadline = user.deadline?.find(deadline => deadline.status === 1);
        if (activeDeadline != null && user.preferredDeadlineId != 0) {
          activeDeadline = user.deadline?.find(x => x.id === user.preferredDeadlineId && x.status === 1);
        }
        return activeDeadline ? activeDeadline.id : '';
      })(),
      Items: cart ? cart.items : [],
      Nature: user.domain ? user.domain[0].value : undefined,
      PaymentMethodId: user.paymentMethod ? user.paymentMethod[0].id : 0,
      Price: '',
    }
    try {
      setProductAdded(false)
      setLoadingBackground(true)
      const response = await api.post('/Invoice/Calculate', data)
      setCart(response.data.data)
      setProductAdded(true)
      localStorage.setItem('@bendito:cart', JSON.stringify(response.data.data))
      currentCartItems = response.data.data.items.length
      startSaveCart(response.data.data);
      toast(response.data.successMessages[0], {
        type: 'success',
        position: 'top-right',
      })
    } catch (error) {
      console.log('error', error)
      console.log('error.response', error.response)
      toast(error.response?.data.errorMessages[0], {
        type: 'error',
        position: 'top-right',
      })
    } finally {
      setLoadingBackground(false)
    }
  }

  const deleteProduct = async (productId, gradeId) => {
    previousCartItems = !!cart ? cart.items.length : 0
    const updatedProducts = cart.items.filter(
      (product) => gradeId ? product.productSkuDataId !== gradeId : product.productId !== productId,
    )
    const data = {
      AddProductId: null,
      AddQuantity: null,
      ClientId: user.clientId,
      DeadlineId: (() => {
        var activeDeadline = user.deadline?.find(deadline => deadline.status === 1);
        if (activeDeadline != null && user.preferredDeadlineId != 0) {
          activeDeadline = user.deadline?.find(x => x.id === user.preferredDeadlineId && x.status === 1);
        }
        return activeDeadline ? activeDeadline.id : '';
      })(),
      Items: updatedProducts,
      Nature: user.domain ? user.domain[0].value : undefined,
      PaymentMethodId: user.paymentMethod ? user.paymentMethod[0].id : '',
      Price: '',
    }
    try {
      setLoadingBackground(true)
      const response = await api.post('/Invoice/Calculate', data)
      setCart(response.data.data)
      localStorage.setItem('@bendito:cart', JSON.stringify(response.data.data))
      currentCartItems = response.data.data.items.length
      startSaveCart(response.data.data);
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingBackground(false)
    }
  }

  const updateMethodPayment = async (payMethod) => {
    const deadlineRes = await api.get('/Deadline')

    const data = {
      AddProductId: null,
      AddQuantity: null,
      ClientId: user.clientId,
      DeadlineId: (() => {
        if(payMethod.deadline && payMethod.deadline > 0) {
          return payMethod.deadline;
        }

        var activeDeadline = deadlineRes.data.find(deadline => deadline.status === 1);
        if (activeDeadline != null && user.preferredDeadlineId != 0) {
          activeDeadline = deadlineRes.data.find(x => x.id === user.preferredDeadlineId && x.status === 1);
        }

        return activeDeadline ? activeDeadline.id : '';
      })(),
      Items: cart.items,
      Nature: user.domain ? user.domain[0].value : undefined,
      PaymentMethodId: +payMethod.value,
      Price: '',
    }
    try {
      setLoadingBackground(true)
      const response = await api.post('/Invoice/Calculate', data)
      setCart(response.data.data)
      localStorage.setItem('@bendito:cart', JSON.stringify(response.data.data))
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingBackground(false)
    }
  }

  const clearCart = () => {
    setCart(null)
    localStorage.removeItem('@bendito:cart')
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        productAdded,
        loadingBackground,
        addProduct,
        deleteProduct,
        updateMethodPayment,
        clearCart,
        startSaveCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  )
}

export function useCart () {
  return useContext(CartContext)
}

export default CartContext