import { toast } from 'react-toastify'
import api from './api'

export const deleteInvoice = async (id, status, integrationCode, customerCode) => {
  const toastDelete = toast.loading('')
  if (status === 1 && (integrationCode === null || integrationCode === '') && (customerCode === null || customerCode === '')) {
    const response = await api.delete(`Invoice/${id}`)
    toast.update(toastDelete, {
      render: response.data.successMessages[0],
      type: 'success',
      isLoading: false,
      delay: 750,
      autoClose: 2000,
      position: 'top-right',
    })
    return true
  } else {
    toast.update(toastDelete, {
      render: 'Só é possível eliminar pedidos em aberto e não integrados!',
      type: 'warning',
      isLoading: false,
      delay: 750,
      autoClose: 5000,
      position: 'top-right',
    })
    return false
  }
}

export const printInvoice = async (id) => {
  try {
    const response = await api.get(`/Invoice/Print/${id}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    })
    const blob = new Blob([response.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    toast('Ocorreu um erro ao gerar sua impressão', {
      type: 'error',
      position: 'top-right',
    })
  }
}
