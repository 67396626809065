import styled from 'styled-components'

export const ProductItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 3rem;
  border-top: 0.15rem solid ${({ theme }) => theme.colors.mono.greyLight};
  @media ${({ theme }) => theme.screens.sm} {
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
`

export const ProductList = styled.ul`
  margin: 3.5rem 0 0 4.4rem;
  @media ${({ theme }) => theme.screens.sm} {
    margin: 2rem auto;
  }
  ${ProductItem} {
    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }
`

export const ProductItemImageFrame = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 10rem;
  height: 9.167rem;
  margin-right: 4rem;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.card};
  @media ${({ theme }) => theme.screens.sm} {
    margin-right: 0rem;
  }
`

export const ProductItemImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const ProductItemWrapper = styled.div`
  flex-grow: 1;
`

export const ProductItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 2rem;
  @media ${({ theme }) => theme.screens.md} {
    justify-content: center;
  }
`
export const ProductItemContentModal = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  @media ${({ theme }) => theme.screens.md} {
    justify-content: center;
    flex-wrap: wrap;
  }
`

export const ProductItemInfos = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.screens.md} {
    width: 100%;
  }
`

export const ProductItemCod = styled.span`
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const ProductItemName = styled.span`
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  color: ${({ theme }) => theme.colors.orange.default};
  max-width: 25rem;
  @media ${({ theme }) => theme.screens.sm} {
    max-width: 100%;
    width: 100%;
  }
`

export const ProductItemCharacteristics = styled.div`
  .desc {
    ul {
      display: flex;
      li {
        margin-bottom: 0.5rem;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.4rem;
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.mono.greyDarker};
        position: relative;
      }
    }
  }
`

export const ProductItemDetails = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.6rem;
  max-width: 60.9rem;
  width: 100%;
  align-items: center;
`

export const ProductItemDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const ProductItemDetailsItemLabel = styled.span`
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
  text-align: center;
`

export const ProductItemDetailsItemValue = styled.span`
  margin-top: 1.45rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
`

export const ProductItemDetailsItemPrice = styled(ProductItemDetailsItemValue)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.orange.default};
`
