import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTitle } from '../../hooks/useTitle'
import api from '../../services/api'
import * as S from './styles.js'
import * as H from './../Home/styles'
import ProductCard from '../../components/ProductCard'
import Breadcrumb from '../../components/Breadcrumb'
import LoadingBackground from '../../components/LoadingBackground'
import { IMAGE_URL } from '../../constants'
import Paginator from '../../components/Paginator'
import Select from '../../components/Select'
import { useAuth } from '../../context/AuthContext'
import { useSearch } from '../../context/SearchContext.js'

const Category = () => {
  const { verifyAcessWithoutLogin } = useAuth()
  const { id, subId } = useParams()
  const { currentPage, setCurrentPage, totalPages, setTotalPages, orderFilter, setOrderFilter } = useSearch();
  const [category, setCategory] = useState({})
  const [subcategoryList, setSubcategoryList] = useState([])
  const [selectedSubcategory, setSelectedSubcategory] = useState('')
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [visualization, setVisualization] = useState(2)
  const accessWithoutLogin = verifyAcessWithoutLogin()

  const handlePageClick = (e) => {
    window.scrollTo({ top: 0 })
    setIsLoading(true)
    setCurrentPage(e.selected + 1)
  }
  const handleOrder = (e) => {
    setOrderFilter(e)
    getOpportunitiesProducts(e, currentPage)
  }
  const getOpportunitiesProducts = async (order) => {
    setIsLoading(true)
    const verifySubcategoryId = !!subId ? '&SubCategoryId=' + subId : ''
    const response = await api.get(`/api/v2/Product?CategoryId=${id}${verifySubcategoryId}&OnlyB2B=true&Order=${order}&Page=${currentPage}&Size=20`)
    setProducts(response.data.response.data)
    setIsLoading(false)
  }
  useEffect(() => {
    const getCategory = async () => {
      const response = await api.get(`/api/v2/Category/${id}`)
      setCategory(response.data.response.data)
      window.scrollTo({ top: 0 })
    }
    getCategory()
  }, [id])

  useEffect(() => {
    const getSubcategory = async () => {
      const response = await api.get('/api/v2/SubCategory')
      setSubcategoryList(response.data.response.data)
    }
    getSubcategory()
  }, [])

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);
      try {
        if (subId === "0") {
          const response = await api.get(`/api/v2/Product?CategoryId=${id}&OnlyB2B=true&Order=${orderFilter}&Page=${currentPage}&Size=20`);
          setTotalPages(response.data.response.totalPages);
          setProducts(response.data.response.data);
          setIsLoading(false);
        } else {
          const verifySubcategoryId = !!subId ? '&SubCategoryId=' + subId : ''
          const response = await api.get(`/api/v2/Product?CategoryId=${id}${verifySubcategoryId}&OnlyB2B=true&Order=${orderFilter}&Page=${currentPage}&Size=20`)
          setTotalPages(response.data.response.totalPages);
          setProducts(response.data.response.data);
          window.scrollTo({ top: 0 });
          subcategoryList.forEach((item) => {
            if (item.id === subId) {
              setSelectedSubcategory(item.description);
            }
          });
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
  
    getProducts();
  }, [category, subId, currentPage]);

  useTitle(category.description)

  return (
    <S.Category>
      {isLoading && (
        <LoadingBackground />
      )}

      <Breadcrumb
        currentSubcategory={selectedSubcategory}
        current={category.description}
        parent='Categorias de Produtos'
      />
      <S.CategoryWrapper>
        {category.image && (
          <S.CategoryFigure>
            <S.CategoryImage src={`${IMAGE_URL}${category.image}`} alt='Categoria' />
          </S.CategoryFigure>
        )}

        <S.CategoryContent>
          <S.CategoryTitle>{category.description}</S.CategoryTitle>

          <S.CategoryText>
            <p>{category.detailedDescription}</p>
          </S.CategoryText>

          {(category.description2 || category.description3) && (
            <S.CategoryQualities>
              {(category.description2 && category.detailedDescription2) && (
                <S.CategoryItem>
                  <S.CategoryItemIconContainer>
                    <S.CategoryItemIcon type='oneFinger' />
                  </S.CategoryItemIconContainer>

                  <S.CategoryItemContent>
                    <S.CategoryItemTitle>{category.description2}</S.CategoryItemTitle>
                    <S.CategoryItemText>{category.detailedDescription2}</S.CategoryItemText>
                  </S.CategoryItemContent>
                </S.CategoryItem>
              )}
              {(category.description3 && category.detailedDescription3) && (
                <S.CategoryItem>
                  <S.CategoryItemIconContainer>
                    <S.CategoryItemIcon type='thumbsUp' />
                  </S.CategoryItemIconContainer>

                  <S.CategoryItemContent>
                    <S.CategoryItemTitle>{category.description3}</S.CategoryItemTitle>
                    <S.CategoryItemText>{category.detailedDescription3}</S.CategoryItemText>
                  </S.CategoryItemContent>
                </S.CategoryItem>
              )}
            </S.CategoryQualities>
          )}

        </S.CategoryContent>
      </S.CategoryWrapper>
      <H.HomeTopInfo>
        <H.HomeTopInfoTitle>{category.description}</H.HomeTopInfoTitle>
        <H.HomeTopOrderCnt>
          <label htmlFor=''>Ordenação</label>
          <Select value={orderFilter} onChange={(e) => handleOrder(e.target.value)}>
            <option value='0'>Nome - De A para Z</option>
            <option value='1'>Nome - De Z para A</option>
            {
              accessWithoutLogin === false &&
              <>
                <option value='2'>Preço - Do menor para o maior</option>
                <option value='3'>Preço - Do maior para o menor</option>
              </>
            }
          </Select>
        </H.HomeTopOrderCnt>
        <H.HomeViewCnt>
          <H.HomeViewBtnText>Visualização</H.HomeViewBtnText>
          <H.HomeViewBtnCnt isMobile>
            <H.HomeViewBtn isActive={visualization === 1} onClick={() => setVisualization(1)}>
              <H.HomeViewBtnIcon type='oneCol' />
            </H.HomeViewBtn>
            <H.HomeViewBtn isActive={visualization === 2} onClick={() => setVisualization(2)}>
              <H.HomeViewBtnIcon type='twoCol' />
            </H.HomeViewBtn>
          </H.HomeViewBtnCnt>
          <H.HomeViewBtnCnt>
            <H.HomeViewBtn isActive={visualization === 2} onClick={() => setVisualization(2)}>
              <H.HomeViewBtnIcon type='twoCol' />
            </H.HomeViewBtn>
            <H.HomeViewBtn isActive={visualization === 3} onClick={() => setVisualization(3)}>
              <H.HomeViewBtnIcon type='thrCol' />
            </H.HomeViewBtn>
          </H.HomeViewBtnCnt>
        </H.HomeViewCnt>
      </H.HomeTopInfo>
      <S.CategoryGridProducts styleQtd={visualization}>
        {!isLoading && (
          <>
            {products.length > 0
              ? (products.map(product => (<ProductCard key={product.id} product={product} styleQtd={visualization} />)))
              : (<h1>Sem produtos</h1>)}
          </>
        )}
      </S.CategoryGridProducts>
      <S.FullW>
        {!isLoading && totalPages > 1 && <Paginator totalPages={totalPages} currentPage={currentPage} handlePageClick={handlePageClick} />}
      </S.FullW>
    </S.Category>
  )
}

export default Category
