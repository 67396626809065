import * as S from './styles'

const Notifications = ({ className, notifications }) => {
  return (
    <S.Notifications className={className}>
      {notifications.length > 0
        ? notifications.map((notification, i) => (
          <S.Notification key={i} isNew={notification?.date?.slice(0, 10) === new Date().toISOString().slice(0, 10)}>
            <S.NotificationWrapper>
              <S.NotificationIcon>
                <svg width='27' height='24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' clipRule='evenodd' d='M9.048 5.176a6.46 6.46 0 0 1 2.216-.942 1.79 1.79 0 0 1 .09-.834c.095-.269.253-.517.465-.728.21-.21.47-.38.76-.495a2.491 2.491 0 0 1 1.841 0c.29.116.549.285.76.495.211.211.37.46.465.728.096.27.126.554.089.834a6.461 6.461 0 0 1 2.217.942c1.098.75 1.734 1.755 2.11 2.758.373.995.508 2.032.573 2.905.027.355.044.696.058.997l.01.203c.017.36.035.63.065.828.22 1.462.493 2.487.882 3.307.38.801.889 1.446 1.646 2.119.157.14.264.318.308.512a.897.897 0 0 1-.064.578c-.085.182-.23.338-.414.448-.185.11-.403.169-.625.169h-7.052c.197.304.301.649.301 1 0 .35-.104.696-.302 1a2.143 2.143 0 0 1-.823.731 2.47 2.47 0 0 1-1.125.268 2.47 2.47 0 0 1-1.125-.268 2.143 2.143 0 0 1-.823-.731 1.832 1.832 0 0 1-.302-1c0-.351.104-.696.301-1H4.5c-.223 0-.44-.059-.625-.169a1.036 1.036 0 0 1-.414-.448.897.897 0 0 1-.064-.578.971.971 0 0 1 .307-.512c.759-.674 1.267-1.314 1.646-2.112.387-.817.66-1.842.882-3.314.03-.199.049-.468.066-.829l.01-.202c.013-.3.03-.642.056-.997.066-.873.201-1.91.574-2.905.377-1.003 1.012-2.009 2.11-2.758ZM6.828 18c.221-.326.418-.67.595-1.041.5-1.053.807-2.279 1.04-3.826.044-.334.07-.67.082-1.007l.012-.216c.013-.3.028-.61.052-.937.061-.814.18-1.652.464-2.407.281-.747.704-1.366 1.345-1.804C11.053 6.329 12.006 6 13.5 6c1.494 0 2.447.329 3.081.762.641.438 1.066 1.057 1.346 1.804.283.755.402 1.593.463 2.407.025.327.04.637.053.936l.011.216c.017.35.037.707.082 1.008.232 1.538.54 2.763 1.04 3.818.177.373.376.72.598 1.049H6.827Z' /></svg>
              </S.NotificationIcon>
              <S.NotificationText>
                {notification.description}
              </S.NotificationText>
            </S.NotificationWrapper>
          </S.Notification>
        ))
        : (
          <S.Notification>
            <S.NotificationWrapper>
              <S.NotificationText>
                Sem notificações
              </S.NotificationText>
            </S.NotificationWrapper>
          </S.Notification>
          )}
    </S.Notifications>
  )
}

export default Notifications
