import React, { useState, useEffect } from 'react'
import CustomerList from '../CustomerList'
import formatMoney from '../../../util/formatMoney'
import * as S from './styles'
import Gallery from '../Gallery'
import Faq from '../../Faq'
import Breadcrumb from '../../Breadcrumb'
import IncrementStepper from '../../IncrementStepper'
import Select from '../../Select'
import { useAuth } from '../../../context/AuthContext'
import api from '../../../services/api'
import { Link } from 'react-router-dom'
import LoadingBackground from '../../LoadingBackground'
import { useCart } from '../../../context/CartContext'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

function DetailProduct ({ product, addProduct }) {
  const [qty, setQty] = useState(0)
  const [grade, setGrade] = useState([])
  const [gradeValue, setGradeValue] = useState('')
  const [structure, setStructure] = useState([])
  const [geralCharacteristics, setGeralCharacteristics] = useState({})
  const [customerCharacteristics, setCustomerCharacteristics] = useState([])
  const { verifyAcessWithoutLogin, authenticated } = useAuth()
  const storedCustomer = JSON.parse(localStorage.getItem('DAuth:customer'));
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'));
  const showProductPromotionInPortal = storedCustomer?.environmentConfiguration?.showProductPromotionInPortal
  const [allCharacteristicsList, setAllCharacteristicsList] = useState()
  const [productCharacteristicsList, setProductCharacteristicsList] = useState()
  const [unitMeasureList, setUnitMeasureList] = useState()
  const [characteristicValues, setCharacteristicValues] = useState()
  const [isInStructureValues, setIsInStructureValues] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const updatedGradeValue = Number(gradeValue)
  const findSkuProduct = grade.find(item => item.id === updatedGradeValue)
  const skuProductPrice = findSkuProduct && findSkuProduct?.price > 0 ? findSkuProduct.price : null
  const productImg = findSkuProduct?.images && findSkuProduct?.images.length > 0 ? findSkuProduct.images : product.images
  const accessWithoutLogin = verifyAcessWithoutLogin()
  const { cart } = useCart()
  const findProductOnCart = cart?.items?.find(item => item.productId === product.id)

  const formatedCharacteristics = Object.entries(geralCharacteristics).reduce((memo, [key, value]) => {
    memo = `${memo}${key}: ${value}<br>`
    return memo
  }, '')

  const formatProducts = (products) => {
    return Object.entries(products).map(([productId, product]) => {
      const descriptions = product.map(item => `${item.quantity} ${item.description}`)
      const lastDescription = descriptions.pop()
      product.description = `${descriptions.join(', ')} e ${lastDescription}.`
      product.productId = productId
      product.thumbnail = product[0].thumbnail ?? '/logo.png'
      product.productName = product[0].productName
      product.productPrice = product[0].productPrice
      if (product[0].thumbnail && product[0].thumbnail.length > 0) {
        if (product[0].thumbnail.includes('http')) { product.thumbnail = product[0].thumbnail } else { product.thumbnail = `${IMAGE_URL}${product[0].thumbnail}` }
      } else {
        product.thumbnail = '/logo.png'
      }

      return product
    })
  }

  const verifyOriginalPrice = () => {
    if ( grade.length > 0 && skuProductPrice ) {
      return product.originalPrice > skuProductPrice
    } else {
      return product.originalPrice > product.price
    }
  }

  const getCharacteristics = async () => {
    let data = allCharacteristicsList?.map(element => {
      const matchedItem = productCharacteristicsList?.find(item => item.characteristicId === element.id);
      if (matchedItem) {
        return {
          ...matchedItem,
          description: element.description,
          unitMeasure: element.unitMeasure
        };
      };
      return null; // Retorna null se não houver correspondência
    }).filter(item => item !== null);

    if (data.length > 0) {
      let tmpGeralCharacteristics = {};
      let tmpAllCharacteristicsValues = [];
      for (const item of data) {
        const unit = unitMeasureList?.find((elem) => elem.value === item.unitMeasure);
        if (item.value) {
          tmpGeralCharacteristics[item.description] = `${item.value}${unit ? unit.shortDescription : ''}`;
        } else {
          try {
            const response = await api.get(`/api/v2/Characteristic/${item.characteristicId}/Values`);
            tmpAllCharacteristicsValues.push({
              charId: item.characteristicId,
              response: response,
            });
            const nullCharacteristic = response.data.response.data.find(chara => chara.id === item.valueId);
            tmpGeralCharacteristics[item.description || ''] = `${nullCharacteristic?.description || ''}${unit ? unit.shortDescription : ''}`;
          } catch (error) {
            console.error('Erro ao buscar a característica nula:', error);
          }
        }
      }
      setGeralCharacteristics(prev => ({ ...prev, ...tmpGeralCharacteristics }));
      setCharacteristicValues(tmpAllCharacteristicsValues);
    }
  }

  const getGroupedProducts = (products) => {
    const groupedProducts = products.reduce((memo, product) => {
      if (!memo[product.productId]) {
        memo[product.productId] = [];
      }
      memo[product.productId].push(product);
      return memo;
    }, {});

    return groupedProducts;
  }

  const handleGradeQtyChange = (e) => {
    const grade = Number(e.target.value)
    setQty(0)
    
    const cartQty = cart?.items?.find(item => item.productId === product.id && item.productSkuDataId === grade)
    if (cartQty && cartQty?.quantity > 0) {
      setQty(cartQty?.quantity)
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        setGradeValue(findProductOnCart && findProductOnCart?.productSkuDataId ? findProductOnCart?.productSkuDataId : '')
        setGeralCharacteristics({})
        setUnitMeasureList([]);
        setAllCharacteristicsList([]);
        setProductCharacteristicsList([]);
        const [responseAllChars, responseProductChars, responseUnitMeasure, responseGrade, responseStructure, responseIsInStructure] = await Promise.all([
          api.get('/api/v2/Characteristic'),
          api.get(`/api/v2/Product/${product.id}/Characteristics`),
          api.get('/Domain?DomainId=UNIDADE_MEDIDA'),
          api.get(`/api/v2/Product/${product.id}/Sku`),
          api.get(`/api/v2/Product/${product.id}/Structure`),
          api.get(`/api/v2/Product/${product.id}/Structure?isInStructure=true`)
        ]);

        setCustomerCharacteristics(storedCustomer?.environmentConfiguration.characteristicsB2B)
        setAllCharacteristicsList(responseAllChars.data.response.data);
        setProductCharacteristicsList(responseProductChars.data.response.data);
        setUnitMeasureList(responseUnitMeasure.data);
        setGrade(responseGrade.data.response.data)
        setStructure(responseStructure.data.response.data)
        setIsInStructureValues(responseIsInStructure.data.response.data)
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      } finally {
        setIsLoading(false);
      }
    }

    getData()
  }, [product.id])

  useEffect(() => {
    const loadCharacteristics = async () => {
      await getCharacteristics();
    }

    if (productCharacteristicsList) {
      loadCharacteristics();
    }
  }, [productCharacteristicsList, unitMeasureList, customerCharacteristics])

  useEffect(() => {
    if (findProductOnCart?.productSkuDataId && findProductOnCart?.productSkuDataId > 0) {
      const cartQty = cart?.items?.find(item => item.productId === product.id && item.productSkuDataId == gradeValue)
      setQty(cartQty ? cartQty?.quantity : 0)
    } else {
      const initialQty = findProductOnCart && findProductOnCart?.quantity > 0 ? findProductOnCart?.quantity : 0;
      setQty(initialQty)
    }
  }, [product.id, gradeValue, findProductOnCart])

  return (
    <>
      {isLoading ? (
        <LoadingBackground />
      ) : (
        <S.Product>
          {product.description && (
            <Breadcrumb
              current={product.description}
              parent='Categorias de Produtos'
            />
          )}
          <S.ProductWrapper>
            <S.ProductDetailMobile>
              <S.ProductCode>Cód.: #{product.customerCode.length > 0 ? product.customerCode : product.productId}</S.ProductCode>
              <S.ProductTitle>
                {product.description}
                {product.multiplicationFactor > 1 ? <S.ProductSubTitle>(x{product.multiplicationFactor})</S.ProductSubTitle> : <></>}
              </S.ProductTitle>
              <CustomerList productId={product.id} isDetailProductPage={true} characteristicValues={characteristicValues} unitMeasure={unitMeasureList} allCharacteristicsList={allCharacteristicsList} productCharacteristicsList={productCharacteristicsList}/>
            </S.ProductDetailMobile>
            <S.ProductGallery>
              <Gallery images={productImg} />
            </S.ProductGallery>
            <S.ProductContent>
              <S.ProductDetailDesk>
                <S.ProductCode>Cód.: #{product.customerCode.length > 0 ? product.customerCode : product.productId}</S.ProductCode>
                <S.ProductTitle>
                  {product.description}
                  {product.multiplicationFactor > 1 ? <S.ProductSubTitle>(x{product.multiplicationFactor})</S.ProductSubTitle> : <></>}
                </S.ProductTitle>
                {structure.length > 0 &&
                  structure.map((item) => {
                  return (
                    <S.ProductKitContentLabel>{item.quantity}x {item.description}</S.ProductKitContentLabel>
                  )
                  })}
                <CustomerList productId={product.id} isDetailProductPage={true} characteristicValues={characteristicValues} unitMeasure={unitMeasureList} allCharacteristicsList={allCharacteristicsList} productCharacteristicsList={productCharacteristicsList}/>
              </S.ProductDetailDesk>

              <S.ProductCartContainer>
                {
                  accessWithoutLogin === false ?
                    showProductPromotionInPortal && verifyOriginalPrice() ? 
                      <>
                        <S.ProductPrices>
                          <S.ProductOriginalPrice>R$ {formatMoney(product.originalPrice, false)}</S.ProductOriginalPrice>
                          <S.ProductPriceContainer>
                            <S.ProductPriceLabel>R$</S.ProductPriceLabel>
                            <S.ProductPrice>{grade.length > 0 && skuProductPrice ? formatMoney(skuProductPrice, false) : formatMoney(product.price, false)}</S.ProductPrice>
                          </S.ProductPriceContainer>
                        </S.ProductPrices>
                      </>
                    : 
                    <S.ProductPrices>
                      {/* <S.ProductPriceReduce>De <span>{product.price}</span> por</S.ProductPriceReduce> */}
                      <S.ProductPriceContainer>
                        <S.ProductPriceLabel>R$</S.ProductPriceLabel>
                        <S.ProductPrice>{grade.length > 0 && skuProductPrice ? formatMoney(skuProductPrice, false) : formatMoney(product.price, false)}</S.ProductPrice>
                        {/* <S.ProductPriceDiscount>-30%</S.ProductPriceDiscount> */}
                      </S.ProductPriceContainer>
                    </S.ProductPrices>
                  : null
                }
                {(!!authenticated || !customerInfo.b2BSellingInfoDisabled) &&
                  <S.ProductCartInfos style={{marginLeft: 'auto'}}>
                    <S.ProductCartField>
                      <S.ProductCartFieldLabel>Qtde:</S.ProductCartFieldLabel>
                      <S.ProductCartFieldWrapper>
                        <IncrementStepper qty={qty} setQty={setQty} />
                      </S.ProductCartFieldWrapper>
                    </S.ProductCartField>

                    {grade.length > 0 && (
                      <S.ProductCartField>
                        <S.ProductCartFieldLabel>Selecione:</S.ProductCartFieldLabel>
                        <S.ProductCartFieldWrapper>
                          <Select value={updatedGradeValue} name='select-product' onChange={(e) => {handleGradeQtyChange(e); setGradeValue(e.target.value)}}>
                            <option value={0} disabled>---</option>
                            {grade.map(item => (
                              <option key={item.id} value={item.id}>{item.skuDescription}</option>
                            ))}
                          </Select>
                        </S.ProductCartFieldWrapper>
                      </S.ProductCartField>
                    )}
                    {
                      accessWithoutLogin === true ? (
                        <Link to='/login'>
                          <S.ProductButton>
                            Ver preço
                          </S.ProductButton>
                        </Link>
                      ) : (
                        <S.ProductButton
                          onClick={() => addProduct(product.id, (qty > 0 ? qty : 1), gradeValue)}
                          disabled={!!((product.stockRestrition && product.warehouseQuantity <= 0) || (grade.length > 0 && !gradeValue))}
                        >
                          {product.stockRestrition && product.warehouseQuantity <= 0 ? 'Produto sem estoque' : 'Comprar'}
                        </S.ProductButton>
                      )
                    }
                  </S.ProductCartInfos>
                }
              </S.ProductCartContainer>
              {product.additionalInformation && (
                <S.ProductDescriptionContainer>
                  <S.ProductDescription className="ql-editor" dangerouslySetInnerHTML={{ __html: product.additionalInformation }} />
                </S.ProductDescriptionContainer>
              )}
              {formatedCharacteristics.length > 0 && (
                <Faq productDetails title='Características Gerais' content={formatedCharacteristics} />
              )}
              {
                isInStructureValues?.length > 0 && (
                  <S.ProductDescriptionContainer>
                    <S.ProductStructureTitle>Este <span>produto</span> aparece em:</S.ProductStructureTitle>
                    {/* em links com um external icon no começo */}
                    {formatProducts(getGroupedProducts(isInStructureValues))
                    .map((product, index) => (
                      <S.ProductStructureSubItem key={index}>
                        <S.ProductStructureSubItemContent>
                          <S.ProductThumbnail src={product.thumbnail} alt={product.productName} />
                          <S.ProductStructureInfo>
                          <S.ProductStructureName>{product.productName || "Sem nome"}</S.ProductStructureName>
                          <S.ProductStructureDescription>Contém: {product.description}</S.ProductStructureDescription>
                          <Link to={`/product/${product.productId}`}>
                            <S.ProductStructureButton>
                              Visualizar
                            </S.ProductStructureButton>
                          </Link>
                          </S.ProductStructureInfo>
                        </S.ProductStructureSubItemContent>
                      </S.ProductStructureSubItem>
                    ))
                    }
                  </S.ProductDescriptionContainer>
                )
              }
            </S.ProductContent>
          </S.ProductWrapper>
        </S.Product>
      )}
    </>
  )
}

export default DetailProduct
