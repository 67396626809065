import * as S from './styles'

const LoadingBackground = () => {
  return (
    <S.LoadingBackground>
      <S.LoadingBackgroundImage src='/assets/images/loading-background.svg' />
    </S.LoadingBackground>
  )
}

export default LoadingBackground
