import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { ButtonOutline } from '../../../commons/Button'

export const OrderSuccess = styled.div`
  margin-bottom: 40rem;

  @media ${({ theme }) => theme.screens.lg} {
    margin-bottom: 10rem;
  }
`

export const OrderSuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 84rem;
  width: 100%;
  margin: 8.3rem auto 0;
  text-align: center;

  @media ${({ theme }) => theme.screens.lg} {
    margin-top: 4rem;
  }
`

export const OrderSuccessIllustration = styled.img``

export const OrderSuccessTitle = styled.h2`
  margin-top: 3rem;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -.1rem;
  color: ${({ theme }) => theme.colors.green.default};

  @media ${({ theme }) => theme.screens.lg} {
    font-size: 2.4rem;
    line-height: 2.8rem;
    text-align: center;
  }
`

export const OrderSuccessSubtitle = styled.h3`
  max-width: 63.9rem;
  width: 100%;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.8rem;
  letter-spacing: -.1rem;

  @media ${({ theme }) => theme.screens.lg} {
    max-width: unset;
    margin-top: 2rem;
    padding-top: 2rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
    letter-spacing: -0.02rem;
    border-top: .15rem solid ${({ theme }) => theme.colors.mono.greyLight};
  }
`

export const OrderSuccessText = styled.p`${({ theme }) => css`
  margin-top: 4rem;
  padding-top: 4rem;
  color: ${theme.colors.mono.greyDarker};
  border-top: .15rem solid ${theme.colors.mono.greyLight};

  @media ${({ theme }) => theme.screens.lg} {
    margin-top: 2rem;
    padding-top: 0;
    font-size: 1.3rem;
    line-height: 1.7rem;
    text-align: center;
    border-top: 0;
  }
`}`

export const OrderSuccessButtonLink = styled(Link)`
  @media ${({ theme }) => theme.screens.sm} {
    display: block;
    width: 100%;
  }
`

export const OrderSuccessButton = styled(ButtonOutline)`
  margin-top: 4rem;
  font-size: 2rem;
  line-height: 1.8rem;
  padding: 2rem 4rem;

  @media ${({ theme }) => theme.screens.md} {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  @media ${({ theme }) => theme.screens.sm} {
    padding: 1.5rem 0;
  }
`
