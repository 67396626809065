import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTitle } from '../../hooks/useTitle'
import api from '../../services/api'
import * as S from './styles.js'
import * as H from './../Home/styles'
import ProductCard from '../../components/ProductCard'
import Breadcrumb from '../../components/Breadcrumb'
import LoadingBackground from '../../components/LoadingBackground'
import { IMAGE_URL } from '../../constants'
import Paginator from '../../components/Paginator'
import Select from '../../components/Select'
import { useAuth } from '../../context/AuthContext'
import { useSearch } from '../../context/SearchContext.js'

const Collection = () => {
  const { verifyAcessWithoutLogin } = useAuth()
  const { id, variationId } = useParams()
  const { currentPage, setCurrentPage, totalPages, setTotalPages, orderFilter, setOrderFilter } = useSearch();
  const [collection, setCollection] = useState({})
  const [variationsList, setVariationsList] = useState([])
  const [selectedVariation, setSelectedVariation] = useState('')
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [visualization, setVisualization] = useState(2)
  const accessWithoutLogin = verifyAcessWithoutLogin()
  
  const handlePageClick = (e) => {
    window.scrollTo({ top: 0 })
    setIsLoading(true)
    setCurrentPage(e.selected + 1)
  }

  const handleOrder = (e) => {
    setOrderFilter(e)
    getOpportunitiesProducts(e, currentPage)
  }

  const getOpportunitiesProducts = async (order) => {
    setIsLoading(true)
    const verifyVariationId = !!variationId ? '&VariationId=' + variationId : ''
    const response = await api.get(`/api/v2/Product?CollectionId=${id}${verifyVariationId}&OnlyB2B=true&Order=${order}&Page=${currentPage}&Size=20`)
    setProducts(response.data.response.data)
    setIsLoading(false)
  }

  useEffect(() => {
    const getCollection = async () => {
      const response = await api.get(`/api/v2/Collection/${id}`)
      setCollection(response.data.response.data)
      window.scrollTo({ top: 0 })
    }
    getCollection()
  }, [id])

  useEffect(() => {
    const getVariations = async () => {
      const response = await api.get(`/api/v2/collection/${id}/Variations`)
      setVariationsList(response.data.response.data)
    }
    getVariations()
  }, [])

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);
      try {
        if (!variationId) {
          const response = await api.get(`/api/v2/Product?CollectionId=${id}&OnlyB2B=true&Order=${orderFilter}&Page=${currentPage}&Size=20`)
          setTotalPages(response.data.response.totalPages);
          setProducts(response.data.response.data);
          setIsLoading(false);
        } else {
          const verifyVariationId = !!variationId ? '&VariationId=' + variationId : ''
          const response = await api.get(`/api/v2/Product?CollectionId=${id}${verifyVariationId}&OnlyB2B=true&Order=${orderFilter}&Page=${currentPage}&Size=20`)
          setTotalPages(response.data.response.totalPages)
          setProducts(response.data.response.data)
          window.scrollTo({ top: 0 })
          variationsList.map((item) => {
            if (item.id == variationId) {
              setSelectedVariation(item.description)
            }
          })
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    getProducts();
  }, [collection, variationId, currentPage]);

  useTitle(collection.description)

  return (
    <S.Collection>
      {isLoading && (
        <LoadingBackground />
      )}
      <Breadcrumb
        currentSubcategory={selectedVariation}
        current={collection.description}
        parent='Coleções'
      />
      <S.CollectionWrapper>
        {collection.image && (
          <S.CollectionFigure>
            <S.CollectionImage src={`${IMAGE_URL}${collection.image}`} alt='Categoria' />
          </S.CollectionFigure>
        )}
        <S.CollectionContent>
          <S.CollectionTitle>{collection.description}</S.CollectionTitle>
          <S.CollectionText>
            <p>{collection.detailedDescription}</p>
          </S.CollectionText>
          {(collection.description2 || collection.description3) && (
            <S.CollectionQualities>
              {(collection.description2 && collection.detailedDescription2) && (
                <S.CollectionItem>
                  <S.CollectionItemIconContainer>
                    <S.CollectionItemIcon type='oneFinger' />
                  </S.CollectionItemIconContainer>
                  <S.CollectionItemContent>
                    <S.CollectionItemTitle>{collection.description2}</S.CollectionItemTitle>
                    <S.CollectionItemText>{collection.detailedDescription2}</S.CollectionItemText>
                  </S.CollectionItemContent>
                </S.CollectionItem>
              )}
              {(collection.description3 && collection.detailedDescription3) && (
                <S.CollectionItem>
                  <S.CollectionItemIconContainer>
                    <S.CollectionItemIcon type='thumbsUp' />
                  </S.CollectionItemIconContainer>
                  <S.CollectionItemContent>
                    <S.CollectionItemTitle>{collection.description3}</S.CollectionItemTitle>
                    <S.CollectionItemText>{collection.detailedDescription3}</S.CollectionItemText>
                  </S.CollectionItemContent>
                </S.CollectionItem>
              )}
            </S.CollectionQualities>
          )}
        </S.CollectionContent>
      </S.CollectionWrapper>
      <H.HomeTopInfo>
        <H.HomeTopInfoTitle>{collection.description}</H.HomeTopInfoTitle>
        <H.HomeTopOrderCnt>
          <label htmlFor=''>Ordenação</label>
          <Select value={orderFilter} onChange={(e) => handleOrder(e.target.value)}>
            <option value='0'>Nome - De A para Z</option>
            <option value='1'>Nome - De Z para A</option>
            {
              accessWithoutLogin === false &&
              <>
                <option value='2'>Preço - Do menor para o maior</option>
                <option value='3'>Preço - Do maior para o menor</option>
              </>
            }
          </Select>
        </H.HomeTopOrderCnt>
        <H.HomeViewCnt>
          <H.HomeViewBtnText>Visualização</H.HomeViewBtnText>
          <H.HomeViewBtnCnt isMobile>
            <H.HomeViewBtn isActive={visualization === 1} onClick={() => setVisualization(1)}>
              <H.HomeViewBtnIcon type='oneCol' />
            </H.HomeViewBtn>
            <H.HomeViewBtn isActive={visualization === 2} onClick={() => setVisualization(2)}>
              <H.HomeViewBtnIcon type='twoCol' />
            </H.HomeViewBtn>
          </H.HomeViewBtnCnt>
          <H.HomeViewBtnCnt>
            <H.HomeViewBtn isActive={visualization === 2} onClick={() => setVisualization(2)}>
              <H.HomeViewBtnIcon type='twoCol' />
            </H.HomeViewBtn>
            <H.HomeViewBtn isActive={visualization === 3} onClick={() => setVisualization(3)}>
              <H.HomeViewBtnIcon type='thrCol' />
            </H.HomeViewBtn>
          </H.HomeViewBtnCnt>
        </H.HomeViewCnt>
      </H.HomeTopInfo>
      <S.CollectionGridProducts styleQtd={visualization}>
      {!isLoading && (
          <>
            {products.length > 0
              ? (products.map(product => (<ProductCard key={product.id} product={product} styleQtd={visualization} />)))
              : (<h1>Sem produtos</h1>)}
          </>
        )}
      </S.CollectionGridProducts>
      <S.FullW>
        {!isLoading && totalPages > 1 && <Paginator totalPages={totalPages} currentPage={currentPage} handlePageClick={handlePageClick} />}
      </S.FullW>
    </S.Collection>
  )
}

export default Collection