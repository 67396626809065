import styled from 'styled-components'

export const ProductBanner = styled.a`
  display: block;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.mono.white};
  box-shadow: ${({ theme }) => theme.shadows.card};
  border-radius: 0.8rem;
`

export const ProductBannerImage = styled.img`
  width: 100%;
  max-height: 38rem;
  @media ${({ theme }) => theme.screens.md} {
    max-height: 30rem;
  }
  object-fit: contain;
`
