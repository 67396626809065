import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTitle } from '../../hooks/useTitle'
import api from '../../services/api'
import { printInvoice } from '../../services/invoice'
import * as S from './styles'
import Loading from '../../commons/Loading'
import OrderPrices from '../../components/OrderPrices'
import BackButton from '../../components/BackButton'
import formatMoney from '../../util/formatMoney'
import getDateStr from '../../util/getDateStr'
import Breadcrumb from '../../components/Breadcrumb'
import * as L from '../../components/Sidebar/styles'
import * as Pl from '../../components/ProductList'
import Modal from 'react-modal'
import { toast } from 'react-toastify'
import Select from '../../components/Select'
import { useDebounce } from './../../hooks/useDebounce'
import CustomerList from '../../components/Product/CustomerList'
import LoadingBackground from '../../components/LoadingBackground'
import { useOnOutsideClick } from '../../hooks/useOnOutsideClick'
import Paginator from '../../components/Paginator'
import DetailProduct from '../../components/Product/Detail'
import { getGradeSelected } from '../../util/getGradeSelected'
import SearchableSelect from 'react-select'
import { customStylesForSearchableSelect } from '../../util/searchableSelectStyle'
import SidebarModal from '../../components/SideBarModal'
import { useSearch } from '../../context/SearchContext'
import { objectToQueryString } from '../../util/objToQueryString'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: ' 90vh',
  },
  overlay: {
    zIndex: 5000, // Ajuste o valor de zIndex conforme necessário
  },
}
const OrdersDetail = () => {
  const { id } = useParams()
  // const { addProduct } = useCart()
  const { filter, usingFilter: usingFilterContext, setUsingFilter: setUsingFilterContext, products: productsContext, totalPages: totalPagesContext, currentPage: currentPageContext } = useSearch()
  const [modalIsOpen, setIsOpen] = useState(false)
  const [invoice, setInvoice] = useState({})
  const [, setQty] = useState(0)
  const [editing, setEditing] = useState(false)
  const IMAGES_PATH = 'https://s3.amazonaws.com/bendito.customer.storage/images/'
  const [family, setFamily] = useState([])
  const [paymentMethod, setPaymentMethod] = useState([])
  const [opportunitiesProducts, setOpportunitiesProducts] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [isCategoryOpen, setIsCategoryOpen] = useState(false)
  const { innerBorderRef } = useOnOutsideClick(() => setIsCategoryOpen(false))
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentCategory, setCurrentCategory] = useState()
  const [product, setProduct] = useState(null)
  const [grade, setGrade] = useState([])
  const [gradeValue, setGradeValue] = useState('')
  const [isUsingFilter, setIsUsingFilter] = useState(false)
  const [isUsingSearchBar, setIsUsingSearchBar] = useState(false)
  const [isUsingCategoryOrSubcategory, setIsUsingCategoryOrSubcategory] = useState(false)

  const getProduct = async (idProduct) => {
    const response = await api.get(`/api/v2/Product/${idProduct}`)
    setProduct(response.data.response.data)
  }
  const getGrade = async (idProduct) => {
    const response = await api.get(`/api/v2/Product/${idProduct}/Sku`)
    setGrade(response.data.response.data)
  }
  const handleDetail = async (idProduct) => {
    setIsLoading(true)
    await Promise.all([getGrade(idProduct), getProduct(idProduct)])
    setIsLoading(false)
  }

  const handleSearch = async () => {
    if (!!searchValue && searchValue.length > 1) {
      const response = await api.get(`/api/v2/Product/SearchBar?Order=1&Page=${!!isUsingSearchBar ? 1 : currentPage}&Size=20&OnlyB2B=true&Description=${searchValue}`)
      setTotalPages(response.data.response.totalPages)
      setOpportunitiesProducts(response.data.response.data)
      setIsLoading(false)
      setProduct(undefined)
      setIsUsingFilter(false)
      !!isUsingSearchBar && setCurrentPage(1)
    }
  }
  const handleCategorySelect = async (id, idSubCategory) => {
    setIsLoading(true)
    const response = await api.get(`/api/v2/Product?CategoryId=${id}&SubCategoryId=${idSubCategory}&OnlyB2B=true&Size=20&Page=1`)
    setCurrentPage(1)
    setCurrentCategory(id)
    setTotalPages(response.data.response.totalPages)
    setOpportunitiesProducts(response.data.response.data)
    setProduct(undefined)
    !!searchValue && setSearchValue('')
    setIsLoading(false)
    setIsUsingFilter(false)
    setIsUsingSearchBar(false)
  }

  const handleCollectionSelect = async (id, idVariation) => {
    setIsLoading(true)
    const response = await api.get(`/api/v2/Product?CollectionId=${id}&VariationId=${idVariation}&OnlyB2B=true&Page=1&Size=20`)
    setCurrentPage(1)
    setTotalPages(response.data.response.totalPages)
    setOpportunitiesProducts(response.data.response.data)
    setProduct(undefined)
    !!searchValue && setSearchValue('')
    setIsLoading(false)
    setIsUsingFilter(false)
    setIsUsingSearchBar(false)
  }

  const handlePageClick = (e) => {
    window.scrollTo({ top: 0 })
    setIsLoading(true)
    setCurrentPage(e.selected + 1)
    setIsUsingSearchBar(false)
    setIsUsingCategoryOrSubcategory(false)
  }
  const debouncedChange = useDebounce(handleSearch)
  
  const getOpportunitiesProductsFilter = async (page) => {
    const updatedFilter = {
      ...filter,
      page: page,
    }
    const filterQuery = objectToQueryString(updatedFilter)
    const response = await api.get(`api/v2/Product/FilterBar?${filterQuery}`)
    setOpportunitiesProducts(response.data.response.data)
    setTotalPages(response.data.response.totalPages)
    !!searchValue && setSearchValue('')
    setIsLoading(false)
    setUsingFilterContext(false)
    setIsUsingSearchBar(false)
    setCurrentCategory(null)
    setIsUsingCategoryOrSubcategory(false)
  }

  useEffect(() => {
    let isMounted = true
    if (!!searchValue) {
      setCurrentCategory(null)
      setIsUsingCategoryOrSubcategory(false)
      setIsUsingSearchBar(true)
      debouncedChange(handleSearch)
    }
    return () => {
      isMounted = false
    }
  }, [searchValue])
  useEffect(() => {
    let isMounted = true

    if (isUsingFilter && !usingFilterContext) {
      // está usando paginação do filtro
      getOpportunitiesProductsFilter(currentPage)
    } else if (currentCategory && !isUsingCategoryOrSubcategory) {
      // verifica se já está na página de categoria/subcategoria pra usar paginação nela
      const getOpportunitiesProducts = async () => {
        const response = await api.get(`/api/v2/Product?CategoryId=${currentCategory}&OnlyB2B=true&Size=20&Page=${currentPage}`)
        setTotalPages(response.data.response.totalPages)
        setOpportunitiesProducts(response.data.response.data)
        setIsLoading(false)
      }
      getOpportunitiesProducts()
    } else if (!!searchValue && !isUsingSearchBar && !currentCategory) {
      // verifica se já fez uma busca para então só mudar a página
      handleSearch()
    }
    return () => {
      isMounted = false
    }
  }, [currentPage])

  useEffect(() => {
    let isMounted = true

    const getPaymentMethod = async () => {
      const response = await api.get('/api/v2/PaymentMethod?OnlyB2B=true')
      setPaymentMethod(response.data.response.data)
    }

    const getFamily = async () => {
      const response = await api.get('api/v2/Category?OnlyB2B=true')
      setFamily(response.data.response.data)
    }
    const getInvoice = async () => {
      const response = await api.get(`/Invoice/${id}`)
      if (location.state) if (location.state.editing && response.data.status === 1) setEditing(true)
      setInvoice(response.data)
    }

    getInvoice()
    getFamily()
    getPaymentMethod()
    setIsLoading(false)

    return () => {
      isMounted = false
    }
  }, [id])

  useEffect(() => {
    if (!!usingFilterContext) {
      setIsUsingFilter(true)
      !!searchValue && setSearchValue('')
    }
  }, [usingFilterContext])

  useEffect(() => {
    setOpportunitiesProducts(productsContext)
    setTotalPages(totalPagesContext)
    setCurrentPage(currentPageContext)
  }, [productsContext, totalPagesContext])

  const validPaymentMethods = paymentMethod.filter((pay) => {
    const hasValidMinimumPrice = !!pay.minimumPrice && pay.minimumPrice > 0
    const hasValidMaximumPrice = !!pay.maximumPrice && pay.maximumPrice > 0

    //forma pgto inativa e não é a forma que o pedido já possui.
    if (!pay.status && pay.id !== invoice.paymentMethodId)
      return false;

    if (hasValidMinimumPrice || hasValidMaximumPrice) {
      if (hasValidMinimumPrice && hasValidMaximumPrice) {
        // Se tiver preço mínimo E preço máximo, verifica se o valor do pedido está entre os dois
        const isValid = invoice?.totalValue >= pay.minimumPrice && invoice?.totalValue <= pay.maximumPrice
        return isValid
      } else if (hasValidMinimumPrice && !hasValidMaximumPrice) {
        // Se tiver somente preço mínimo, verifica se o valor do pedido está acima do mínimo
        const isValid = invoice?.totalValue >= pay.minimumPrice
        return isValid
      } else if (!hasValidMinimumPrice && hasValidMaximumPrice) {
        // Se tiver somente preço máximo, verifica se o valor do pedido está abaixo do máximo
        const isValid = invoice?.totalValue <= pay.maximumPrice
        return isValid
      }
    } else {
      return true
    }
  })

  const paymentMethodOptions = validPaymentMethods.map(payMethod => ({
    value: payMethod.id,
    label: payMethod.description,
  }))
  const loadSelectValue = paymentMethodOptions?.find((option) => option.value === invoice?.paymentMethodId) || invoice?.paymentMethodId
  const handleSelectChange = (selectedOption) => {
    handlePaymentMethod(selectedOption.value)
  }

  const reCalc = async (item, isModalDetail, gradeValue) => {
    setIsLoading(true)

    const data = {
      AddProductGradeId: (item.productSkuDataId || gradeValue) ?? null,
      AddProductId: isModalDetail ? item.id : item.productId,
      // AddQuantity: item.quantity || 1,
      AddQuantity: item.quantity < 1 ? 1 : item.quantity || 1,
      DeadlineId: invoice.deadlineId,
      Items: invoice.items,
      Nature: invoice.nature,
      PaymentMethodId: invoice.paymentMethodId || 1,
      Price: '',
    }

    const response = await api.post('/Invoice/Calculate', data).catch(() => {
      const id = toast.loading('Verificando', {
        position: 'top-right',
      })
      toast.update(id, {
        render: 'Erro ao adicionar o produto',
        type: 'error',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
    }).finally(() => setIsLoading(false))
    setInvoice({ ...invoice, ...{ paymentMethodId: response.data.data.paymentMethodId, addition: response.data.data.addition, discount: response.data.data.discount, freight: response.data.data.freight, items: response.data.data.items, tax: response.data.data.tax, totalValue: response.data.data.totalValue, value: response.data.data.value } })
  }
  const handleAddProduct = (product, isModalDetail, gradeValue) => {
    const id = toast.loading('Alterando quantidade', {
      position: 'top-right',
    })
    reCalc(product, !!isModalDetail, gradeValue)
    toast.update(id, {
      render: 'Produto adicionado ao seu pedido!',
      type: 'success',
      isLoading: false,
      delay: 750,
      autoClose: 2000,
    })
  }
  const handleAddProductDetail = (id, qty, gradeValue) => {
    product.quantity = qty
    handleAddProduct(product, true, gradeValue)
  }
  const handleQtyCount = async (type, item, index) => {
    const value = item.quantity || 1
    const invoiceEdited = invoice
    if (type === 'increase') {
      const id = toast.loading('Alterando quantidade', {
        position: 'top-right',
      })
      setQty(value + 1)
      invoiceEdited.items[index].quantity++
      toast.update(id, {
        render: 'Produto adicionado ao seu pedido!',
        type: 'success',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
    } else if (type === 'decrease' && value > 1) {
      const id = toast.loading('Alterando quantidade', {
        position: 'top-right',
      })
      invoiceEdited.items[index].quantity--

      setQty(value - 1)
      toast.update(id, {
        render: 'Produto removido o seu pedido!',
        type: 'success',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
    }
    reCalc(item)
    setInvoice({ ...invoiceEdited })
  }
  const handleQtyAdd = async (type, item, index) => {
    const invoiceEdited = opportunitiesProducts
    let value = invoiceEdited[index].quantity || 1
    if (type === 'increase') {
      // const id = toast.loading('Alterando quantidade')
      value++
      invoiceEdited[index].quantity = value
      setOpportunitiesProducts([...invoiceEdited])
      // toast.update(id, {
      //   render: 'Produto adicionado ao seu pedido!',
      //   type: 'success',
      //   isLoading: false,
      //   delay: 750,
      //   autoClose: 2000,
      // })
    } else if (type === 'decrease' && value > 1) {
      // const id = toast.loading('Alterando quantidade')
      value--
      invoiceEdited[index].quantity = value
      setOpportunitiesProducts([...invoiceEdited])
      // setQty(value - 1)
      // toast.update(id, {
      //   render: 'Produto removido o seu pedido!',
      //   type: 'success',
      //   isLoading: false,
      //   delay: 750,
      //   autoClose: 2000,
      // })
    }
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  const handlePaymentMethod = async (value) => {
    setIsLoading(true)
    const data = {
      AddProductId: null,
      AddQuantity: null,
      DeadlineId: invoice.deadlineId,
      Items: invoice.items,
      Nature: invoice.nature,
      PaymentMethodId: value,
      Price: '',
    }
    const response = await api.post('/Invoice/Calculate', data)
    setInvoice({ ...invoice, ...{ paymentMethodId: response.data.data.paymentMethodId, addition: response.data.data.addition, discount: response.data.data.discount, freight: response.data.data.freight, items: response.data.data.items, tax: response.data.data.tax, totalValue: response.data.data.totalValue, value: response.data.data.value } })
    setIsLoading(false)
  }
  const handleSaveOrder = async () => {
    const id = toast.loading(`Alterando pedido ${invoice.invoiceId}`, {
      position: 'top-right',
    })
    const data = {
      Advance: invoice.advance,
      DeadlineId: invoice.deadlineId,
      DeliveryForecast: invoice.deliveryForecast,
      Discount: invoice.discount,
      Freight: invoice.freight,
      Items: invoice.items,
      Nature: invoice.nature,
      Notes: invoice.notes,
      PaymentMethodId: invoice.paymentMethodId,
      Price: null,
      Tax: invoice.tax,
      TotalValue: invoice.totalValue,
      Value: invoice.value,
      // CarrierId: user?.customer.environmentConfiguration.invoiceCarrierPortalFinalCustomerDefault,
    }
    const response = await api.put(`/Invoice/${invoice.invoiceId}`, data)
    if (response.data.success) {
      toast.update(id, {
        render: response.data.successMessages[0],
        type: 'success',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
      setEditing(false)
    } else {
      toast.update(id, {
        render: 'Erro ao atualizar pedido',
        type: 'error',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
    }
  }
  const handleDuplicateOrder = async () => {
    const id = toast.loading(`Duplicando pedido ${invoice.invoiceId}`, {
      position: 'top-right',
    })
    document.getElementById('bntDuplicateOrder').disabled = true
    const items = invoice.items
    items.forEach(el => { el.id = 0 })
    const data = {
      Advance: invoice.advance,
      DeadlineId: invoice.deadlineId,
      DeliveryForecast: invoice.deliveryForecast,
      Discount: invoice.discount,
      Freight: invoice.freight,
      Items: items,
      Nature: invoice.nature,
      Notes: invoice.notes,
      PaymentMethodId: invoice.paymentMethodId,
      Price: null,
      Tax: invoice.tax,
      TotalValue: invoice.totalValue,
      Value: invoice.value,
      CreationSource: 2,
    }
    try {
      const response = await api.post('/Invoice', data)
      if (response.data.success) {
        toast.update(id, {
          render: response.data.successMessages[0],
          type: 'success',
          isLoading: false,
          delay: 750,
          autoClose: 2000,
        })
        window.location.replace(`/orders/${response.data.data.invoiceId}`)
        document.getElementById('bntDuplicateOrder').disabled = false
      }
    } catch (err) {
      toast.update(id, {
        render: 'Erro ao duplicar pedido',
        type: 'error',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
      document.getElementById('bntDuplicateOrder').disabled = false
    }
    // navigate(`/orders/${response.data.data.invoiceId}`, { state: { editing: true } })
  }
  const handleDeleteOrder = async () => {
    const id = toast.loading(`Excluindo pedido ${invoice.invoiceId}`, {
      position: 'top-right',
    })
    const response = await api.delete(`/Invoice/${invoice.invoiceId}`)
    if (response.data.success) {
      toast.update(id, {
        render: response.data.successMessages[0],
        type: 'success',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
      setEditing(false)
      navigate('/orders')
    } else {
      toast.update(id, {
        render: 'Erro ao excluir pedido',
        type: 'error',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })
    }
  }

  const handlePrintOrder = async () => {
    setIsLoading(true)
    await printInvoice(invoice.invoiceId)
    setIsLoading(false)
  }

  const handleChangeProductNote = (note, index) => {
    const invoiceEdited = invoice
    invoiceEdited.items[index].comments = note
    setInvoice({ ...invoiceEdited })
  }
  const handleChangeInvoiceNote = (note) => {
    const invoiceEdited = invoice
    invoiceEdited.notes = note
    setInvoice({ ...invoiceEdited })
  }
  const handleRemoveProduct = async (index) => {
    setIsLoading(true)
    const invoiceEdited = invoice
    invoiceEdited.items.splice(index, 1)
    const data = {
      AddProductId: null,
      AddQuantity: null,
      DeadlineId: invoiceEdited.deadlineId,
      Items: invoiceEdited.items,
      Nature: invoiceEdited.nature,
      PaymentMethodId: invoiceEdited.paymentMethodId,
      Price: '',
    }
    const response = await api.post('/Invoice/Calculate', data)
    setInvoice({ ...invoice, ...{ paymentMethodId: response.data.data.paymentMethodId, addition: response.data.data.addition, discount: response.data.data.discount, freight: response.data.data.freight, items: response.data.data.items, tax: response.data.data.tax, totalValue: response.data.data.totalValue, value: response.data.data.value } })
    setIsLoading(false)
  }

  const handleOpenCategory = () => {
    setIsCategoryOpen(!isCategoryOpen)
  }

  const handleCategorySelectCallback = (categoryId, subCategoryId) => {
    // setSelectedCategoryId(categoryId);
    setCurrentCategory(categoryId)
    setIsUsingCategoryOrSubcategory(true)
    setIsUsingSearchBar(false)
    setIsUsingFilter(false)
    handleCategorySelect(categoryId, subCategoryId)
  }

  const handleCollectionSelectCallback = (categoryId, subCategoryId) => {
    // setSelectedCategoryId(categoryId);
    handleCollectionSelect(categoryId, subCategoryId)
  }

  useTitle(`Detalhes do pedido #${invoice.invoiceId}`)
  return (
    <S.OrdersDetail>
      {isLoading && (
        <LoadingBackground />
      )}
      <Breadcrumb
        current='Detalhes do Pedido'
        parentLink={{ label: 'Meus pedidos', url: '/orders' }}
      />
      <S.OrdersDetailTop>
        <BackButton to='/orders' />
        <S.OrdersDetailTitle>Pedido {invoice.invoiceId}</S.OrdersDetailTitle>
        {invoice.invoiceId
          ? (
            <>
              <S.OrdersDetailDate>
                <S.OrdersDetailDateLabel>Data do pedido</S.OrdersDetailDateLabel>
                <S.OrdersDetailDateTag>{getDateStr(invoice.timeCreation)}</S.OrdersDetailDateTag>
              </S.OrdersDetailDate>
              <S.OrdersDetailStatus>
                <S.OrdersDetailStatusLabel>Status do pedido</S.OrdersDetailStatusLabel>
                <S.OrdersDetailStatusTag status={invoice.status}>{invoice.statusDescription}</S.OrdersDetailStatusTag>
              </S.OrdersDetailStatus>
              {editing && <S.BtnAddProduct onClick={openModal}>Adicionar novos itens</S.BtnAddProduct>}
            </>
            )
          : <Loading />}
      </S.OrdersDetailTop>

      <Pl.ProductList>
        {invoice.items && invoice.items.map((item, index) => {
          let firstImage = '/logo.png'
          const itemDiscount = item.politicsDiscount + item.politicsUnitDiscount

          if (item.product.images && item.product.images.length > 0) {
            if (item.product.images[0].includes('http')) { firstImage = item.product.images[0] } else { firstImage = `${IMAGES_PATH}${item.product.images[0]}` }
          }

          return (
            <Pl.ProductItem key={item.id}>
              <Pl.ProductItemImageFrame>
                <Pl.ProductItemImage
                  src={firstImage}
                  alt={item.product.description}
                />
              </Pl.ProductItemImageFrame>
              <Pl.ProductItemWrapper>
                <Pl.ProductItemContent>
                  <Pl.ProductItemInfos>
                    <Pl.ProductItemCod>Cód.: #{item.product.productId}</Pl.ProductItemCod>
                    <Pl.ProductItemName>{item.product.sku.length > 0 ? `${getGradeSelected(item)} -` : ''} {item.product.description}</Pl.ProductItemName>
                    <Pl.ProductItemCharacteristics>
                      <div className='desc'>
                        <CustomerList productId={item.product.id} model='small' />
                      </div>
                    </Pl.ProductItemCharacteristics>
                  </Pl.ProductItemInfos>
                  <Pl.ProductItemDetails>
                    <Pl.ProductItemDetailsItem>
                      <Pl.ProductItemDetailsItemLabel>Valor Unitário</Pl.ProductItemDetailsItemLabel>
                      <Pl.ProductItemDetailsItemValue>{formatMoney(item.value, false)}</Pl.ProductItemDetailsItemValue>
                    </Pl.ProductItemDetailsItem>
                    <Pl.ProductItemDetailsItem>
                      <Pl.ProductItemDetailsItemLabel>Desconto</Pl.ProductItemDetailsItemLabel>
                      <Pl.ProductItemDetailsItemValue>{formatMoney(itemDiscount, false)}</Pl.ProductItemDetailsItemValue>
                    </Pl.ProductItemDetailsItem>
                    <Pl.ProductItemDetailsItem>
                      <Pl.ProductItemDetailsItemLabel>Impostos</Pl.ProductItemDetailsItemLabel>
                      <Pl.ProductItemDetailsItemValue>{formatMoney(item.tax, false)}</Pl.ProductItemDetailsItemValue>
                    </Pl.ProductItemDetailsItem>
                    <Pl.ProductItemDetailsItem>
                      <Pl.ProductItemDetailsItemLabel>Frete</Pl.ProductItemDetailsItemLabel>
                      <Pl.ProductItemDetailsItemValue>{formatMoney(item.freight, false)}</Pl.ProductItemDetailsItemValue>
                    </Pl.ProductItemDetailsItem>
                    <Pl.ProductItemDetailsItem>
                      <Pl.ProductItemDetailsItemLabel>Adição</Pl.ProductItemDetailsItemLabel>
                      <Pl.ProductItemDetailsItemValue>{formatMoney(item.addition || 0, false)}</Pl.ProductItemDetailsItemValue>
                    </Pl.ProductItemDetailsItem>
                    <Pl.ProductItemDetailsItem>
                      <Pl.ProductItemDetailsItemLabel>Qtde</Pl.ProductItemDetailsItemLabel>
                      <S.ProductQtd>
                        {editing &&
                          <button onClick={() => handleQtyCount('decrease', item, index)}>
                            -
                          </button>}
                        <input
                          type='number'
                          value={item.quantity}
                          onBlur={(e) => { setQty(e.target.value < 1 ? 1 : e.target.value); item.quantity = !e.target.value ? 1 : e.target.value; handleQtyCount('', item, index) }}
                          onChange={(e) => { setQty(e.target.value); item.quantity = e.target.value }}
                          readOnly={!editing}
                        />
                        {editing &&
                          <button
                            onClick={() => handleQtyCount('increase', item, index)}
                          >
                            +
                          </button>}
                      </S.ProductQtd>
                    </Pl.ProductItemDetailsItem>
                    <Pl.ProductItemDetailsItem>
                      <Pl.ProductItemDetailsItemLabel>Valor</Pl.ProductItemDetailsItemLabel>
                      <Pl.ProductItemDetailsItemPrice>{formatMoney(item.totalValue)}</Pl.ProductItemDetailsItemPrice>
                    </Pl.ProductItemDetailsItem>
                    {editing && <div onClick={() => handleRemoveProduct(index)}> <S.RowIcon type='trash' /></div>}
                  </Pl.ProductItemDetails>
                </Pl.ProductItemContent>
                {item.comments && !editing && (
                  <S.OrdersMessageBox title='Comentário adicionado' content={item.comments} />
                )}
                {editing && (
                  <S.CntTextArea>
                    <label>Adicionar um comentário a este item</label>
                    <textarea
                      value={item.comments}
                      onChange={(e) => handleChangeProductNote(e.target.value, index)}
                      style={{ width: '100%' }}
                      placeholder='Deixe aqui seu comentário...'
                    />
                  </S.CntTextArea>
                )}
              </Pl.ProductItemWrapper>
            </Pl.ProductItem>
          )
        })}
      </Pl.ProductList>

      <S.OrdersDetailOtherInfos>
        {invoice.notes && !editing && (
          <S.OrdersMessageBox title='Observações do Pedido' content={invoice.notes} />
        )}
        {editing && (
          <S.CntTextArea>
            <label>Adicionar um comentário a este pedido</label>
            <textarea
              value={invoice.notes || ''}
              onChange={(e) => handleChangeInvoiceNote(e.target.value)}
              style={{ width: '100%' }}
              placeholder='Deixe aqui seu comentário...'
            />
          </S.CntTextArea>
        )}
        {invoice && (
          <S.OrdersDetailOtherWrapper>
            <OrderPrices
              value={invoice.value}
              tax={invoice.tax}
              freight={invoice.freight}
              discount={invoice.politicsDiscount}
              totalValue={invoice.totalValue}
              addition={invoice.addition || 0}
            />
            {editing &&
              <S.OrdersDetailActions>
                <S.SelectPayment>
                  <label htmlFor=''>Selecione uma forma de pagamento:</label>
                  <SearchableSelect
                    name='select-product'
                    defaultValue={loadSelectValue}
                    placeholder='Selecione...'
                    onChange={handleSelectChange}
                    styles={customStylesForSearchableSelect}
                    options={paymentMethodOptions}
                    noOptionsMessage={() => 'Sem resultados.'}
                  />
                </S.SelectPayment>
                <S.OrdersDetailButton onClick={handleSaveOrder}>Salvar pedido</S.OrdersDetailButton>
              </S.OrdersDetailActions>}
            {!editing &&
              <S.OrdersDetailActions>
                {invoice.status === 1 && <S.OrdersDetailButton onClick={() => setEditing(true)}>Editar pedido</S.OrdersDetailButton>}
                <S.OrdersDetailButton onClick={handleDeleteOrder}>Excluir pedido</S.OrdersDetailButton>
                <S.OrdersDetailButton id='bntDuplicateOrder' onClick={handleDuplicateOrder}>Duplicar pedido</S.OrdersDetailButton>
                <S.OrdersDetailButton onClick={handlePrintOrder}>Imprimir pedido</S.OrdersDetailButton>
              </S.OrdersDetailActions>}
          </S.OrdersDetailOtherWrapper>
        )}
      </S.OrdersDetailOtherInfos>

      <BackButton label to='/orders' />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <S.CntModal>

          <div className='top'>
            <div className='left'>
              <div className='title'>Precisa adicionar novos itens ao seu pedido?</div>
              <div className='desc'>Selecione a categoria e depois adicione o item desejado</div>
            </div>
            <div className='right'>
              <button onClick={closeModal}>Fechar</button>
            </div>
          </div>
          <div className='cnt'>
            <div className='category'>
              <div style={{ marginLeft: '1.5rem' }}>
                <SidebarModal handleCategorySelectCallback={handleCategorySelectCallback} handleCollectionSelectCallback={handleCollectionSelectCallback} />
              </div>
            </div>
            <div className='itens'>
              <div className='search'>
                <input value={searchValue} type='text' onChange={(e) => setSearchValue(e.target.value)} className='input' />
                <S.BtnSearch onClick={() => {handleSearch()}}>Buscar produto</S.BtnSearch>
              </div>
              <SidebarModal isMobile handleCategorySelectCallback={handleCategorySelectCallback} handleCollectionSelectCallback={handleCollectionSelectCallback} />
              {opportunitiesProducts.length > 0 &&
                <div className='result-prodc'>
                  <S.CntModalProducts>
                    {
                      product && <DetailProduct product={product} grade={grade} gradeValue={gradeValue} setGradeValue={setGradeValue} addProduct={handleAddProductDetail} />
                    }
                    {
                      !product && opportunitiesProducts.map((product, index) => {
                        let firstImage = '/logo.png'

                        if (product.images && product.images.length > 0) {
                          if (product.images[0].includes('http')) { firstImage = product.images[0] } else { firstImage = `${IMAGES_PATH}${product.images[0]}` }
                        }

                        return (
                          <Pl.ProductItem key={product.id}>
                            <Pl.ProductItemImageFrame>
                              <Pl.ProductItemImage
                                src={firstImage}
                                alt={product.description}
                              />
                            </Pl.ProductItemImageFrame>
                            <Pl.ProductItemWrapper>
                              <Pl.ProductItemContentModal>
                                <Pl.ProductItemInfos>
                                  <Pl.ProductItemCod>Cód.: #{product.id}</Pl.ProductItemCod>
                                  <Pl.ProductItemName>{product.description}</Pl.ProductItemName>
                                  <Pl.ProductItemCharacteristics>
                                    <div className='desc'>
                                      <ul>
                                        {/* {product.characteristics.map(detail => <li key={detail.id}>{detail.convertedValue}</li>)} */}
                                      </ul>
                                    </div>
                                  </Pl.ProductItemCharacteristics>
                                </Pl.ProductItemInfos>
                                <Pl.ProductItemDetails>
                                  <Pl.ProductItemDetailsItem>
                                    <Pl.ProductItemDetailsItemLabel>Valor Unitário</Pl.ProductItemDetailsItemLabel>
                                    <Pl.ProductItemDetailsItemValue>{formatMoney(product.price, false)}</Pl.ProductItemDetailsItemValue>
                                  </Pl.ProductItemDetailsItem>
                                  <Pl.ProductItemDetailsItem>
                                    <Pl.ProductItemDetailsItemLabel>Valor</Pl.ProductItemDetailsItemLabel>
                                    <Pl.ProductItemDetailsItemPrice>{formatMoney(product.price)}</Pl.ProductItemDetailsItemPrice>
                                  </Pl.ProductItemDetailsItem>
                                  <Pl.ProductItemDetailsItem>
                                    <Pl.ProductItemDetailsItemLabel>Qtde</Pl.ProductItemDetailsItemLabel>
                                    <S.ProductQtd>
                                      <button onClick={() => handleQtyAdd('decrease', product, index)}>
                                        -
                                      </button>
                                      <input
                                        type='number'
                                        value={product.quantity || 1}
                                        onBlur={(e) => { setQty(e.target.value < 1 ? 1 : e.target.value); product.quantity = !e.target.value ? 1 : e.target.value }}
                                        onChange={(e) => { setQty(e.target.value); product.quantity = e.target.value }}
                                      />
                                      <button
                                        onClick={() => handleQtyAdd('increase', product, index)}
                                      >
                                        +
                                      </button>
                                    </S.ProductQtd>
                                  </Pl.ProductItemDetailsItem>
                                  <S.CntBtnModal>
                                    <Pl.ProductItemDetailsItem>
                                      <S.OrdersDetailButton onClick={() => handleDetail(product.id)}>Ver Detalhes</S.OrdersDetailButton>
                                    </Pl.ProductItemDetailsItem>
                                    <Pl.ProductItemDetailsItem>
                                      <S.OrdersDetailButtonBuy onClick={() => product.hasGrade ? handleDetail(product.id) : handleAddProduct(product, true)}>Adicionar</S.OrdersDetailButtonBuy>
                                    </Pl.ProductItemDetailsItem>
                                  </S.CntBtnModal>

                                </Pl.ProductItemDetails>
                              </Pl.ProductItemContentModal>
                            </Pl.ProductItemWrapper>
                          </Pl.ProductItem>
                        )
                      })
                    }
                    {!isLoading && totalPages > 1 && !product && <Paginator totalPages={totalPages} currentPage={currentPage} handlePageClick={handlePageClick} />}
                  </S.CntModalProducts>
                </div>}
              {opportunitiesProducts.length === 0 &&
                <div className='result'>
                  <div className='not-found'>
                    <img src='/assets/images/img-ilustra.svg' alt='' />
                    <div className='text'>Selecione uma categoria de produtos ao lado para exibir produtos ou use o campo de busca acima</div>
                  </div>
                </div>}
            </div>
          </div>
        </S.CntModal>
      </Modal>
    </S.OrdersDetail>
  )
}

export default OrdersDetail
