import { useTitle } from '../../hooks/useTitle'
import * as S from './styles'
import { IMAGE_URL } from '../../constants'

const PageNotFound = () => {
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'));
  useTitle('Bendito - Acelere suas vendas!')

  return (
    <S.PageNotFound>
      <S.PageNotFoundContainer>
        <S.PageNotFoundWrapper>
          <S.PageNotFoundInner>
            <S.PageNotFoundLogoWrapper>
              <S.PageNotFoundLogo src={!!customerInfo && customerInfo?.logo ? `${IMAGE_URL}${customerInfo.logo}` : '/assets/images/logo-bendito-white.svg'} />
            </S.PageNotFoundLogoWrapper>
            <S.PageNotFoundTitle>Página não encontrada!</S.PageNotFoundTitle>
          </S.PageNotFoundInner>
        </S.PageNotFoundWrapper>
      </S.PageNotFoundContainer>
    </S.PageNotFound>
  )
}

export default PageNotFound
