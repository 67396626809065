import { useEffect, useState } from 'react'
import { useTitle } from '../../hooks/useTitle'
import { useCart } from '../../context/CartContext'
import CustomerList from '../../components/Product/CustomerList'
import CartEmpty from '../../components/Cart/CartEmpty'
import Breadcrumb from '../../components/Breadcrumb'
import { IMAGE_URL } from '../../constants'
import * as S from './styles'
import { getGradeSelected } from '../../util/getGradeSelected'

const Cart = () => {
  const { cart } = useCart()

  if (cart?.items.length === 0) {
    localStorage.removeItem('@bendito:selectedPayMethod');
  }

  useTitle('Carrinho de compras')

  return (
    <S.Cnt>
      {cart && cart.items.length > 0
        ? (
          <>
            <Breadcrumb current='Meus Pedidos' />
            <S.h1Styled>Resumo do Meu Pedido</S.h1Styled>
            <ul>
              {cart && (
                cart.items.map(product => (
                  <CartProduct key={product.productId} product={product} />
                ))
              )}
            </ul>
            <S.botInfoTotal>
              <div className='mid'>
                <div className='values'>
                  <div className='name'>Subtotal</div>
                  <div className='value'>{cart.value.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                  </div>
                </div>
                <div className='values'>
                  <div className='name'>Desconto</div>
                  <div className='value'>{cart.politicsDiscount.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                  </div>
                </div>
                <div className='values'>
                  <div className='name'>Impostos</div>
                  <div className='value'>{cart.tax.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                  </div>
                </div>
                <div className='values'>
                  <div className='name'>Frete</div>
                  <div className='value'>{cart.freight.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                  </div>
                </div>
                <div className='values total'>
                  <div className='name'>Total Geral</div>
                  <div className='value'>{cart.totalValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                  </div>
                </div>
              </div>
            </S.botInfoTotal>
            <S.BtnRoute to='/cart-payment'>
              <S.BtnPay>Ir para pagamento</S.BtnPay>
            </S.BtnRoute>
          </>
          )
        : (
          <CartEmpty>
            Parece que seu carrinho ainda está vazio 😱 <br />
            <strong>Vamos mudar isso?</strong>
          </CartEmpty>
          )}
    </S.Cnt>
  )
}

const CartProduct = ({ product }) => {
  const { addProduct, deleteProduct } = useCart()
  const [qty, setQty] = useState(0)
  const [tempQty, setTempQty] = useState(product.quantity)
  const [hasNote, setHasNote] = useState(false)
  const [productNote, setProductNote] = useState("");
  let firstImage = '/logo.png'

  useEffect(() => {
    if (qty > 0) {
      addProduct(product.productId, qty, product.productSkuDataId ?? null)
    }
  }, [qty])

  useEffect(() => {
    setProductNote(!!product.comments ? product.comments : "")
    setHasNote(!!product.comments ? true : false)
   }, [product])

  const handleQtyCount = async (type) => {
    const value = Number(qty) || Number(product.quantity)

    if (type === 'increase') {
      setQty(value + 1)
      setTempQty(value + 1)
    } else if (type === 'decrease' && qty > 1) {
      setQty(value - 1)
      setTempQty(value - 1)
    }
  }

  const handleProductNoteChange = (e) => {
    setProductNote(e.target.value);
    product.comments = e.target.value;
  }

  const handleEnableProductNoteChange = (e) => {
    setHasNote(e.target.checked);
    if (!e.target.checked) {
      product.comments = null;
    }
  }

  if (product.product.images && product.product.images.length > 0) {
    if (product.product.images[0].includes('http')) { firstImage = product.product.images[0] } else { firstImage = `${IMAGE_URL}${product.product.images[0]}` }
  }

  if (product.discount === null) {
    product.discount = 0
  }

  if (product.unitDiscount === null) {
    product.unitDiscount = 0
  }

  const discountSum = product.politicsDiscount + product.politicsUnitDiscount

  // TODO: Perguntar para o Gustavo se só produtos na Grade tem SKU

  return (
    <S.Container key={product.productId}>
      <div className='box-img'>
        <img src={firstImage} onError={e => { e.target.src = '/logo.png' }} alt='' />
      </div>
      <div className='info'>
        <div className='top'>
          <div className='left'>
            <div className='cod'>Cód.: #{product.product.id}</div>
            <div className='title'>{product.product.sku.length > 0 ? `${getGradeSelected(product)} -` : ''} {product.product.description}
              {product.product.multiplicationFactorValue > 1 && product.product.useMultiplicationFactor == true ?
                <small>(x{product.product.multiplicationFactorValue})</small> : ""}
            </div>
            <div className='desc'>
              <CustomerList productId={product.product.id} model='small' />
            </div>
          </div>
          <div className='mobile-img-qtd'>
            <div className='box-img'>
              <img src={firstImage} alt='' />
            </div>
            <div className='right'>
              <div className='qtd'>
                <button
                  onClick={() => handleQtyCount('decrease')}
                >
                  -
                </button>

                <input
                  type='number'
                  value={tempQty}
                  onChange={(e) => setTempQty(e.target.value.replace(',','.'))}
                  onBlur={(e) => setQty(e.target.value.replace(',','.'))}
                />
                <button
                  onClick={() => handleQtyCount('increase')}
                >
                  +
                </button>
              </div>
              <div className='total-value'>{product.totalValue.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
                <S.CartDeleteProductBtn onClick={() => deleteProduct(product.productId, product.productSkuDataId)}>
                  <S.CartIcon type='trash' />
                </S.CartDeleteProductBtn>
              </div>
            </div>
          </div>
          <div className='mid'>
            <div className='values'>
              <div className='name'>Valor Unitário</div>
              <div className='value'>{product.value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              </div>
            </div>
            <div className='values'>
              <div className='name'>Desconto</div>
              <div className='value'>{discountSum.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              </div>
            </div>
            <div className='values'>
              <div className='name'>Impostos</div>
              <div className='value'>{product.tax.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              </div>
            </div>
            <div className='values'>
              <div className='name'>Frete</div>
              <div className='value'>{product.freight.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              </div>
            </div>
          </div>
          <div className='right'>
            <div className='qtd'>
              <button
                onClick={() => handleQtyCount('decrease')}
              >
                -
              </button>

              <input
                type='text'
                value={tempQty}
                onChange={(e) => setTempQty(e.target.value.replace(',','.'))}
                onBlur={(e) => setQty(e.target.value.replace(',','.'))}
              />
              <button
                onClick={() => handleQtyCount('increase')}
              >
                +
              </button>
            </div>
            <div className='total-value'>{product.totalValue.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
            </div>
            <S.CartDeleteProductBtn onClick={() => deleteProduct(product.productId, product.productSkuDataId)}>
              <S.CartIcon type='trash' />
            </S.CartDeleteProductBtn>
          </div>
        </div>
        <div className='bot'>
          <div className='select-box'>
            <input
              value={hasNote}
              onChange={handleEnableProductNoteChange} type='checkbox' name='' id={product.productId} placeholder=''
              checked={hasNote ? true : false}
            />
            <label htmlFor={product.productId}>Adicionar um comentário a este item</label>

          </div>
          {hasNote &&
            <textarea
              value={productNote}
              onChange={handleProductNoteChange}
              style={{ width: '100%', height: '150px' }}
              placeholder='Deixe aqui seu comentário...'
            />}

        </div>
      </div>
      {/* {product.product.description}
      ====
      Total R$ {product.totalValue}
      ====
      <button
        onClick={() => handleQtyCount('decrease')}
        style={{ color: 'red', fontSize: '30px' }}
      >
        -
      </button>
      <input
        type='text'
        value={tempQty}
        onChange={(e) => setTempQty(e.target.value)}
        onBlur={(e) => setQty(e.target.value)}
      />
      <button
        onClick={() => handleQtyCount('increase')}
        style={{ color: 'red', fontSize: '30px' }}
      >
        +
      </button> */}
    </S.Container>
  )
}

export default Cart
