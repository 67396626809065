import { useState, useEffect } from 'react'
import * as S from './styles'
import { SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import ProductDiscountCard from '../ProductDiscountCard'
import NavGroup from '../Slide/NavGroup'
import api from '../../services/api'

const SlideNewProducts = ({ className }) => {
  const newProductsData = 'discount'
  const [products, setProducts] = useState([])
  const getProducts = async () => {
    const response = await api.get('/api/v2/Product/NewProducts?Size=10')
    response.data.response && setProducts(response.data.response.data.slice(0, 10))
  }
  useEffect(() => {
    getProducts()
  }, [])

  return (
    products.length > 0 &&
      <S.SlideNewProducts className={className}>
        <S.SlideNewProductsTitle>
          Conheça nossas <span>novidades!</span>
        </S.SlideNewProductsTitle>
        <S.SlideNewProductsContainer>
          <S.SlideNewProductsBtn data-js={`${newProductsData}-btn-prev`}>
            <S.SlideNewProductsBtnIcon type='upChevron' />
          </S.SlideNewProductsBtn>
          <S.SlideNewProductsBtn data-js={`${newProductsData}-btn-next`}>
            <S.SlideNewProductsBtnIcon type='upChevron' />
          </S.SlideNewProductsBtn>
          <S.SlideNewProductsSwiper
            slidesPerView={1}
            spaceBetween={30}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            pagination={{
              el: `[data-js=${newProductsData}-pagination]`,
              clickable: true,
              dynamicBullets: true,
            }}
            navigation={{
              prevEl: `[data-js=${newProductsData}-btn-prev]`,
              nextEl: `[data-js=${newProductsData}-btn-next]`,
            }}
            modules={[Pagination, Navigation]}
          >
            {products.map(product => (
              <SwiperSlide key={product.id}>
                <ProductDiscountCard product={product} />
              </SwiperSlide>
            ))}
          </S.SlideNewProductsSwiper>
        </S.SlideNewProductsContainer>
        <NavGroup btnIcon='leftArrow' data={newProductsData} />
      </S.SlideNewProducts>
  )
}

export default SlideNewProducts
