import styled from 'styled-components'
import Icon from '../../../commons/Icon'

export const NavGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NavGroupBtnIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NavGroupBtn = styled.button`
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  color: ${({ theme }) => theme.colors.orange.default};

  ${NavGroupBtnIcon} {
    width: 100%;
    height: 100%;
  }

  &[data-js$="btn-prev"] {
    ${NavGroupBtnIcon} {
      transform: rotate(-180deg);
    }
  }

  &.swiper-button-disabled {
    color: ${({ theme }) => theme.colors.mono.greyDark};
  }
`

export const NavGroupPagination = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  left: unset !important;
  bottom: unset !important;
  width: auto !important;
  margin: 0 4rem;

  .swiper-pagination-bullet {
    opacity: 1;
    background: ${({ theme }) => theme.colors.mono.greyDark};
    cursor: pointer;
    border-radius: 0.4rem;
    transition: background-color 0.3s, width 0.3s;
    display: none;
    &.swiper-pagination-bullet-active-next-next,
    &.swiper-pagination-bullet-active-next,
    &.swiper-pagination-bullet-active-prev,
    &.swiper-pagination-bullet-active-prev-prev,
    &.swiper-pagination-bullet-active {
      display: block;    
      width: 1rem;
      height: 1rem;
    }
    &.swiper-pagination-bullet-active-next,
    &.swiper-pagination-bullet-active-prev {
      transform: scale(0.6);
      animation-name: zoomIn6;
      animation-duration: 0.3s;
      animation-fill-mode: both;
    }
    &.swiper-pagination-bullet-active-next-next,
    &.swiper-pagination-bullet-active-prev-prev {
      transform: scale(0.3);
      animation-name: zoomIn3;
      animation-duration: 0.3s;
      animation-fill-mode: both;
    }
    &:not(:last-of-type) {
      margin-right: 1.5rem;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.orange.default};
    }

    &.swiper-pagination-bullet-active {
      width: 4rem;
      background: ${({ theme }) => theme.colors.orange.default};
    }
    @keyframes zoomIn3 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(0.3);
      }
    }
    @keyframes zoomIn6 {
      0% {
        transform: scale(0.3);
      }
      100% {
        transform: scale(0.6);
      }
    }
  }
`
