export const getGradeSelected = (item) => {
  const itemSkuId = item.productSkuDataId
  const itemSkuList = item.product.sku
  const gradeSelected = itemSkuList.find((i) => i.id === itemSkuId)

  if (gradeSelected?.characteristicValueSecondDimension != null) {
    return `${gradeSelected.characteristicValueFirstDimension.description} ${gradeSelected.characteristicValueSecondDimension.description}`
  } else {
    return `${gradeSelected.characteristicValueFirstDimension.description}`
  }
}
