import { useState, useEffect } from 'react'
import { SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Thumbs } from 'swiper'
import * as S from './styles'
import { IMAGE_URL } from '../../../constants'
import NavGroup from '../../Slide/NavGroup'

import 'swiper/css'
// import 'swiper/css/pagination'

import useWindowDimensions from './../../../hooks/useWindowDimensions'

const Gallery = ({ images = [] }) => {
  const { width } = useWindowDimensions()
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [key, setKey] = useState(0)
  const galleryData = 'gallery'
  
  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [images]);

  return (
    <S.Gallery>
      {images &&
        <S.GalleryMain
          key={key}
          loop={images.length > 1}
          spaceBetween={30}
          thumbs={images.length > 1 ? { swiper: thumbsSwiper } : null}
          pagination={{
            el: `[data-js=${galleryData}-pagination]`,
            clickable: true,
            dynamicBullets: true,
          }}
          navigation={{
            prevEl: `[data-js=${galleryData}-btn-prev]`,
            nextEl: `[data-js=${galleryData}-btn-next]`,
          }}
          modules={[Pagination, Navigation, Thumbs]}
        >
          {images.length === 0 &&
            <SwiperSlide key='image'>
              <S.GalleryFrame>
                <S.GalleryImage src='/logo.png' alt='' />
              </S.GalleryFrame>
            </SwiperSlide>}
          {images.map(image => {
            if (!image.includes('http')) { image = `${IMAGE_URL}${image}` }

            return (
              <SwiperSlide key={image}>
                <S.GalleryFrame>
                  <S.GalleryImage src={image} alt='' onError={(e) => { e.target.src = '/logo.png' }} />
                </S.GalleryFrame>
              </SwiperSlide>
            )
          },
          )}
        </S.GalleryMain>}

      {images &&
        <>
          {images.length > 1 && width > 668 && (
            <>
              <NavGroup
                btnIcon='rightChevron'
                data={galleryData}
              />
              <S.GalleryThumbs
                onSwiper={setThumbsSwiper}
                spaceBetween={15}
                slidesPerView={1}
                breakpoints={{
                  568: {
                    slidesPerView: 1,
                  },
                  1440: {
                    slidesPerView: 2,
                  },
                }}
              >
                {images.map((image) => {
                  if (!image.includes('http')) { image = `${IMAGE_URL}${image}` }

                  return (
                    <SwiperSlide key={image}>
                      <S.GalleryFrame>
                        <S.GalleryImage src={image} onError={(e) => { e.target.src = '/logo.png' }} />
                      </S.GalleryFrame>
                    </SwiperSlide>
                  )
                })}
              </S.GalleryThumbs>
            </>
          )}
        </>}

    </S.Gallery>
  )
}

export default Gallery
