import { useState, useEffect } from 'react'
import * as S from './styles'
import { SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import ProductDiscountCard from '../ProductDiscountCard'
import NavGroup from '../Slide/NavGroup'
import api from '../../services/api'

const SlideOpportunities = ({ className }) => {
  const opportunitiesData = 'discount'
  const [products, setProducts] = useState([])
  const getProducts = async () => {
    const response = await api.get('/api/v2/Product/Recommendations?Size=20')
    response.data.response && setProducts(response.data.response.data.slice(0, 20))
  }
  useEffect(() => {
    getProducts()
  }, [])

  return (
    <S.SlideOpportunities className={className}>
      <S.SlideOpportunitiesTitle>
        Olha o que esta fazendo <span>sucesso também!</span>
      </S.SlideOpportunitiesTitle>
      <S.SlideOpportunitiesContainer>
        <S.SlideOpportunitiesBtn data-js={`${opportunitiesData}-btn-prev`}>
          <S.SlideOpportunitiesBtnIcon type='upChevron' />
        </S.SlideOpportunitiesBtn>
        <S.SlideOpportunitiesBtn data-js={`${opportunitiesData}-btn-next`}>
          <S.SlideOpportunitiesBtnIcon type='upChevron' />
        </S.SlideOpportunitiesBtn>
        <S.SlideOpportunitiesSwiper
          slidesPerView={1}
          spaceBetween={30}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          pagination={{
            el: `[data-js=${opportunitiesData}-pagination]`,
            clickable: true,
            dynamicBullets: true,
          }}
          navigation={{
            prevEl: `[data-js=${opportunitiesData}-btn-prev]`,
            nextEl: `[data-js=${opportunitiesData}-btn-next]`,
          }}
          modules={[Pagination, Navigation]}
        >
          {products.map(product => (
            <SwiperSlide key={product.id}>
              <ProductDiscountCard product={product} />
            </SwiperSlide>
          ))}
        </S.SlideOpportunitiesSwiper>
      </S.SlideOpportunitiesContainer>
      <NavGroup btnIcon='leftArrow' data={opportunitiesData} />
    </S.SlideOpportunities>
  )
}

export default SlideOpportunities
