import styled, { css } from 'styled-components/macro'
import Icon from '../../commons/Icon'

export const InputField = styled.div`${({ styleType }) => css`
  margin-bottom: 1rem;

  ${styleType === 2 && css`
    ${InputLabel} {
      display: flex;
      align-items: center;

      @media ${({ theme }) => theme.screens.md} {
        display: block;
      }
    }

    ${InputText} {
      flex-shrink: 0;
      width: max-content;
      margin: 0 1rem 0 0;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.2rem;
      letter-spacing: -0.03rem;

      @media ${({ theme }) => theme.screens.md} {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.6rem;
      }
    }
  `}
`}`

export const InputLabel = styled.label``

export const InputText = styled.span`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.greyDarker};
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 1.1rem 1.5rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  background: #FFFFFF;
  /* Mono/Grey */
  border: 2px solid #E3E6E8;
  border-radius: .6rem;
  transition: border-color .3s;

  &:focus {
    border-color: ${({ theme }) => theme.colors.orange.default};
    outline: 0;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.mono.greyLight};
    border-color: ${({ theme }) => theme.colors.mono.grey};
    color: ${({ theme }) => theme.colors.mono.greyDark};
  }
`

export const InputPasswordButton = styled.button`${({ isActive }) => css`
  position: absolute;
  right: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.colors.orange.default};

  ${isActive && css`
    color: ${({ theme }) => theme.colors.mono.greyDark};
  `}
`}`

export const InputIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
`
export const RequiredBadge = styled.span`
  color: red;
`
