import styled, { css } from 'styled-components/macro'
import Icon from '../../../commons/Icon'
import { Button } from '../../../commons/Button'

export const Cart = styled.div`
  position: relative;
`

export const CartIcon = styled(Icon)``

export const CartMenu = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 4rem;
  @media ${({ theme }) => theme.screens.md} {
      margin-left: auto;
  }
  color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.greyLight : theme.colors.mono.black};
  ${CartIcon} {
    width: 3.6rem;
    height: 3.6rem;
    color: ${({ theme, $changeHeaderColor }) => $changeHeaderColor ? theme.colors.mono.white : theme.colors.orange.default};
  }
`

export const CartMenuLabel = styled.span`
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
`

export const CartMenuQty = styled.span`
  position: absolute;
  left: 46px;
  top: -0.1rem;
  padding: 0 0.8rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  background: ${({ theme }) => theme.colors.yellow.default};
  border: 0.5rem solid ${({ theme }) => theme.colors.mono.white};
  border-radius: 10rem;
  color: ${({ theme }) => theme.colors.mono.black};
`

export const CartBox = styled.div`${({ isOpen, theme }) => css`
  visibility: hidden;
  position: absolute;
  right:0rem;
  top: 7rem;
  transform: translateY(2rem);
  z-index: 1000;
  width: 47.5rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.mono.white};
  border-radius: .5rem;
  box-shadow: ${({ theme }) => theme.shadows.dark};
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s, visibility .3s, transform .3s;
  
  @media ${theme.screens.xs} {
    width: 40.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    right: 4rem;
    top: 0;
    transform: translateY(-100%);
    border-left: .5rem solid transparent;
    border-right: .5rem solid transparent;
    border-bottom: .5rem solid ${({ theme }) => theme.colors.mono.white};
  }

  ${isOpen && css`
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  `}
`}
`

export const CartBoxNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 1.55rem 1.5rem 1.55rem 2.5rem;
  background: ${({ theme }) => theme.colors.green.default};
  border-radius: 0.5rem;
  display: none;
`

export const CartBoxNotificationText = styled.span`
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.mono.white};
`

export const CartBoxNotificationButton = styled.button`
  color: ${({ theme }) => theme.colors.mono.white};
  width: 1.8rem;
  height: 1.8rem;

  ${CartIcon} {
    width: 1.8rem;
    height: 1.8rem;
  }
`

export const CartProducts = styled.div`
  max-height: 29rem;
  overflow-y: auto;
`

export const CartProduct = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mono.grey};

  &:first-of-type {
    margin-top: 0;
  }
`

export const CartProductImageWrapper = styled.figure`
  flex-shrink: 0;
  width: 6.5rem;
  height: 7rem;
  margin-right: 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
`

export const CartProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const CartProductContent = styled.div`
  flex-grow: 1;
  position: relative;
  padding-right: 2rem;
`

export const CartProductTitle = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
`

export const CartSubProductTitle = styled.small`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.8rem;
  letter-spacing: -0.02rem;
`

export const CartProductWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
`

export const CartProductQty = styled.span`
  margin-right: 1rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const CartProductPrice = styled.span`
  font-size: 1.3rem;
  line-height: 1.7rem;
`

export const CartProductBtn = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.mono.greyDark};

  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`

export const CartBoxBottom = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CartSubtotal = styled.div`
  display: flex;
  align-items: center;
`

export const CartSubtotalLabel = styled.span`
  margin-right: 1rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
`

export const CartSubtotalValue = styled.span`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -0.03rem;
  color: ${({ theme }) => theme.colors.orange.default};
`

export const CartButton = styled(Button)`
  padding: 1.2rem 2rem;
  font-size: 1.4rem;
  line-height: 1.5rem;
`
